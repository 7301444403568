import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Box,
    CircularProgress
} from "@mui/material";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Product } from "../../../api/resources/Product";
import Emitter from '../../../services/emitter';
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import dateformat from "dateformat";
import ThemeButton from "../../partials/ThemeButton";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    btnSuccess: {
        backgroundColor: "#43a047",
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: "#388e3c"
        }
    },
}));

function RepostListingModal(props) {
    //console.log(props)
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [rePostLoading, setRePostLoading] = React.useState(false);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const repostButtonClassname = clsx({
        [classes.buttonSuccess]: props.rateSuccess
    });

    const rePostListingAction = () => {
        setRePostLoading(true);
        Product.rePostListing({
            listing_id: props.selectedListingID,
        })
            .then(response => {
                if (response.data.status == "success") {
                    Emitter.emit('SNACKBAR_NOTIFICATION', { message: `Listing Reposted successfully!`, params: { variant: 'success' } });
                }
                setOpen(false)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setRePostLoading(false);
            });
    };

    return (
        <>
            <Button
                startIcon={<SyncAltIcon />}
                className={classes.btnSuccess}
                size="small"
                variant="contained"
                color="primary"
                disabled={props.isAuction == true}
                disableElevation
                onClick={e => {
                    handleClickOpen("rePostModal");
                }}>
                REPOST
            </Button>
            <Dialog
                open={open === "rePostModal"}
                TransitionComponent={Transition}
                keepMounted
            >
                <Box style={{ maxWidth: "290px" }}>

                    <DialogContent>
                        <Box>
                            <Box mb={2} fontWeight="bold" fontSize={16}>Are you Sure you want to repost this listing?</Box>

                            <Box fontSize={14} className="textMuted">
                                Listing ID: {props.selectedListingID} (Will Update)
                                <br />
                                Quantity: {props.selectedQuantity}MT
                            </Box >
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Grid container spacing={1}>

                            <Grid item xs={6}>
                                <ThemeButton
                                    onClick={handleClose}
                                    color="inherit"
                                    type="text"
                                    style={{ width: "100%" }}
                                >
                                    NO THANKS
                                </ThemeButton>
                            </Grid>
                            <Grid item xs={6}>
                                <ThemeButton
                                    onClick={e => rePostListingAction()}
                                    color="primary"
                                    type="text"
                                    className={repostButtonClassname}
                                    disabled={rePostLoading}
                                    style={{ width: "100%" }}
                                >
                                    {rePostLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}{" "} &nbsp;
                                    YES REPOST IT
                                </ThemeButton>
                            </Grid>
                        </Grid>

                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}

const mapStateToProps = state => {
    return {
        loader: state.commonReducer.loader
    };
};

export default connect(mapStateToProps, {})(RepostListingModal);