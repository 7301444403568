import React from 'react'
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { Product } from "../../../api/resources/Product";
import { User } from "../../../api/resources/User";
import clsx from "clsx";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import { DialogTitle } from '@mui/material';
import Paper from '@mui/material/Paper';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    radioWrapper: {
        '& .MuiFormLabel-root': {
            width: '100%'
        }
    },
    inputRadio: {
        display: 'flex',
        paddingTop: theme.spacing(.7),
        paddingBottom: theme.spacing(.7),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
        border: 'solid 1px #cccccc',
        borderRadius: '4px',
        textAlign: 'left',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(1),
        },
        '&.MuiFormControlLabel-root': {
            marginLeft: theme.spacing(0),
        },
        '& .MuiButtonBase-root': {
            padding: 5
        }
    },
    inputBG: {
        background: '#eef2f5',
        '& .MuiFormControlLabel-root': {
            border: 'transparent'
        }
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#000',
        fontWeight: 'bold'
    },
    noMargin: {
        marginBottom: theme.spacing(0)
    },
    btnDanger: {
        backgroundColor: '#e32b2b',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#c91f1f',
        }
    },
    btnPrimary: {
        backgroundColor: "#fff",
        color: "#2959BA",
        border: '1px solid #ccc'
    },
    paperSpacing: {
        backgroundColor: '#D4DEF1',
        color: '#2959BA',
        padding: theme.spacing(2, 2),
        display: 'grid',
        gridTemplateColumns: '.1fr 1fr',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2.5, 0),
        }
    },
}));

export default function QuotationRejectionForm(props) {
    const classes = useStyles();
    const [isLoading, isLoadingSet] = React.useState(true);
    const [reasonList, setReasonList] = React.useState([]);
    const [reasonID, setReasonID] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    const [rejectSuccess, setRejectSuccess] = React.useState(false);
    const [showBlockDialog, setShowBlockDialog] = React.useState(false);
    const [rejectLoader, setRejectLoader] = React.useState(false);
    const [rejectQuoteReason, setRejectQuoteReason] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [addNegativeList, setAddNegativeList] = React.useState(false);
    const [addNegativeListSuccess, setAddNegativeListSuccess] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleClickOpen = modalName => {
        setOpen(modalName);
    };
    const handleClose = () => {
        setOpen(false);
        props.setCommNoDialog(false);
    };
    const rejectButtonClassName = clsx({
        [classes.buttonSuccess]: rejectSuccess
    });

    React.useEffect(() => {
        setOpen(props.show);
        if (props.show === true) {
            handleClickOpen("rejectModal");
            listRejectQuotationReasons();
        }

    }, [props.show])

    const listRejectQuotationReasons = async params => {
        isLoadingSet(true)
        var params = Object.assign({}, params);

        try {
            const res = await Product.rejectQuotationReasons(params);
            if (res.data.status == "success") {
                setReasonList(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
        isLoadingSet(false)
    };

    const rejectAction = async params => {
        setRejectLoader(true);
        setRejectSuccess(false);
        setAddNegativeList(true);
        var params = Object.assign(
            {
                communication_id: props.communicationId,
                reject_reason_text: rejectQuoteReason,
                reject_reason_id: reasonID,
            }
        );

        try {
            const res = await User.chats.communicationNo(params);
            if (res.data.status == "success") {
                setAddNegativeListSuccess(true);
                setMessage("Company Blocked Successfully!!");
                setRejectLoader(false);
                setAddNegativeList(false);
            }
        } catch (e) {
            console.error(e);
        }
        setRejectLoader(false);
    };

    return (
        <>
            <Dialog
                open={open === "rejectModal"}
                maxWidth="xs"
                TransitionComponent={Transition}
                keepMounted
                onClose={e => {
                    handleClose(e);
                }}
            >
                {showBlockDialog &&
                    <Box sx={{ p: 3 }}>
                        <IconButton
                            aria-label="close"
                            className="closeBtn"
                            size="small"
                            onClick={e => {
                                setShowBlockDialog(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogTitle align="center">
                            <Box sx={{ mb: 1 }}>
                                <img src="/images/no-ad-credits.png" style={{ maxWidth: 240 }} alt="Reject Quotation" />
                            </Box>
                        </DialogTitle>

                        {addNegativeListSuccess &&
                            <>
                                <Box mb={2} textAlign="center">
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {message}
                                    </Typography>
                                </Box>
                                <Box mb={2}>
                                    <Button
                                        type="button"
                                        className={classes.btnDanger}
                                        onClick={e => { handleClose(e); }}
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                    >
                                        CLOSE
                                    </Button>
                                </Box>
                            </>
                        }

                        {!addNegativeListSuccess &&
                            <>
                                <Box mb={2}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Are you sure you want to add <strong>{props.quotation?.quote_sent_by}</strong> to your "Blocked List"?
                                    </Typography>
                                </Box>

                                <Box mb={2}>
                                    <Paper elevation={2} className={classes.paperSpacing}>
                                        <NotificationImportantOutlinedIcon />
                                        Once the company is added to your block list, you won't be able to trade with this company in future.
                                    </Paper>
                                </Box>

                                <Box mb={2}>
                                    <LoadingButton
                                        type="submit"
                                        className={classes.btnDanger}
                                        onClick={e => { rejectAction(); }}
                                        loading={addNegativeList}
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                    >
                                        YES, MOVE THIS TO MY BLOCKED LIST
                                    </LoadingButton>
                                </Box>
                            </>
                        }
                    </Box>
                }

                {rejectSuccess &&
                    <Box sx={{ p: 3 }}>
                        <Box mb={2}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Quotation Rejected Successfully!!
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Button
                                type="button"
                                className={classes.btnDanger}
                                onClick={e => { handleClose(e); }}
                                color="secondary"
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                CLOSE
                            </Button>
                        </Box>
                    </Box>
                }

                {!showBlockDialog && !rejectSuccess && (
                    <Box sx={{ p: 3 }}>
                        <IconButton
                            aria-label="close"
                            className="closeBtn"
                            size="small"
                            onClick={e => {
                                handleClose(e);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <ValidatorForm
                            onSubmit={rejectAction}
                            noValidate>
                            <Box sx={{ textAlign: 'center', fontSize: '24px', mb: 1.5 }}>What is the reason to reject <br /> this deal ?</Box>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.secondaryHeading}>I don’t want to deal with this seller, because...</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <FormControl component="fieldset" size="small" className={`${classes.radioWrapper} d-block`}>
                                        <FormLabel component="legend">
                                            <RadioGroup
                                                name="reason"
                                                value={reasonID}
                                                onChange={e => {
                                                    setReasonID(e.target.value);
                                                }}
                                            >
                                                {!isLoading && reasonList.length > 0 && reasonList.filter((elem, i) => i != 0).map((r, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        className={`${classes.inputRadio} ${classes.inputBG} w-100`}
                                                        value={r.id}
                                                        control={<Radio size="small" color="primary" />}
                                                        label={r.title}
                                                        onChange={e => { setRejectQuoteReason(r.title) }}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormLabel>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            <Box sx={{ my: 2 }} textAlign="center">
                                <Button
                                    onClick={e => {
                                        handleClose(e);
                                    }}
                                    color="primary"
                                    type="button"
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                >
                                    GO BACK TO DEAL
                                </Button>
                                <Button
                                    onClick={e => {
                                        if (reasonID == 2 || reasonID == 3 || reasonID == 4) {
                                            setShowBlockDialog(true);
                                        }
                                    }}
                                    color="secondary"
                                    type={(reasonID == 2 || reasonID == 3 || reasonID == 4) ? "button" : "submit"}
                                    variant="text"
                                    style={{ marginTop: '10px' }}
                                    size="small"
                                    className={rejectButtonClassName}
                                    disabled={rejectLoader || reasonID == null}
                                >
                                    {(reasonID == 2 || reasonID == 3 || reasonID == 4) ? "Next" : "Submit"}
                                </Button>
                                {rejectLoader && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Box>
                        </ValidatorForm>
                    </Box>
                )}
            </Dialog>
        </>
    )
}
