import axios from 'axios';
var uaParser = require('ua-parser-js');

let headers = {
    'Content-Type': 'application/json'
}

// configure an axios instance
const instance = axios.create({
    headers: headers
});


instance.interceptors.request.use(function (config) {

    var params = {}

    if(config.params) {
        params = config.params
    }

    var parsedUA = new uaParser()

    params['cs_ua_source'] = {
        'browser': parsedUA.getBrowser(),
        'device': parsedUA.getDevice(),
        'os': parsedUA.getOS(),
        // 'engine': uaparsed.getEngine(),
        // 'cpu': uaparsed.getCPU(),
    }

    config.params = params

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;
