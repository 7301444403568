import { NOTIFY, CLEAR_NOTIFICATION } from '../actions/types';

const initialState = {
	message: '',
	type: ''
}

export default function notificationReducer(state = initialState, action) {
	switch (action.type) {
		case NOTIFY:
			return {
				...state,
				message: action.payload.message,
				type: action.payload.type
			}
		case CLEAR_NOTIFICATION:
			return {
				...state,
				message: '',
				type: ''
			}
		default:
			return state;
	}
}