import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import MuiLink from '@mui/material/Link';
import { useNavigate, Link } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NoAdCreditPrompt(props) {

    const navigate = useNavigate();
    // console.log(props.data)
    return (
        <Box>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={e => { props.onChangeOpen(false) }}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    className="closeBtn"
                    size="small"
                    onClick={e => { props.onChangeOpen(false) }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box id="alert-dialog-slide-description" sx={{ textAlign: 'center', mb: 1.75 }}>
                        <Box sx={{ mb: 1 }}>
                            <img src="/images/no-ad-credits.png" style={{ maxWidth: 220 }} alt="Ad Credits consumed" />
                        </Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: 16, mb: .55 }}>
                            Not Enough Ad Credits!!
                        </Box>
                        <Box sx={{ mb: .55 }}>
                            {(props.data && props.data.left_quantity !== undefined) && <>
                                Current Balance is
                                {props.data.left_quantity >= 0 && <strong> {props.data.left_quantity} Ad Credits</strong>}
                                {props.data.left_quantity < 0 && <strong style={{ color: 'red' }}> {props.data.left_quantity} Ad Credits</strong>}.
                            </>}
                        </Box>
                        <Box sx={{ mb: .55 }}>
                            You need <strong>{props.data.over_shoot_quantity} Ad Credits</strong> more to <br /> complete the {props.data.action_type == "negative_list_unblock" ? "action" : "deal"}.
                            {/* You need more <strong>
                                {(props.data.quantity && props.data.quantity !== null) ?
                                    props.data.over_shoot_quantity + props.data.quantity :
                                    props.data.over_shoot_quantity
                                } Ad Credits</strong> to <br/> complete the deal. */}
                        </Box>
                        {/* <Box sx={{ mb: .55 }}>
                            Please recharge now or <MuiLink component={Link} to='/contact'>Contact Us</MuiLink>
                        </Box> */}
                    </Box>
                    <Button onClick={e => { navigate('/pricing-plans'); props.onChangeOpen(false) }} variant="contained" fullWidth disableElevation>Recharge Now</Button>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
