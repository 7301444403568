import React from 'react'
import { User } from '../../../api/resources/User';
import { Grid, Fab, Card, CardContent, Button, InputLabel, Box, MenuItem, InputAdornment } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import { Edit } from "@mui/icons-material";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { useSelector, connect } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';
import UpdateProfileContact from "../UpdateProfileContact";
import Emitter from '../../../services/emitter';
import { checkAndLoadUser } from '../../../redux/actions/userActions';

const useStyles = makeStyles(theme => ({
    formGrid: {
        display: "grid",
        gridTemplateColumns: "95px auto",
        alignItems: 'flex-start',
        gridGap: '5px',
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr .5fr",
        alignItems: 'center',
        gridGap: '5px'
    },
    iconBg: {
        position: 'relative',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
    },
    bgGray: {
        backgroundColor: '#F6F6F6'
    },
    marginBottom: {
        marginBottom: 1,
    },
}));

function ProfileForm({ user, cancelButton, onClose }) {
    const classes = useStyles();
    const countries = useSelector(state => state.commonReducer.countries);
    const [firstName, firstNameSet] = React.useState(user?.first_name);
    const [lastName, lastNameSet] = React.useState(user?.last_name);
    const [contactNumber, contactNumberSet] = React.useState(user?.contact_number);
    const [phoneCode, phoneCodeSet] = React.useState('+91');
    const [loading, loadingSet] = React.useState(false);

    const storeUserUpdateProfileAction = async () => {
        loadingSet(true)
        try {
            const res = await User.storeUserUpdateProfileAction(
                {
                    user_id: user.id,
                    first_name: firstName,
                    last_name: lastName,
                    contact_number: user.contact_number
                }
            )
            if (res.data.status && res.data.status == 'success') {
                setTimeout(() => {
                    checkAndLoadUser();
                }, 4000)
                Emitter.emit('SNACKBAR_NOTIFICATION', { message: `User Details updated successfully!`, params: { variant: 'success' } });
            }
        }
        catch (e) {
            console.error(e)
        }
        loadingSet(false)
    }


    return (
        <>
            <ValidatorForm
                noValidate
                onSubmit={e => { storeUserUpdateProfileAction() }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='First Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            size='small'
                            variant="outlined"
                            name="first_name"
                            value={firstName}
                            onChange={e => { firstNameSet(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='Last Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            size='small'
                            variant="outlined"
                            name="last_name"
                            value={lastName}
                            onChange={e => { lastNameSet(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='Email' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            size='small'
                            variant="outlined"
                            name="email"
                            value={user?.email}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>Enter Phone Number<sup className='nota-bene'>*</sup></InputLabel>
                        <Box sx={{ mb: 2 }}>
                            <Box className={classes.formGrid}>
                                <Box sx={{ backgroundColor: '#F6F6F6' }}>
                                    <SelectValidator
                                        value={phoneCode}
                                        name="country"
                                        variant="outlined"
                                        placeholder='Currency'
                                        required
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            phoneCodeSet(e.target.value);
                                        }}
                                        inputProps={{
                                            id: "open-select"
                                        }}
                                        validators={[
                                            "required",
                                        ]}
                                        errorMessages={[
                                            "Required",
                                        ]}
                                    >
                                        {countries.map((option, key) => (
                                            <MenuItem value={option.phone_code} key={key}>
                                                <img
                                                    loading="lazy"
                                                    width="18"
                                                    src={`${option.flag_img}`}
                                                    alt={option.iso}
                                                />{option.phone_code}
                                            </MenuItem>
                                        ))}
                                    </SelectValidator>
                                </Box>

                                <Box className='isRelative'>
                                    <TextValidator variant="outlined"
                                        fullWidth
                                        name="phone"
                                        placeholder="Enter Phone Number"
                                        size="small"
                                        value={contactNumber}
                                        onChange={e => { contactNumberSet(e.target.value); }}
                                        validators={[
                                            "required",
                                            "isNumber",
                                            `minStringLength:${phoneCode == '+91' ? '10' : '7'}`,
                                            `maxStringLength:${phoneCode == '+91' ? '10' : '13'}`,
                                        ]}
                                        errorMessages={[
                                            "Contact number is required!",
                                            "Only Numeric values accepted!",
                                            `Minimum required length is ${phoneCode == '+91' ? '10' : '7'} digit`,
                                            `Max length can not exceed ${phoneCode == '+91' ? '10' : '13'} digit`,
                                        ]}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className="fsSmall"
                                                >
                                                    <UpdateProfileContact
                                                        prevContact={user?.contact_number}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>

                            {phoneCode == '+91' && <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>You will receive an OTP on your number</Box>}
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: 'right' }}>
                    {cancelButton == true &&
                        <Button
                            disableElevation
                            size="large"
                            variant="text"
                            color="inherit"
                            onClick={e => {
                                onClose(e);
                            }}
                        >
                            Cancel
                        </Button>
                    }
                    <LoadingButton
                        disableElevation
                        size="large"
                        loading={loading}
                        color="primary"
                        variant="text"
                        type='submit'
                    >
                        Submit
                    </LoadingButton>

                </Box>
            </ValidatorForm>

        </>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps, { checkAndLoadUser })(ProfileForm);
