import React from 'react';
import { Product } from '../../api/resources/Product';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { validatorRegEx } from '../../helpers';
import { Extras } from '../../api/resources/Extras';
import Emitter from '../../services/emitter';
import { Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';

export default function ContactForm(props) {
  const [submitting, submittingSet] = React.useState(false);
  const [submitSuccess, submitSuccessSet] = React.useState(false);
  // const [countriesVal, countriesSet] = React.useState([]);
  const [checked, setChecked] = React.useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const countries = useSelector((state) => state.commonReducer.countries);
  const coaltypes = useSelector((state) => state.commonReducer.coalTypes);
  const [firstName, firstNameSet] = React.useState('');
  const [lastName, lastNameSet] = React.useState('');
  const [countryCode, countryCodeSet] = React.useState('+91');
  const [contactNumber, contactNumberSet] = React.useState('');
  const [email, emailSet] = React.useState('');
  const [query, querySet] = React.useState('');
  const [coalType, setCoalType] = React.useState([]);
  const [coalTypeId, setCoalTypeId] = React.useState([]);
  const [industries, setIndustries] = React.useState([]);
  const [industry, setIndustry] = React.useState('');

  /**
   * @function getCountries
   * @name getCountries
   * @description
   * An async function to fetch the all the countries
   *
   * @async
   */

  //  const fetchCountries = async (params) => {
  //     setLoadingCountries(true)
  //     var params = Object.assign({
  //         currency_status: 1
  //     })
  //     try {
  //         const res = await Product.getCountries(params);
  //         if (res.data.status == 'success') {
  //             countriesSet(res.data.data)
  //         }
  //     }
  //     catch (e) {
  //         console.error(e)
  //     }
  //     setLoadingCountries(false)
  // }

  // React.useEffect(() => {
  //     fetchCountries()
  // }, [])

  React.useEffect(() => {
    Product.rfqForm()
      .then((response) => {
        setIndustries(response.data.data.ekycIndustries);
      })
      .catch((error) => { })
      .finally(() => { });
  }, []);

  /**
   * @function submitEnquiry
   * @name submitEnquiry
   * @description
   * An async function to submit the users' enquiry via
   * contact widget and contact page form
   *
   * @async
   */

  const submitEnquiry = (e) => {
    submittingSet(true);
    let coaltypes = coalType.map((c) => c.type);

    let data = {
      first_name: firstName,
      last_name: lastName,
      phone: contactNumber,
      country_mobile_prefix: countryCode,
      email: email,
      is_guest: 0,
      mx_Contact_Us_Industries: industry?.name,
      mx_Contact_Us_User_Type: industry?.user_type == 1 ? 'Buyer' : 'Seller',
      mx_Contact_Us_Coal_Types: coaltypes.join(', '),
      message: query,
    };
    Extras.submitEnquiryForm(data)
      .then((response) => {
        if (response.data.status == 'success') {
          submitSuccessSet(true);
          // Show success snackbar on contact page
          if (props.onSuccess) {
            props.onSuccess();
          }
          // Close widget after successful submission
          if (props.isContactFormWidget) {
            props.onSubmitHandleChange();
          }
          Emitter.emit('SNACKBAR_NOTIFICATION', {
            message: `${'Your query has been submitted successfully!'}`,
            params: { variant: 'success' },
          });
          firstNameSet('');
          lastNameSet('');
          contactNumberSet('');
          emailSet('');
          querySet('');
        } else {
          Emitter.emit('SNACKBAR_NOTIFICATION', {
            message: `${'Something went wrong! Please try later.'}`,
            params: { variant: 'default' },
          });
          submitSuccessSet(false);
        }
      })
      .finally(() => {
        submittingSet(false);
      });
  };

  return (
    <>
      <ValidatorForm
        noValidate
        onSubmit={(e) => {
          submitEnquiry(e);
        }}
      >
        <Box
          sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 8 }}
        >
            {/* First Name */}
            <TextValidator
                required
                fullWidth
                // margin="normal"
                sx={{ mr: 1 }}
                label="First Name"
                variant="outlined"
                name="first_name"
                size="small"
                value={firstName}
                onChange={(e) => {
                firstNameSet(e.target.value);
                }}
                validators={[
                'required',
                'isString',
                'maxStringLength: 40',
                `matchRegexp:${validatorRegEx('alpha_no_space')}`,
                ]}
                errorMessages={[
                'First name is required!',
                'Only alphabets are accepted!',
                'Name must cannot be at 40 character long.',
                'Only alphabets are accepted!',
                ]}
            />

            {/* Last Name */}
            <TextValidator
                required
                fullWidth
                // margin="normal"
                //sx={{ mt: 1 }}
                label="Last Name"
                variant="outlined"
                name="last_name"
                size="small"
                value={lastName}
                onChange={(e) => {
                lastNameSet(e.target.value);
                }}
                validators={[
                'required',
                'isString',
                'maxStringLength: 40',
                `matchRegexp:${validatorRegEx('alpha_no_space')}`,
                ]}
                errorMessages={[
                'Last name is required!',
                'Only alphabets are accepted!',
                'Name must cannot be at 40 character long.',
                'Only alphabets are accepted!',
                ]}
            />
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '110px 1fr',
            gap: 1,
            alignItems: 'center',
          }}
        >
            {/* Country code */}
          <SelectValidator
            value={countryCode}
            name="country"
            sx={{ mt: 1 }}
            variant="outlined"
            placeholder="Currency"
            required
            size="small"
            fullWidth
            onChange={(e) => {
              countryCodeSet(e.target.value);
            }}
            inputProps={{
              id: 'open-select',
            }}
            validators={['required']}
            errorMessages={['Required']}
          >
            {countries.map((option, key) => (
              <MenuItem value={option.phone_code} key={key}>
                <img
                  loading="lazy"
                  width="18"
                  src={`${option.flag_img}`}
                  alt={option.iso}
                />
                {option.phone_code}
              </MenuItem>
            ))}
          </SelectValidator>

          {/* Contact number */}
          <TextValidator
            variant="outlined"
            fullWidth
            // margin="normal"
            sx={{ mt: 1 }}
            name="phone"
            label="Contact Number"
            size="small"
            required
            maxlength={10}
            value={contactNumber}
            onChange={(e) => {
              contactNumberSet(e.target.value);
            }}
            validators={[
              'required',
              'isNumber',
              'minStringLength:10',
              `maxStringLength:${countryCode == '+91' ? '10' : '13'}`,
              // `matchRegexp:${validatorRegEx("mobile")}`
            ]}
            errorMessages={[
              'Contact number is required!',
              'Only Numeric values accepted!',
              'Minimum required length is 10 digit',
              `Max length can not exceed ${countryCode == '+91' ? '10' : '13'
              } digit`,
              // "Max length can not exceed 10 digit",
              // "Contact number can not start with 0",
            ]}
          />
        </Box>

            {/* Email */}
            <TextValidator
            variant="outlined"
            // margin="normal"
            sx={{ mt: 1 }}
            required
            size="small"
            fullWidth
            label="Email"
            name="email"
            value={email}
            onChange={(e) => {
                emailSet(e.target.value);
            }}
            validators={[
                'required',
                'isEmail',
                `matchRegexp:${validatorRegEx('email')}`,
            ]}
            errorMessages={[
                'Please enter your email',
                'Field is not valid',
                'Email address is not valid!',
            ]}
            />

        <Box
          sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 8 }}
        >
            {/* Coal type */}
            <Autocomplete
            multiple
            name="coal_type"
            size="small"
            filterSelectedOptions
            options={coaltypes}
            onChange={(e, coal) => {
                setCoalType(coal);
                // setCoalTypeId(coal.type);
            }}
            getOptionLabel={(coal) => (coal.type ? coal.type : '')}
            value={coalType}
            renderInput={(params) => (
                <TextValidator
                {...params}
                variant="outlined"
                required
                // margin="normal"
                sx={{ mt: 1 }}
                label="Coal Type"
                fullWidth
                validators={['required']}
                errorMessages={['Coal Type field is required']}
                value={coalType}
                />
            )}
            />

            {/* Industry */}
            <Autocomplete
            name="industry"
            size="small"
            filterSelectedOptions
            options={industries}
            onChange={(e, i) => {
                setIndustry(i);
            }}
            getOptionLabel={(coal) => (coal.name ? coal.name : '')}
            value={industry}
            renderInput={(params) => (
                <TextValidator
                {...params}
                variant="outlined"
                required
                // margin="normal"
                sx={{ mt: 1 }}
                label="Industry"
                fullWidth
                validators={['required']}
                errorMessages={['Industry field is required']}
                value={industry}
                />
            )}
            />
        </Box>

        {/* Message */}
        <TextValidator
          variant="outlined"
          // margin="normal"
          sx={{ mt: 1 }}
          required
          multiline
          fullWidth
          label="Message"
          name="message"
          rows="2"
          value={query}
          onChange={(e) => {
            querySet(e.target.value);
          }}
          inputProps={{
            maxLength: 250,
          }}
          helperText={
            <Box component="span" display="block" textAlign="right">
              Words: {query && query.length} /250{' '}
            </Box>
          }
          validators={[
            'required',
            'isString',
            `matchRegexp:${validatorRegEx(
              'alphaNum_with_space_fullStop_comma_dash_slash'
            )}`,
          ]}
          errorMessages={[
            'Please enter your message',
            '',
            'Only alphabets, numbers, spaces, comma, slash and full-stop accepted.',
          ]}
        />

        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          {/* <input type="checkbox" /> */}
          <Typography fontSize={12} color="text.secondary" component="div">
            I authorize CoalShastra Pvt Ltd and it's representative to contact
            me with updates and notifications via Email, SMS, WhatsApp, and
            Call. This will override the registry on DND/NDNC.
          </Typography>
        </Box>

        <Box textAlign="right">
          <LoadingButton
            loading={submitting}
            fullWidth={props.matchesViewport ? true : false}
            variant="contained"
            disabled={checked ? false : true}
            color="primary"
            type="submit"
            disableElevation
          >
            {submitting ? 'Submitting' : 'Submit'}
          </LoadingButton>
        </Box>
      </ValidatorForm>
    </>
  );
}
