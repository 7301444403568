import React from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from "@mui/material/Box";

export default function RatePercent(props)
{
    return <Box fontWeight='bold' display='inline' {...props}>
        {props.percentage > 0 && <><span style={{display: "inline-block"}} className="success-green"> +{props.percentage} %<ArrowDropUpIcon className={`vAlignBottom`}/></span></>}
        {props.percentage < 0 && <><span style={{display: "inline-block"}} className="text-danger">{props.percentage} %<ArrowDropDownIcon className={`vAlignBottom`}/></span></>}
        {props.percentage == 0 && <span style={{display: "inline-block"}}>{props.percentage} %</span>}
    </Box>
}