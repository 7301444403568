import React from "react";
import Rating from '@mui/material/Rating';
import { Box, Typography } from "@mui/material";
import posed, { PoseGroup } from "react-pose";
import makeStyles from '@mui/styles/makeStyles';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PropTypes from 'prop-types';

const RatingValAnimation = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
});

const FadeAndDrop = posed.span({
    enter: {
        y: 0,
        opacity: 1,
        transition: { duration: 700 },
    },
    exit: {
        y: ({ fromDirection }) => (fromDirection === "left" ? -30 : 30),
        opacity: 0,
        overflow: 'hidden'
    }
});

const customIcons = {
    1: {
        icon: <StarBorderIcon size="small" />,
        point: 1
    },
    2: {
        icon: <StarBorderIcon size="small" />,
        point: 2
    },
    3: {
        icon: <StarBorderIcon size="small" />,
        point: 3
    },
    4: {
        icon: <StarBorderIcon size="small" />,
        point: 4
    },
    5: {
        icon: <StarBorderIcon size="small" />,
        point: 5
    },
};

const useStyles = makeStyles(theme => ({
    ratingVal: {
        height: "25px",
        // width: "170px"
    },
    initialStarColor: {
        //width: "100%",
        '& .MuiRating-iconEmpty': {
            textAlign: 'center',
            paddingTop: theme.spacing(.5),
            paddingBottom: theme.spacing(.5),
            paddingLeft: theme.spacing(2),
            border: 'solid 1px #cccccc',
            borderRadius: '5px',
        },
        '& .MuiRating-iconFilled': {
            textAlign: 'center',
            paddingTop: theme.spacing(.5),
            paddingBottom: theme.spacing(.5),
            paddingLeft: theme.spacing(2),
            border: 'solid 1px #cccccc',
            borderRadius: '5px',
            backgroundColor: "#fee10b",
            color: '#585151de',
            border: '1px solid #fee10b'

        }
    },
    ratingGrid: {
        display: 'grid',
        gridTemplateColumns: '.5fr .5fr .5fr .5fr .5fr',
        gridGap: '.7rem',
    },
}));

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].point}{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function ReviewRatingOnDeals(props) {
    const classes = useStyles();
    let question = props.question;
    let starLabels = [];
    question.active_descriptions.forEach((d, k) => {
        starLabels[d.point] = d.desc;
    });
    const [value, setValue] = React.useState(parseInt(props.value));

    const [hover, setHover] = React.useState(-1);

    React.useEffect(() => {
        props.onChange({ rating_reward_id: props.rewardId, rating_value: value });
    }, [props.rewardId, value]);

    return (
        <div>
            <Box component="fieldset" p={0} borderColor="transparent" className={classes.initialStarColor}>
                <Rating
                    name={props.name}
                    className={classes.ratingGrid}
                    value={value}
                    IconContainerComponent={IconContainer}
                    highlightSelectedOnly
                    onChange={(event, newValue) => {
                        setValue(parseInt(event.target.value));
                        props.onChange({
                            rating_reward_id: props.rewardId,
                            rating_value: (event.target.value),
                        });
                    }}
                    required
                    size="small"
                />
                <RatingValAnimation ml={0} className={classes.ratingVal}>
                    <PoseGroup>
                        <FadeAndDrop key={value}>
                            {starLabels[hover !== -1 ? hover : value]}
                            {props.ratingErrorMsg &&
                                <Box mt={1}>
                                    <Typography component='p' color='error' className='has__error'>
                                        Please rate this deal.
                                    </Typography>
                                </Box>
                            }
                        </FadeAndDrop>
                    </PoseGroup>
                </RatingValAnimation>
            </Box>
        </div>
    );
}
