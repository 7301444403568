import React, { Component } from "react";
import { User } from "../../api/resources/User";
import eventsTracking from '../../eventsTracking';
import { storeUserToken } from '../../helpers'
import { checkAndLoadUser } from '../../redux/actions/userActions'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Step1 from './Step1';
import {
  Grid,
  Avatar,
  Typography,
  Container,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Box,
  Divider,
  CircularProgress,
  InputLabel
} from "@mui/material";
import ThemeButton from "../partials/ThemeButton"
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { validatorRegEx } from "../../helpers";
import { useParams } from "react-router";
import "../../sass/modal.scss";
import { connect } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import { notify } from "../../redux/actions/notificationActions";
import "../../sass/form-validations.scss";
import LanguageTranslate from '../partials/UnderScoreLanguage'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Loader from "../partials/Loader";
import Snackbar from "../partials/Snackbar";
import LoadingButton from '@mui/lab/LoadingButton';
import Emitter from "../../services/emitter";
import HelpAndSupport from "../partials/HelpAndSupport";
import OTPTimer from "../partials/OTPTimer";
import BrandStoreStep1 from "./BrandStoreStep1";

const styles = {
  otpInputField: {
    width: "40px!important",
    height: "40px",
    lineHeight: "40px",
    border: "0",
    background: "rgba(0, 0, 0, 0.05)",
    borderBottom: '2px #1d2a72 solid'
  },
  otpInputWrapper: {
    width: '176px',
    margin: 'auto'
  },
  success: {
    backgroundColor: '#7cb342 !important',
    color: '#fff !important'
  },
  marginBottom: {
    marginBottom: 1,
  },
  gridCol2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: 'center',
    gridGap: '5px',
    padding: 20,
    marginLeft: 15,
    marginRight: 15
  },
};

/**
 *
 * @name BrandStoreSignup
 * @alias BrandStoreSignup
 * @component
 * @exports BrandStoreSignup
 *
 * @description
 * SignUp Form and related Actions
 */

class BrandStoreSignup extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
      message: null,
      otpDialog: false,
      sendingOtp: false,
      otp: '',
      delay: 90,
      otpResponseData: '',
      otpFailMessage: false,
      verifyingOtp: false,
      otpVerificationFailMessage: false,
      contactNumberVerified: false,
      emailVerified: false,
      type: 'password',
      inputs: {
        first_name: "",
        last_name: "",
        contact_number: this.props.contact_number,
        email: "",
        user_type: "3",
        password: "",
        password_confirmation: "",
        register_consent: 0,
        isVerified: false,
        otp: '',
        country_mobile_prefix: this.props.phoneCode,
        // country_id: this.props.countryId,
        // is_international: this.props.countryId == 1 ? 0 : 1,
      },
      user_type_error: null,
      temp_user_type: [],
      register_consent_error: null,

      loading: false
    };
    this.showHidePasswordInput = this.showHidePasswordInput.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  showHidePasswordInput(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text'
    })
  }
  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.inputs.password) {
        return false;
      }
      return true;
    });
    // const token = await this.props.googleReCaptchaProps.executeRecaptcha('singup');
  }

  showOtpDialog = (status) => {
    this.setState({ otpDialog: status })
  }


  handleOtpDialogClose = () => {
    this.showOtpDialog(false);
  };

  /**
     * @function sendOtp
     * @name sendOtp
     * @description
     * Function to send Otp
     *
     */

  sendOtp = (type, data) => {
    this.setState({ sendingOtp: true })
    this.setState({ otpFailMessage: false })
    var params = {
      type: type == 'email' ? 1 : 2
    }
    if (params.type == 1) {
      params.email = data
    }
    else {
      params.contact_number = data
    }
    User.sendVerificationCode(params)
      .then(res => {
        // console.log(res)
        if (res.data.status == 'fail') {
          this.setState({ otpFailMessage: res.data.message })
        }
        if (res.data.status == 'success') {
          this.setState({ otpResponseData: res.data.data });
          Emitter.emit('SNACKBAR_NOTIFICATION', { message: `OTP send successfully!`, params: { variant: 'success' } });
        }
      })
      .finally(() => {
        this.setState({ sendingOtp: false })
      })
  }

  /**
     * @function verifyOtp
     * @name verifyOtp
     * @description
     * Function for verify Otp
     *
     */

  verifyOtp = (type, data) => {
    this.setState({ verifyingOtp: true })
    this.setState({ otpVerificationFailMessage: false })
    var params = {
      key: this.state.otpResponseData,
      value: data
    }
    User.verifyOtp(params)
      .then(res => {
        // console.log(res)
        if (res.data.status == 'fail') {
          this.setState({ otpVerificationFailMessage: 'Wrong OTP, please try again.' })
        }
        if (res.data.status == 'success') {
          if (type == 'email') {
            this.setState({ emailVerified: true })
            this.setState({ otpVerificationFailMessage: false })
            eventsTracking.emailVerified()
          }
          else {
            this.setState({ contactNumberVerified: true })
            this.setState({ otpVerificationFailMessage: false })
            eventsTracking.mobileVerified()
          }

          this.showOtpDialog(false)
          this.setState({ otp: '' })
        }
      })
      .finally(() => {
        this.setState({ verifyingOtp: false })
      })
  }

  /**
     * @function handleInputChange
     * @name handleInputChange
     * @description
     * Function to handle Input Change
     *
     */

  handleInputChange = e => {
    var inputs = this.state.inputs;
    inputs[e.target.name] = e.target.value;

    if (e.target.name === 'email') {
      inputs[e.target.name] = e.target.value.toString().trim(' ').toLowerCase();
    }

    if (e.target.name === 'user_type') {
      let selectedValues = this.state.temp_user_type
      let selectedValue = e.target.value
      if (selectedValues.includes(selectedValue)) {
        selectedValues.splice(selectedValues.indexOf(selectedValue), 1);
      }
      else {
        selectedValues.push(selectedValue)
      }

      this.setState({ temp_user_type: selectedValues })

      //  Set checkbox value for user type. I.e; user is buyer or seller or a trader.

      if (selectedValues.length === 2) {
        inputs.user_type = 3
      }
      else if (selectedValues.length === 0) {
        inputs.user_type = null
      }
      else {
        inputs.user_type = parseInt(this.state.temp_user_type[0])
      }

    }

    if (e.target.name == 'register_consent') {
      inputs.register_consent = this.state.inputs.register_consent == 0 ? 1 : 0
    }

    this.setState({
      inputs: inputs
    });

    if (e.target.name === 'register_consent') {
      this.checkConsentAggrementValidation()
    }
  };


  handleSubmit(event, errors, inputs) {
    this.setState({ errors, inputs });
  }


  checkConsentAggrementValidation = () => {
    // checkbox validation
    this.setState({ register_consent_error: false })
    if (this.state.inputs.register_consent === 0) {
      this.setState({ register_consent_error: true })
      return false
    }

    return true
  }

  avoidSpace = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({ show: snapshot.show });
    }
  }

  getSnapshotBeforeUpdate(nextProps, prevState) {
    if (nextProps.show !== prevState.show) {
      return { show: nextProps.show };
    } else {
      return null;
    }
  }

  toggle() {
    this.setState(prevState => ({
      show: !prevState.show
    }));
    if (!this.state.show) {
      // console.log('close sigUp modal')98
      this.props.closeSignUpModal(true);
    }
  }

  /**
     * @function signUp
     * @name signUp
     * @description
     * Function to submit signUp form
     *
     */

  signUp(e, errors, inputs) {
    e.preventDefault();

    let stateInputs = {
      first_name: this.state.inputs.first_name,
      last_name: this.state.inputs.last_name,
      contact_number: this.props.contact_number,
      email: this.state.inputs.email,
      user_type: "3",
      password: this.state.inputs.password,
      password_confirmation: this.state.inputs.password_confirmation,
      register_consent: 0,
      isVerified: false,
      otp: this.state.inputs.otp,
      country_mobile_prefix: this.props.phoneCode,
      country_id: this.props.countryId,
      is_international: this.props.countryId == 1 ? 0 : 1,
    };

    this.setState({ loading: true });

    // Post signup form
    User.signUp
      .submitV2(stateInputs)
      .then(response => {
        if (response.data.status === "fail") {
          this.setState({
            message: response.data.message
          });
        }
        if (response.data.status === "success") {
          this.setState({
            message: response.data.message
          });
          eventsTracking.userRegistered()
          //   this.props.setTempUser(stateInputs);
          storeUserToken('user', response.data.data.token)
          this.props.checkAndLoadUser()
          //   window.location = '/virtual-office/inbox'
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });

  }

  render() {
    const defaultValues = this.state.inputs;
    const message = this.state.message;
    const loading = this.state.loading;
    // console.log(this.props.countryId)
    // Navigate user to add company form.
    if (this.props.isLoggedIn && this.props.noCompany) {
      return <Navigate to="/brand-stores/add-company-eKyc" />;
    }

    // Navigate user to virtual office if company is added.
    if (this.props.isLoggedIn && !this.props.noCompany) {
      return <Navigate to="/dashboard/my-listings" />;
    }
    return (
      <Box className="signUpForm form-ui" py={3}>
        <Container component="main" maxWidth="xs">
          <div>
            <Typography component="h1" variant="h5" align="center">
              <strong>Welcome to CoalShastra!</strong> <br />
              <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px', mb: 2, mt: 1 }}>Enter the largest marketplace for buying and selling coal.</Box>
            </Typography>

            {this.props.contact_number == 0 ?
              <BrandStoreStep1 isSignUpPage={true} />
              :
              <Container component="main" maxWidth="xs">
                <ValidatorForm
                  onSubmit={e => this.signUp(e)}
                  model={defaultValues}
                  noValidate
                >
                  {(!this.state.otpResponseData || this.state.emailVerified) &&
                    <>
                      <Grid container spacing={2}>
                        {message && (
                          <Box mb={2} mx={'auto'} sx={{ textAlign: 'center' }}>
                            <Snackbar
                              open={true}
                              message={message}
                            />
                          </Box>
                        )}
                        <br />

                        <Grid item sm={6} xs={12}>
                          {this.props.signedUp}
                          <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='First Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                          <TextValidator
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            autoFocus
                            id="first_name"
                            autoComplete="first_name"
                            //label={<LanguageTranslate s='First Name' />}
                            name="first_name"
                            onChange={this.handleInputChange}
                            value={this.state.inputs.first_name}
                            validators={[
                              "required",
                              `matchRegexp:${validatorRegEx("alpha_with_space")}`,
                              "maxStringLength:50"
                            ]}
                            errorMessages={[
                              "First name is required!",
                              "Only alphabets are accepted!",
                              "Max length is 50"
                            ]}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          {this.props.signedUp}
                          <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='Last Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                          <TextValidator
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            id="last_name"
                            autoComplete="last_name"
                            //label={<LanguageTranslate s='Last Name' />}
                            name="last_name"
                            onChange={this.handleInputChange}
                            value={this.state.inputs.last_name}
                            validators={[
                              "required",
                              //"isString",
                              `matchRegexp:${validatorRegEx("alpha_with_space")}`,
                              "maxStringLength:50"
                            ]}
                            errorMessages={[
                              "Last name is required!",
                              "Only alphabets are accepted!",
                              //   "Only alphabets are accepted!",
                              "Max length is 50"
                            ]}
                          />
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12} className='isRelative'>
                        {this.props.signedUp}
                        <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='Email ID' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                          variant="outlined"
                          required
                          size="small"
                          fullWidth
                          id="email"
                          autoComplete="email"
                          //label={<LanguageTranslate s='Email' />}
                          name="email"
                          //className="lowercase"
                          disabled={this.state.emailVerified}
                          // onChange={this.handleInputChange}
                          onChange={e => {
                            this.handleInputChange(e);
                            if (this.state.inputs.email.match(validatorRegEx("email"))) {
                              eventsTracking.emailValid()
                            }
                          }}
                          value={this.state.inputs.email}
                          onKeyDown={this.avoidSpace}
                          validators={[
                            "required",
                            "isEmail",
                            `matchRegexp:${validatorRegEx("email")}`
                          ]}
                          errorMessages={[
                            "Email is required!",
                            "Input must be a valid email address",
                            "Only underscore (_), dash (-) and dot (.) are accepted."
                          ]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="fsSmall">
                                {this.state.emailVerified ? <CheckCircleIcon style={{ color: 'green' }} /> : ''}
                              </InputAdornment>
                            )
                          }}
                        />
                        {this.state.otpFailMessage &&
                          <Box mt={2}>
                            <Typography component='p' color='error' className='has__error'>{this.state.otpFailMessage}</Typography>
                          </Box>
                        }
                        {/* {console.log(this.state.inputs.email)} */}
                      </Grid>
                    </>
                  }
                  {(this.state.otpResponseData && !this.state.emailVerified) &&
                    <Grid item xs={12} sm={12}>
                      <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='Enter OTP' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        name="otp"
                        //label="Enter OTP"
                        onChange={this.handleInputChange}
                        // placeholder="Enter OTP"
                        size="small"
                        value={this.state.inputs.otp}
                        // onChange={e => this.setState({ otp: e })}
                        validators={[
                          "required",
                          "isNumber",
                        ]}
                        errorMessages={[
                          "OTP is required!",
                          "Only Numeric values accepted!",
                        ]}
                      />
                      <span className='text-danger'><br />{this.state.otpVerificationFailMessage}</span>

                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                        <Box>Please confirm OTP sent to <strong>{this.state.inputs.email}</strong></Box>
                        <Button type='text' color='primary' onClick={e => { this.setState({ otpResponseData: '' }) }} style={{ color: 'blue', textDecoration: 'underline' }}>Change</Button>
                      </Box>


                    <OTPTimer setDelay={e => this.setState({delay: e})} delay={this.state.delay} />

                      <Box textAlign='center' sx={{ color: '#6A6A6A', py: .5, fontSize: '15px', mb: 1, mt: 2 }}>Don't received OTP? {" "}<Button onClick={e => {
                        this.sendOtp('email', this.state.inputs.email);
                        this.setState({delay: 90})
                      }} color="primary" sx={{ color: 'blue', textDecoration: 'underline' }} disabled={this.state.delay !== 0 ? true : false }>Resend OTP</Button>
                      </Box>
                    </Grid>
                  }
                  <Grid item sm={12} xs={12}>
                    <div className={this.props.classes.inputRightAbs}>
                      {(!this.state.emailVerified && !this.state.otpResponseData) &&
                        <Box mt={2}>
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            color="primary"
                            loading={this.state.sendingOtp}
                            disabled={this.state.inputs.email.length == 0}
                            type="button"
                            onClick={e => {
                              this.sendOtp('email', this.state.inputs.email);
                              //this.showOtpDialog('email')
                            }}
                          >
                            <LanguageTranslate s='VERIFY' />
                          </LoadingButton>
                        </Box>
                      }
                      {(this.state.otpResponseData && !this.state.emailVerified) &&
                        <LoadingButton
                          variant="contained"
                          fullWidth
                          color="primary"
                          disabled={this.state.inputs.otp.length !== 4}
                          loading={this.state.verifyingOtp}
                          type="button"
                          onClick={e => { this.verifyOtp('email', this.state.inputs.otp) }}
                        >
                          <LanguageTranslate s='VERIFY OTP' />
                        </LoadingButton>
                      }
                    </div>
                  </Grid>

                  {/* {!this.state.emailVerified &&
                    <Grid item xs={12} sm={12}>
                      <Box textAlign="center">
                        <Button variant="text" sx={{ textDecoration: 'underline', color: '#2959BA' }} color="primary" component={Link} to="/">Skip</Button>
                      </Box>
                    </Grid>
                  } */}
                  {/* Hidden until the email and password is verified  */}
                  {(this.state.emailVerified) && (
                    <>

                      <Grid item xs={12} sm={12}>
                        <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='Create Your Password' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                          variant="outlined"
                          required
                          size="small"
                          fullWidth
                          id="password"
                          name="password"
                          //label={<LanguageTranslate s='Enter Password' />}
                          // type="password"
                          type={this.state.type}
                          disabled={!this.state.emailVerified}
                          autoComplete="current-password"
                          onChange={this.handleInputChange}
                          value={this.state.inputs.password}
                          validators={["required", "isString", "minStringLength:8"]}
                          errorMessages={[
                            "Password is required!",
                            "Field is not valid"
                          ]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="fsSmall">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.showHidePasswordInput}
                                  edge="end"
                                >
                                  {this.state.type === 'password' ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputLabel className={this.props.classes.marginBottom}><LanguageTranslate s='Confirm Your Password' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                          variant="outlined"
                          required
                          size="small"
                          fullWidth
                          id="confirm_password"
                          disabled={!this.state.emailVerified}
                          name="password_confirmation"
                          //label={<LanguageTranslate s='Confirm Password' />}
                          // type="password"
                          type={this.state.type}
                          // onChange={this.handleInputChange}
                          onChange={e => {
                            this.handleInputChange(e);
                          }}
                          value={this.state.inputs.password_confirmation}
                          validators={["isPasswordMatch", "required", "isString", "minStringLength:8"]}
                          errorMessages={[
                            "Password mismatch!",
                            "Password confirmation is required.",
                            "Confirm your password!",
                            "Field is not valid"
                          ]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="fsSmall">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.showHidePasswordInput}
                                  edge="end"
                                >
                                  {this.state.type === 'password' ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>


                      <Grid item sm={12}>
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            disabled={(!this.state.emailVerified)}
                            type="submit"
                            onClick={e => this.signUp(e)}
                          >
                            <LanguageTranslate s='SUBMIT' />
                          </Button>
                        </Box>

                        {loading && <Loader />}
                      </Grid>
                    </>
                  )}
                  {/* Hidden until the email and password is verified  */}
                </ValidatorForm>
              </Container>
            }
          </div>

          <Box sx={{ mt: 3 }}>
            {/* <HelpAndSupport /> */}
          </Box>
        </Container >
      </Box >
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    loggingIn: state.userReducer.loggingIn,
    isLoggedIn: state.userReducer.isLoggedIn,
    noCompany: state.userReducer.noCompany,
  };
};

export default connect(mapStateToProps, { notify, checkAndLoadUser })(withStyles(styles)(BrandStoreSignup));
