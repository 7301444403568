import React from 'react'
import { useSelector } from 'react-redux'

export default function UnderScoreLanguage({s})
{
    const languageCode = useSelector(state => state.commonReducer.languageCode)
    const translations = useSelector(state => state.commonReducer.translations)
    const [translated, translatedSet] = React.useState(s)

    React.useEffect(() => {
        if(languageCode !== 'en' && translations && translations[s.toLowerCase()])
        {
            translatedSet(translations[s.toLowerCase()])
        }
        else {
            translatedSet(s)
        }
    }, [languageCode])

    return <>{translated}</>
}
