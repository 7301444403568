import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { Rating } from '@mui/material';
import {
    AccountCircle,
} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({

    badgesContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        // alignItems: 'center',
        gridGap: '5px',
    },

    statsWrapper: {
        position: 'relative',
    },
    stats: {
        position: 'absolute',
        top: '25px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    statsSM: {
        position: 'absolute',
        top: '10px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '9px'
    },

    bold: {
        fontWeight: "bold",
        color: '#2a2a2a'
    },
    textMuted: {
        color: "#6A6A6A",
        fontSize: 13,
    },
}))

const UserPlanIcon = ({ user }) => {
    return user?.active_revenueable_invoice_consumption == null ? (
        <span title={"UnPaid User"}>
            <AccountCircle
                size="small"
                color="primary"
                style={{ fontSize: "1rem" }}
            />
        </span>
    ) : (
        <img
            src={user?.active_revenueable_invoice_consumption?.plan?.icon_src}
            width={20}
            title={
                user?.active_revenueable_invoice_consumption?.plan?.title +
                " User"
            }
            alt={
                user?.active_revenueable_invoice_consumption?.plan?.title +
                " User"
            }
        />
    );
};

export default function BadgesOnly(props) {
    const classes = useStyles();
   // console.log({props})
    return (
        <>
            {props.isChatModalV3 == true ?
                <Box>
                    {props.label == 'Buyer Info' && (props.payment_term_id == 2 || props.payment_term_id == 3) ?
                        <Box>
                            <Box style={{ display: 'flex', alignItem: "center" }}>
                                <UserPlanIcon user={props.requestData} />
                                <small style={{ marginLeft: "5px", fontSize: '12px', fontWeight: "bold", display: "inline-block" }}>{props.label}</small>
                            </Box>
                            <Box className={classes.textMuted} style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}>
                                <Box style={{ display: 'flex' }}>
                                    <Rating
                                        value={parseFloat(props.requestData?.rating?.avg_round)}
                                        precision={0.5}
                                        readOnly
                                        sx={{ fontSize: '1rem' }}
                                    /> {props.requestData?.rating?.avg_round} |  {props.reviewLength} Reviews
                                </Box>
                            </Box>
                            <Box>
                                <small className={classes.textMuted}> {props.requestData?.badges?.deal_quantity?.text} | {props.requestData?.badges?.deal_count?.text} | {props.requestData?.badges?.age_of_account?.text}
                                </small>
                            </Box>
                        </Box>
                        :
                        props.label == 'Seller Info' && props.payment_term_id != 3 &&
                        <Box>
                            <Box style={{ display: 'flex', alignItem: "center" }}>
                                <UserPlanIcon user={props.requestData} />
                                <small style={{ marginLeft: "5px", fontSize: '12px', fontWeight: "bold", display: "inline-block" }}>{props.label}</small>
                            </Box>
                            <Box className={classes.textMuted} style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}>
                                <Box style={{ display: 'flex' }}>
                                    <Rating
                                        value={parseFloat(props.requestData?.rating?.avg_round)}
                                        precision={0.5}
                                        readOnly
                                        sx={{ fontSize: '1rem' }}
                                    /> {props.requestData?.rating?.avg_round} |  {props.reviewLength} Reviews
                                </Box>
                            </Box>
                            <Box>
                                <small className={classes.textMuted}> {props.requestData?.badges?.deal_quantity?.text} | {props.requestData?.badges?.deal_count?.text} | {props.requestData?.badges?.age_of_account?.text}
                                </small>
                            </Box>
                        </Box>
                    }
                </Box>

                :
                <Box className={classes.badgesContainer} textAlign='center' py={props.isChatModal ? 0 : 1.5} px={0}>

                    <Box>
                        {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" &&
                            <Typography variant="subtitle2"><strong>Quantity {props.matchesViewport && <br />}Transacted</strong></Typography>
                        }
                        <Box className={classes.statsWrapper}>
                            {/* <img src="/images/badge-green.svg" /> */}
                            {(props.quantity_transacted < 1000) && <img src="/images/badge-gray.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            {(props.quantity_transacted >= 1000) && <img src="/images/badge-green.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            <Box className={`${props.isChatModal !== true ? classes.stats : classes.statsSM} ${classes.bold} text-white`}>
                                {(props.quantity_transacted < 1000) && '1K'}
                                {(props.quantity_transacted >= 1000 && props.quantity_transacted < 2500) && '1K'}
                                {(props.quantity_transacted >= 2500 && props.quantity_transacted < 10000) && '2.5K'}
                                {(props.quantity_transacted >= 10000 && props.quantity_transacted < 50000) && '10K'}
                                {(props.quantity_transacted >= 50000 && props.quantity_transacted < 100000) && '50K'}
                                {(props.quantity_transacted >= 100000 && props.quantity_transacted < 200000) && '1L'}
                                {(props.quantity_transacted >= 200000 && props.quantity_transacted < 500000) && '2L'}
                                {(props.quantity_transacted >= 500000 && props.quantity_transacted < 1000000) && '5L'}
                                {(props.quantity_transacted >= 1000000) && '1M'}
                            </Box>
                        </Box>
                        {props?.isChatModal === false &&
                            <Box>
                                {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" ?
                                    <Typography variant="body2" color="textSecondary">{props.quantity_transacted} MT</Typography>
                                    :
                                    <Typography variant='body2' className={classes.textDark}>Quantity <br />Transacted <br />(MT) </Typography>
                                }
                            </Box>
                        }
                    </Box>
                    <Box>
                        {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" &&
                            <Typography variant="subtitle2"><strong>Transactions {props.matchesViewport && <br />} Done</strong></Typography>
                        }
                        <Box className={classes.statsWrapper}>
                            {/* <img src="/images/badge-yellow.svg" /> */}
                            {(props.transactions_done < 10) && <img src="/images/badge-gray.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            {(props.transactions_done >= 10) && <img src="/images/badge-yellow.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            <Box className={`${props.isChatModal !== true ? classes.stats : classes.statsSM} ${classes.bold} text-white`}>
                                {(props.transactions_done < 10) && '10'}
                                {(props.transactions_done >= 10 && props.transactions_done < 25) && '10'}
                                {(props.transactions_done >= 25 && props.transactions_done < 50) && '25'}
                                {(props.transactions_done >= 50 && props.transactions_done < 100) && '50'}
                                {(props.transactions_done >= 100 && props.transactions_done < 250) && '100'}
                                {(props.transactions_done >= 250 && props.transactions_done < 500) && '250'}
                                {(props.transactions_done >= 500) && '500'}
                            </Box>
                        </Box>
                        {props?.isChatModal === false &&
                            <Box>
                                {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" ?
                                    <Typography variant="body2" color="textSecondary">{props.transactions_done} Deals</Typography>
                                    :
                                    <Typography variant='body2' className={classes.textDark}>Transactions <br />Done </Typography>
                                }
                            </Box>
                        }
                    </Box>
                    <Box>
                        {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" &&
                            <Typography variant="subtitle2"><strong>Age of {props.matchesViewport && <br />}Account</strong></Typography>
                        }
                        <Box className={classes.statsWrapper}>
                            {/* <img src="/images/badge-blue.svg" /> */}
                            {(props.age_of_account < 1) && <img src="/images/badge-gray.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            {(props.age_of_account >= 1) && <img src="/images/badge-blue.svg" style={props.isChatModal == true ? { width: 30 } : { width: 75 }} />}
                            <Box className={`${props.isChatModal !== true ? classes.stats : classes.statsSM} ${classes.bold} text-white`}>
                                {(props.age_of_account < 1) && '1+'}
                                {(props.age_of_account >= 1 && props.age_of_account < 2) && '1+'}
                                {(props.age_of_account >= 2 && props.age_of_account < 3) && '2+'}
                                {(props.age_of_account >= 3 && props.age_of_account < 5) && '3+'}
                                {(props.age_of_account >= 5) && '5+'}
                            </Box>
                        </Box>
                        {props?.isChatModal === false &&
                            <Box>
                                {props.isUserDashboard && props.isUserDashboard === "isUserDashboard" ?
                                    <Typography variant="body2" color="textSecondary">{props.age_of_account} Years</Typography>
                                    :
                                    <Typography variant='body2' className={classes.textDark}>Age of <br />Account <br />(Years)</Typography>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    )
}