import { fcmAnalytics } from './firebase'
// import { validatorRegEx } from "./helpers";
const prefix = 'web_';

function HanselLogEvent(...params) {
    console.log(...params);

    if ('Hansel' in window) {
        window.Hansel.logEvent(...params);
        // window.smartech(...params);
    } else {
        window.addEventListener('pxSdkReady', () => {
            window.Hansel.logEvent(...params);
            // window.smartech(...params);
        })
    }
}

const eventsTracking = {

    // Homepage
    latestFiveDeals: (user, dealItem) => {
        const eventName = 'Latest_five_deals_opened'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            deal_name: dealItem,
        });

    },
    signUpFromHomepageCTA: () => {
        const eventName = 'register_from_homepage_CTA'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    guestAddGST: (user) => {
        const eventName = 'add_GSTIN_from_homepage_CTA'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user
        });

    },
    UsrRegisteredFromHomeModal: () => {
        const eventName = 'homepage_user_goes_to_sign_up_page_from_modal'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    UsrSkipsRegistrationFromHomeModal: () => {
        const eventName = 'homepage_user_skips_sign_up_from_modal'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    partialUsrAddGST: (user) => {
        const eventName = 'homepage_partial_user_goes_to_ekyc_page_from_modal'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user
        });

    },
    partialUsrSkippedGST: (user) => {
        const eventName = 'homepage_partial_user_skipped_ekyc_from_modal'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user
        });

    },
    exploreMarkets: (user) => {
        const eventName = 'explore_market_from_homepage_bar'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user
        });
    },

    activeContract: (user, activeContractName) => {
        const eventName = 'active_contract_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            contact_name: activeContractName
        });
    },

    bannerClick: (user, bannerName) => {
        const eventName = 'banner_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            from_banner: bannerName
        });
    },
    favoriteContract: (user, favoriteContractName) => {
        const eventName = 'favorite_contract_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            contact_name: favoriteContractName
        });
    },
    coalOrigin: (user, origin) => {
        const eventName = 'coal_origin_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            coal_origin_name: origin
        });
    },
    trendingVesselMine: (user, vesselMineName) => {
        const eventName = 'trending_vessel_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            trending_vessel_or_mine_name: vesselMineName,
        });
    },
    benchmark: (user, benchmarkName) => {
        const eventName = 'benchmark_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            benchmark: benchmarkName,
        });
    },
    newsArticle: (user, newsTitle) => {
        const eventName = 'news_article_from_homepage'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {
            user_id: user,
            article_title: newsTitle,
        });
    },

    // New registration
    mobileValid: () => {
        const eventName = 'signup_mobile_valid'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    mobileVerified: () => {
        const eventName = 'signup_mobile_verified'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    emailValid: () => {
        const eventName = 'signup_email_valid'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    emailVerified: () => {
        const eventName = 'signup_email_verified'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    userModeSelected: () => {
        const eventName = 'signup_selected_user_mode'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    userRegistered: () => {
        const eventName = 'signup_success'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    // Add company
    addCompanyPage: (user) => {
        const eventName = 'add_company_page_accessed'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    addCompanyPageSkipped: (user) => {
        const eventName = 'add_company_page_skipped'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    addGST: () => {
        const eventName = 'add_GSTIN'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    GSTVerified: () => {
        const eventName = 'GSTIN_verified'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    selectCity: () => {
        const eventName = 'city_select'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    selectIndustry: () => {
        const eventName = 'industry_select'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    industrySelected: () => {
        const eventName = 'industry_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    selectCoalType: () => {
        const eventName = 'coal_type_select'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    ekycSuccess: () => {
        const eventName = 'add_company_ekyc_success'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    // User logged out
    userLogout: () => {
        const eventName = 'logout'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

        // if (window.Hansel) {
        //     window.Hansel.logEvent(prefix + eventName, 'fbs', {});
        // }
    },

    // user logged in
    userLoggedIn: (user) => {
        const eventName = 'login'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    bidSent: (user, listing_id) => {
        const eventName = 'bid_sent'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
            listing_id: listing_id,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    offerSent: (user, listing_id) => {
        const eventName = 'offer_sent'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
            listing_id: listing_id,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    bidAccepted: (user, listing_id) => {
        const eventName = 'bid_accepted'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
            listing_id: listing_id,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    offerAccepted: (user, listing_id) => {
        const eventName = 'offer_accepted'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
            listing_id: listing_id,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    chatOpened: (user) => {
        const eventName = 'chat_opened'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            type: user.user_type == 1 ? 'Buyer' : "Seller",
            user_role: user.user_type == 1 ? 'buyer' : 'seller',
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    // Add new listing
    addListing: (user_mode, type) => {
        const eventName = 'add_listing'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_mode: user_mode === 1 ? "Buyer" : "Seller",
            type: type == "buyer" ? "Buyer" : "Seller",
            navigate: (user_mode === 1 && type == "seller") ? '/requirements/buyer' : '/requirements/seller',
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    // Domestic

    domesticSelected: () => {
        const eventName = 'domestic_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    importedSelected: () => {
        const eventName = 'imported_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    selectVessel: () => {
        const eventName = 'vessel_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    searchMine: () => {
        const eventName = 'mine_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    miningCompany: () => {
        const eventName = 'mining_company'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    ekycSuccess: () => {
        const eventName = 'add_company_ekyc_success'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    bestBuyersSellers: () => {
        const eventName = 'best_buyers_sellers_found'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    quantity: () => {
        const eventName = 'quantity'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    offerPrice: () => {
        const eventName = 'price_valid'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    paymentTerms: () => {
        const eventName = 'payment_terms_selected'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    liftingDays: () => {
        const eventName = 'lifting_days'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    liftingExpiry: () => {
        const eventName = 'lifting_expiry'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    addListingSuccess: () => {
        const eventName = 'listing_success'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    // Add reference contact
    referenceContactValid: () => {
        const eventName = 'reference_contact_valid'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    referenceContactAdded: () => {
        const eventName = 'reference_contact_added'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    referenceCompanyAdded: () => {
        const eventName = 'reference_contact_company_name_added'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    referenceContactDeleted: () => {
        const eventName = 'reference_contact_deleted'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    listingPostedByWhatsAppLink: () => {
        const eventName = 'listing_posted_by_whats_app_link';
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    // Negative Lists

    companyBlocked: () => {
        const eventName = 'negative_list_company_blocked'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    UserContactValid: () => {
        const eventName = 'negative_list_contact_valid'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },
    selectUser: () => {
        const eventName = 'negative_list_select_user_to_block'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    requestedToReviewRating: () => {
        const eventName = 'request_to_review_rating'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },

    unblockedUser: () => {
        const eventName = 'negative_list_unblocked_user'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});

    },


    //
    viewListing: (listing_id) => {
        const eventName = 'view_listing'
        fcmAnalytics.logEvent(prefix + eventName, {
            listing_id: listing_id
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    profile: (user, noCompany) => {
        const eventName = 'profile'
        fcmAnalytics.logEvent(prefix + eventName, {
            user_id: user.id,
            noCompany: noCompany ? true : false
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    viewQuotation: (quotation_id) => {
        const eventName = 'view_quotation'
        fcmAnalytics.logEvent(prefix + eventName, {
            quotation_id: quotation_id
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    marketPage: (marketplace_id) => {
        const eventName = 'market_page'
        fcmAnalytics.logEvent(prefix + eventName, {
            limit: 99,
            marketplace_id: marketplace_id == "bookmarked" ? '' : marketplace_id,
            mode: 3,
            is_bookmarked: marketplace_id == "bookmarked" ? 1 : ''
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    selectMarket: (keyword) => {
        const eventName = 'select_market'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    setContractFavourite: (keyword) => {
        const eventName = 'market_added_to_favourite'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    removeContractFavourite: (keyword) => {
        const eventName = 'market_removed_from_favourite'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    shareContract: (keyword) => {
        const eventName = 'share_contract_card'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    openMarketOpened: () => {
        const eventName = 'open_market_clicked'
        fcmAnalytics.logEvent(prefix + eventName);

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    contractDetailOpened: (keyword) => {
        const eventName = 'market_contract_details_opened'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    listingFormToSellOpened: (keyword) => {
        const eventName = 'market_contract_Sell_clicked'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    listingFormToBuyOpened: (keyword) => {
        const eventName = 'market_contract_Buy_clicked'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    listingFormOpened: (keyword) => {
        const eventName = 'market_contract_listing_btn_clicked'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    contractDetailChat: (keyword) => {
        const eventName = 'market_contract_details_chat_action_clicked'
        fcmAnalytics.logEvent(prefix + eventName, {
            keyword: keyword
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    myWallet: (range) => {
        const eventName = 'my_wallet'
        fcmAnalytics.logEvent(prefix + eventName, {
            page: 1,
            limit: 10,
            platform_type: 2,
            start_date: range[0].startDate,
            end_date: range[0].endDate
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },

    notifications: () => {
        const eventName = 'notifications'
        fcmAnalytics.logEvent(prefix + eventName, {
            page: 1,
            limit: 15,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    notificationToMarket: () => {
        const eventName = 'accessed_markets_from_notification_page'
        fcmAnalytics.logEvent(prefix + eventName, {
            page: 1,
            limit: 15,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
    notificationToReviewRating: () => {
        const eventName = 'review_rating'
        fcmAnalytics.logEvent(prefix + eventName, {
            page: 1,
            limit: 15,
        });

        HanselLogEvent(prefix + eventName, 'fbs', {});
    },
}

export default eventsTracking;