import React from 'react'
import Emitter from '../../services/emitter'
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux'
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';
import eventsTracking from '../../eventsTracking'
import { cEncrypt } from '../../helpers'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  modalWrapper: {
    backgroundColor: "#eef2f5",
    position: 'fixed',
    bottom: 5,
    right: 5,
    boxShadow: '3px 4px 40px rgb(0 0 0 / 20%)',
    borderRadius: 8,
  },

  btn: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#1976d2'
  },
  btnDefault: {
    color: '#6a6a6a'
  }

}));

export default function UnauthorizedAndPendingGSTModal() {
  const classes = useStyles();
  // const navigate = useNavigate();
  const user = useSelector(state => state.userReducer.user);
  const [unauthorizedDialog, unauthorizedDialogSet] = React.useState({ show: false, data: {} })
  const [noCompanyDialog, noCompanyDialogSet] = React.useState({ show: false, data: {} })
  const [automateChatDialog, automateChatDialogSet] = React.useState({ show: false, data: {} })
  const [isDialogMessage, isDialogMessageSet] = React.useState('')
  const [gotoPlanPage, gotoPlanPageSet] = React.useState(false)
  //   const [isDialogLowAdCreditsMessage, isDialogLowAdCreditsMessageSet] = React.useState('')

  React.useEffect(() => {

    Emitter.on("UNAUTHORIZED_ACTION", (e) => {
      unauthorizedDialogSet({ show: true, data: e })
    });

    Emitter.on("COMPANY_NOT_ADDED", (e) => {
      noCompanyDialogSet({ show: true, data: e })
    });


    // Please Note: If the received offer matches with your buyer listing then the offer would get auto-accepted.
    Emitter.on("AD_CREDIT_QTY_BUY_SELL_NOW", (e) => {
      automateChatDialogSet({ show: true, data: e })
      // isDialogMessageSet(e == 'buyer' ? 'If the received offer matches with your buyer listing then the offer would get auto-accepted.' : 'If the received bid matches with your seller listing then the bid would get auto-accepted.')
      if (e.adCredits >= e.quantity) {
        isDialogMessageSet(e.uType == 'buyer' ? 'Which means, for next 30 minutes, any exactly matching offer will get automatically accepted on your behalf.' :
          'Which means, for next 30 minutes, any exactly matching bid will get automatically accepted on your behalf.')
        gotoPlanPageSet(false)
      } else {
        isDialogMessageSet(`You are just ${e.quantity - e.adCredits} Ad Credits away from creating a hot listing. This increase the chances of the deal completion and results in faster deals.`)
        gotoPlanPageSet(true)
      }
      // isDialogLowAdCreditsMessageSet((e.adCredits < e.quantity) && `You are just ${30} AdCredits away from creating a premium/highlighted/hot listing. This increase the chances of the deal completion and results in faster deals.`)
    });

  }, [])

  const NoCompanyAdded = (props) => (
    <>
      <Typography variant="h6" component="p" gutterBottom><strong>Add Your Company</strong></Typography>
      <Typography variant="body" component="p" gutterBottom lineHeight={2}>
        You are 1-step away from profitable trade, just add <br /> your GST to begin.
      </Typography>
      <Box sx={{ textAlign: 'right' }}>
        <Button variant='text' color='primary' component={Link} to='/add-company-ekyc' onClick={e => { props.onChangeOpen(false); eventsTracking.partialUsrAddGST(user.id) }}>
          ADD COMPANY GST/TAX ID
        </Button> <br />
        <Button variant='text' className={classes.btnDefault} onClick={e => { props.onChangeOpen(false); eventsTracking.partialUsrSkippedGST(user.id) }}>
          No THANKS
        </Button>
      </Box>
    </>
  )

  const NotRegistered = (props) => (
    <>
      <Typography variant="h6" component="p" gutterBottom><strong>Please complete your Signup process</strong></Typography>
      <Typography variant="body" component="p" gutterBottom lineHeight={2}>
        Signup & add your GST to get an unrestricted, <br /> personalized experience and alerts of the coal market.
      </Typography>
      <Box sx={{ textAlign: 'right' }}>
        <Button variant='text' color='primary' component={Link} to={`/sign-up/${cEncrypt(0)}`} onClick={e => { props.onChangeOpen(false); eventsTracking.UsrRegisteredFromHomeModal() }}>
          YES, COMPLETE NOW
        </Button> <br />
        <Button variant='text' className={classes.btnDefault} onClick={e => { props.onChangeOpen(false); eventsTracking.UsrSkipsRegistrationFromHomeModal() }}>
          NO THANKS
        </Button>
      </Box>
    </>
  )

  const AutomateChatContent = (props) => (
    <>
      {/* <Typography variant="h6" component="p" gutterBottom><strong>AutomateChatContent</strong></Typography> */}
      <Typography variant="body" component="p" gutterBottom lineHeight={2}>
        <strong>Hey {user.first_name},</strong><br />
        This will be a HOT <img src="../images/hot-listing.gif" alt="hot" width="18" /> listing. <br />
        {isDialogMessage} <br />
        *you can always expire your listing before 30 min from <strong>My Listings</strong> page.
      </Typography>
      {props.gotoPlanPage == true && <Box><Button variant='contained' disableElevation color='primary' component={Link} to='/pricing-plans' onClick={e => { props.onChangeOpen(false); }}>Recharge Now</Button></Box>}
      <Box sx={{ textAlign: 'right' }}>
        <Button variant='text' className={classes.btnDefault} onClick={e => { props.onChangeOpen(false); }}>
          OKAY
        </Button>
      </Box>
    </>
  )


  return (
    <Dialog
      open={unauthorizedDialog.show || noCompanyDialog.show || automateChatDialog.show}
      TransitionComponent={Transition}
      keepMounted
      // onClose={e => { unauthorizedDialog.show(false) || noCompanyDialog.show(false) }}
      // disableBackdropClick
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        {(noCompanyDialog && noCompanyDialog.show) &&
          <NoCompanyAdded
            onChangeOpen={e => noCompanyDialogSet({ show: e })}
          />
        }
        {(unauthorizedDialog && unauthorizedDialog.show) &&
          <NotRegistered
            onChangeOpen={e => unauthorizedDialogSet({ show: e })}
          />
        }
        {(automateChatDialog && automateChatDialog.show) &&
          <AutomateChatContent
            gotoPlanPage={gotoPlanPage}
            onChangeOpen={e => automateChatDialogSet({ show: e })}
          />
        }
      </DialogContent>
    </Dialog>
  )
}
