import React from 'react';

/**
 *
 * @name Success
 * @alias Success
 * @component
 * @exports AnimatedSuccess
 * @description
 * A static component with animated svg icon to show success of an action to the user
 * @example
 *
 * {..condition starts
 * <AnimatedSuccess />
 * ..condition ends}
 */

export default function AnimatedSuccess() {
    return (
        <div className="success-checkmark">
            <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
            </div>
        </div>
    );
}

