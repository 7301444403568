export default function (props, data) {
    var message = "Hi,\n"
    if (props.reOffer) {
        message += "We are interested to offer as follows\n"
    }
    else if (props.reBid) {
        message += "We are interested to bid as follows\n"
    }
    else {
        message += "We are interested to " + ((props.product_type == 1) ? 'offer' : 'bid') + " as follows\n"
    }

    if (data.price && props.currency == 'INR') {
        message += "Price : " + data.price + " " + props.currency + "/MT (including CESS) + GST\n"
    } else {
        message += "Price : " + data.price + " " + props.currency + "/MT\n"
    }

    message += "Quantity : " + data.quantity + "MT\n"

    if (data.lifting) {
        message += "Lifting Days : " + data.lifting + "\n"
    }
    if (data.paymentMode) {
        message += "Payment : " + data.paymentMode
        if (data.paymentModeId == 4 && data.advance) {
            message += " (" + data.advance + "% Adv.)"
        }
        message += "\n"
    }
    if (data.creditDays >= 0 && (data.paymentModeId == 2 || data.paymentModeId == 3)) {
        message += "Credit Days : " + data.creditDays + "\n"
    }
    if (data.paymentDays && data.paymentModeId == 4) {
        message += "Payment Days : " + data.paymentDays + "\n"
    }
    if (data.deliveryTerm) {
        message += "Delivery Term : " + data.deliveryTerm + "\n"
    }
    if (data.validityTime !== undefined && data.validityTime !== null) {
        message += props.reOffer ?
            "Offer"
            : (props.reBid ? "Bid" : ((props.product_type == 1) ? 'Offer' : 'Bid'))

        message += " Validity : " + data.validityTime + " Minutes \n"
    }

    if (data.comment && data.comment.length > 0) {
        message += "Additional Comments : " + data.comment + "\n\n"
    }

    message += "Let us know if you can accept these terms."

    return message
}