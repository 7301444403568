import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function Heading(props) {
  return (
    <Box sx={{textAlign: 'center', pt: {xs: 4, sm: 5}, pb: {xs: 1, sm: 1.75}}}>
        <Typography variant="h5" component="h2" gutterBottom fontWeight={'bolder'} {...props}>{props.title}</Typography>
        {props.subtitle &&
            <Typography variant="subtitle2" component="p" gutterBottom color={'text.secondary'} {...props}>{props.subtitle}</Typography>
        }
    </Box>
  )
}
