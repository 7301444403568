import { createTheme } from '@mui/material/styles';
const common = {
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                size: 'small',
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
            body: {
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            },
            },
        },
    },
    palette: {
        primary: {
            lighter: "#d4def1",
            light: "#537ac7",
            main: "#2959BA",
            dark: "#1c3e82",
        },
        secondary: {
            light: "#ff4081",
            main: "#f50057",
            dark: "#c51162"
        }
    }
};

export const consoleTheme = createTheme({
    ...common, ...{

    }
})
export const buyerColors = {
    primary: {
        lighter: "#d4def1",
        light: "#339ba5",
        main: "#00838F",
        dark: "#005b64",
    },
}

export const sellerColors = {
    primary: {
        lighter: "#d4def1",
        light: "#537ac7",
        main: "#2959BA",
        dark: "#1c3e82",
    },
}

export const buyerTheme = createTheme({
    ...common, ...{
    palette: {
        primary: buyerColors.primary,
        secondary: common.palette.secondary,
    },
    // toggle: {
    //     thumbOnColor: '#00838F',
    //     trackOnColor: '#00838F'
    // },
    typography: {
        button: {
            textTransform: "capitalize",
        },
    },
    // "palette":{
    //     primary: { main: '#D81B60' },
    //     secondary: { main: '#00695C' }
    // }
}});

export const sellerTheme = createTheme({
    ...common, ...{
    palette: {
        primary: sellerColors.primary,
        secondary: common.palette.secondary,
    },
    // toggle: {
    //     thumbOnColor: '#2959BA',
    //     trackOnColor: '#2959BA'
    // },
    typography: {
        button: {
            textTransform: "capitalize",
        },
    },
    // "palette":{
    //     primary: { main: '#D81B60' },
    //     secondary: { main: '#00695C' }
    // }
}});

export const buttonClasses = (theme) => {
    return {
        buyerButtonContained: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    sellerButtonContained: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    buyerButtonLight: {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: "#FFF",
        },
        '&:active': {
            backgroundColor: theme.palette.primary.light,
            color: "#FFF",
        }
    },
    sellerButtonLight: {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: "#FFF",
        },
        '&:active': {
            backgroundColor: theme.palette.primary.light,
            color: "#FFF",
        }
    },
    buyerButtonOutlined: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark
        },
        '&:active': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark
        }
    },
    sellerButtonOutlined: {
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark
        },
        '&:active': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark
        }
    },
    buyerButtonDefault: {
        color: theme.palette.primary.main,
        // '&:hover': {
        //     backgroundColor: theme.palette.primary.light
        // },
        // '&:active': {
        //     backgroundColor: theme.palette.primary.light
        // }
    },
    sellerButtonDefault: {
        color: theme.palette.primary.main,
        // '&:hover': {
        //     backgroundColor: theme.palette.primary.light
        // },
        // '&:active': {
        //     backgroundColor: theme.palette.primary.light
        // }
    }}
};