import React from "react";
import { IconButton, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

/**
 * ErrorHandler
 *
 * @component
 * @example
 *
 * <ErrorHandler>
 *     <YourComponent />
 * </ErrorHandler>
 */
class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errorOccurred: false, retrying: false, showRetry: props.showRetry == undefined ? true : props.showRetry };
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        // logErrorToMyService(error, info);
        console.error(error.toString())
        console.error(info.componentStack)
    }

    retry = () => {
        this.setState({retrying: true})
        setTimeout(() => {
            this.setState({errorOccurred: false})
            this.setState({retrying: false})
        }, 1500)
    }

    render() {
        return this.state.errorOccurred ? (
            <span style={{display: 'inline-block', textAlign: 'center'}}>
                {this.state.retrying ?
                    <>
                        Reloading component...<br />
                        <CircularProgress color="secondary" size='20px' />
                    </>
                    :
                    <>
                        {this.state.showRetry &&
                        <>
                        Failed! Retry
                        <IconButton color="primary" aria-label="Retry" onClick={this.retry} size="large">
                            <RefreshIcon />
                        </IconButton>
                        </>
                        }
                    </>
                }
            </span>
        ) : (
            this.props.children
        );
    }
}

export default ErrorHandler
