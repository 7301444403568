import { createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { loadState, saveState } from './localStorage'

const initialState = loadState();
const middlewares = [thunk];
const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

store.subscribe(() => {
    saveState(store.getState());
});

export default store;