import React from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';
import makeStyles from '@mui/styles/makeStyles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
    btnPrimary: {
        backgroundColor: "#d4def1",
        color: "#2959BA",
        "&:hover": {
            backgroundColor: "#d4def1"
        }
    },
}));


export function MakeAnotherListingBtn(props) {

    const user_mode = useSelector(state => state.userReducer.user_mode);
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);

    return (
        <Box sx={{ textAlign: 'center', mt: 1.4 }}>
            {isLoggedIn &&
                <Button
                    variant='contained'
                    disableElevation
                    to={user_mode === 1 ? '/requirements/buyer' : '/requirements/seller'}
                    component={Link}
                    target="_blank"
                    rel="noopener"
                    {...props.actionBtnProps}
                >
                    Make another listing
                </Button>
            }
        </Box>
    )
}

export function ViewAllBidOffers(props) {
    const classes = useStyles();
    return (
        <Box sx={{ textAlign: 'center', mt: 1.4 }}>
            <Button
                disableElevation
                size="small"
                className={classes.btnPrimary}
                to='/dashboard/inbox'
                component={Link}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
                onClick={e => {
                    props.onClickEvent();
                }}
                {...props.actionBtnProps}
            >
                {props.buttonName}
            </Button>
        </Box>
    )
}

export function ViewChatLinkBtn(props) {
    return (
        <Button
            disableElevation
            size="small"
            onClick={e => {
                props.onClickEvent();
            }}
            {...props.actionBtnProps}
        >
            View Chat
        </Button>
    )
}

export function SentBtn(props) {
    return (
        <Button
            disableElevation
            style={{ backgroundColor: '#85ce1f' }}
            size="small"
            onClick={e => {
                props.onClickEvent();
            }}
            {...props.actionBtnProps}
        >
            {props.label}
        </Button>
    )
}

export function ListingBidOfferBtn(props) {
    const user = useSelector(state => state.userReducer.user);

    return (
        <LoadingButton
            disableElevation
            loading={props.fetching}
            loadingPosition="end"
            disabled={user.id == props.sessionUserId ? true : false}
            size="small"
            onClick={e => {
                props.onClickEvent();
            }}
            {...props.actionBtnProps}
        >
            {props.label}
        </LoadingButton>
    )
}
