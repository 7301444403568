import React from 'react';
import loadable from "@loadable/component";
// import MatchingListingsDialog from './MatchingListingsDialog';
// import AutoWithdrawRejectDialog from './AutoWithdrawRejectDialog';
import Emitter from '../../services/emitter';
import ChatModal from '../partials/ChatModalV3';
import { useSelector } from 'react-redux'
// import MuiAlert from '@mui/material/Alert';
import { Button, Box, Typography } from "@mui/material";


const MatchingListingsDialog = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-MatchingListingsDialog" */ "./MatchingListingsDialog"
    );
});

const AutoWithdrawRejectDialog = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-AutoWithdrawRejectDialog" */ "./AutoWithdrawRejectDialog"
    );
});

const MutualContactsDialog = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-partials-MutualContactsDialog" */ "../partials/MutualContactsDialog"
    );
});

export default function FirebaseNotification() {
    const [showMatchingListingDialog, showMatchingListingDialogSet] = React.useState(false);
    const [showAutoWithdrawRejectListingDialog, showAutoWithdrawRejectDialogSet] = React.useState(false);
    const [mutualContactModalOpen, mutualContactModalOpenSet] = React.useState(false);
    const [mutualContactData, mutualContactDataSet] = React.useState([]);
    const user = useSelector(state => state.userReducer.user);
    const [data, dataSet] = React.useState(null);
    const [type, typeSet] = React.useState('');
    const [listingId, listingIdSet] = React.useState(null);
    const [productId, productIdSet] = React.useState(null);
    const [quantity, quantitySet] = React.useState(null);
    const [currency, currencySet] = React.useState('');
    const [notification, notificationSet] = React.useState(null);
    // const [listingContractName, listingContractNameSet] = React.useState(null);
    const [visible, visibleSet] = React.useState(false);
    const [autoWithdraw, autoWithdrawSet] = React.useState(null);
    const [message, messageSet] = React.useState({});
    const [chatData, setChatData] = React.useState({});

    React.useEffect(() => {
        Emitter.on("FIREBASE_NOTIFICATION", (e) => {
            if (process.env.REACT_APP_ENV != 'production') {
                //console.log('FIREBASE_NOTIFICATION', e)
            }
            //console.log('firebase data', e.data.data)
            // console.log('firebase notification', e.data.notification)
            if ((e.data && e.data.data && (e.data.data.notification_type == 1 || e.data.data.notification_type == 16))) {
                // if (e.data.data.action == 8 || e.data.data.action == 9) {
                //     console.log(
                //       e.data.data.quotation_id
                //     );
                // } else {
                visibleSet(true);
                messageSet({
                    message: e.data.notification.body,
                    type: "success",
                    data: e.data.data
                });
                // listing_id:
                // chat_company_id
            }

            if (e.data && e.data.data && e.data.data.notification_type == 10) {
                showMatchingListingDialogSet(true);
            }
            if (e.data && e.data.data && e.data.data.notification_type == 17) {
                mutualContactModalOpenSet(true);
                //console.log(e.data.data)
                mutualContactDataSet(e.data.data)
            }
            if (e.data && e.data.data && e.data.data.notification_type == 14) {
                showAutoWithdrawRejectDialogSet(true);
                autoWithdrawSet(e.data.data);
                productIdSet(e.data.data.product_id);
                typeSet(user.active_company == e.data?.data?.buyer_id ? 1 : (user.active_company == e.data?.data?.seller_id ? 2 : ''));
                // typeSet(user.active_company == e.data.data.buyer_id ? 1 : 2);
                quantitySet(e.data.data.chat_quantity);
            }

            dataSet(e.data.data);
            listingIdSet(e.data.data.product_id);
            currencySet(e.data?.posted_listing?.currency);
            // listingContractNameSet(e.data.data.contract_name)
            notificationSet(e.data.notification)
        });

        return function cleanUp() {
            showMatchingListingDialogSet(false);
            showAutoWithdrawRejectDialogSet(false);
            // Emitter.off("FIREBASE_NOTIFICATION");
        };
    }, [])

    return (
        <>
            {(data && data.notification_type == 10) && <MatchingListingsDialog
                data={data}
                notification={notification}
                show={showMatchingListingDialog}
                listingId={listingId}
                currency={currency}
                onClose={e => showMatchingListingDialogSet(false)}
            />
            }
            {(mutualContactData && mutualContactData.notification_type == 17) && <MutualContactsDialog
                data={mutualContactData}
                contactList={JSON.parse(mutualContactData.reference_contract_list)}
                show={mutualContactModalOpen}
                onClose={e => mutualContactModalOpenSet(false)}
            />
            }

            {(autoWithdraw && productId && type != null) && <AutoWithdrawRejectDialog
                notification={notification}
                show={showAutoWithdrawRejectListingDialog}
                product_id={productId}
                quantity={quantity}
                type={type}
                buyerID={autoWithdraw?.buyer_id}
                sellerID={autoWithdraw?.seller_id}
                message={autoWithdraw?.lastBidOfferMessage?.action}
                onClose={e => showAutoWithdrawRejectListingDialog(false)}
            />
            }

            {/* Chat modal */}
            {(visible == true) &&
                <Box sx={{ position: 'fixed', bottom: '20px', left: '20px', background: '#eef2f5', maxWidth: 1 / 4, p: 2, borderRadius: 1.5, boxShadow: '4px 4px 7px #22222261' }}>
                    <Typography>
                        {message.message}
                    </Typography>

                    <Box sx={{ textAlign: 'right' }}>
                        <Button size='small' variant='outlined' disableElevation sx={{ mx: 1 }} onClick={e => { setChatData({ show: false }); visibleSet(false) }}>
                            Close
                        </Button>

                        <Button size='small' variant='contained' disableElevation onClick={e => {
                            setChatData({
                                show: true,
                                listing_id: message.data.listing_id,
                                chat_company_id: user.active_company == message.data.msg_from_id ? message.data.msg_to_id : message.data.msg_from_id,
                            }); visibleSet(false)
                        }}>
                            View
                        </Button>
                    </Box>
                </Box>
            }
            {chatData?.show && (
                <ChatModal
                    key={chatData?.listing_id}
                    data={chatData}
                    onClose={e => setChatData({})}
                />
            )
            }
        </>
    );
}