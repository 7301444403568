import React, { useState } from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    Button
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: '450px'
    },
    '& .MuiDialog-paper': {
        // width: 'calc(100% - 190px)'
        backgroundColor: 'red'
    },
    '& .MuiCircularProgress-svg': {
        color: '#cccc'
    },
    btnPrimary: {
        backgroundColor: "#d4def1",
        color: "#2959BA",
        marginRight: '15px',
        "&:hover": {
            backgroundColor: "#d4def1"
        }
    },
}))

function CurrencyValidationDialog(props) {
    //console.log(props)
    const classes = useStyles();
    const user = useSelector(state => state.userReducer.user);
    const [show, showSet] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
        // if (props.onClose) {
        //     props.onClose()
        // }
    };

    React.useEffect(() => {
        showSet(props.show);
        if (props.show === true) {
            handleClickOpen("currencyValidationDialog")
        }

    }, [props.show])

    return (
        <>
            {show === true &&
                <Dialog
                    open={open === "currencyValidationDialog"}
                    TransitionComponent={Transition}
                    keepMounted
                    classes={{ paper: classes.dialogPaper }}
                    maxWidth='xs'
                    onClose={e => {
                        handleClose(e);
                        props.currencyValidationDialogSet(false);
                    }}
                >

                    <Box>
                        <DialogTitle align="center">
                            <Box sx={{ fontSize: '14px', color: '#6a6a6a', mb: 1 }}>Dear {user.first_name}, </Box>
                            <Box sx={{ fontSize: '14px', color: '#2a2a2a', mb: .75 }}>
                                {props.listing == true ? `You can create listings only for ${props.currency != '+91' ? 'Dollar' : 'Rupees'}-based contracts.` :
                                    ` You can only send the bid/offer under ${props.currency != '+91' ? 'Dollar' : 'Rupees'}-based contracts and not the ${props.currency != '+91' ? 'Rupees' : 'Dollar'}.`
                                }
                            </Box>
                            <IconButton
                                aria-label="close"
                                className="closeBtn"
                                size="small"
                                onClick={e => {
                                    handleClose(e);
                                    props.currencyValidationDialogSet(false);
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </DialogTitle>
                        <Box sx={{ textAlign: 'center', mb: 1.5 }}>
                            <Button
                                onClick={e => {
                                    handleClose(e);
                                    props.currencyValidationDialogSet(false);
                                }}
                                disableElevation
                                size="large"
                                variant="contained"
                                color="primary"
                            >
                                Okay
                            </Button>
                        </Box>
                    </Box>

                </Dialog>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps)(CurrencyValidationDialog);