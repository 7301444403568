import React from 'react'
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function HelpAndSupport(props)
{
    return (
        <Box sx={{maxWidth: 300, mx: 'auto'}}>
            <Box textAlign="center" p={2} style={{ textDecoration: 'underline', color: '#2959BA' }}>
                <Button variant="text" color="primary" component={Link} to={props?.brandstore==true?'/brand-stores/sign-up/MA==':'/contact'}>{props?.brandstore==true?'SIGNUP':'Need Help?'}</Button>
            </Box>
            {
            props?.brandstore!=true&&(
<Box sx={{ textAlign: "center", display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                    <img src="/images/reliable-deal_icon.png" alt="secure" width='20' /><br />
                    Reliable Deals</Box>
                <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                    <img src="/images/Secure.svg" alt="secure" width='20' /><br />
                    100% secure
                </Box>
            </Box>
            )
            }
            
        </Box>
    )
}
