export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const LOGIN_USER = 'LOGIN_USER';
export const CHECK_AND_LOAD_USER = 'CHECK_AND_LOAD_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const NOTIFY = 'NOTIFY';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const USER_SIGNUP = 'USER_SIGNUP';
export const CHANGE_USER_MODE = 'CHANGE_USER_MODE';
export const UPDATE_NOTIFICATIONS_AND_MESSAGES = 'UPDATE_NOTIFICATIONS_AND_MESSAGES';
export const UNREAD_NOTIFICATION_COUNT = 'UNREAD_NOTIFICATION_COUNT';
export const LOADER = 'LOADER';
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const SIGNUP_MODAL = 'SIGNUP_MODAL';
export const LOAD_COMMON_DATA = 'LOAD_COMMON_DATA';
export const USER_NOT_VERIFIED = 'USER_NOT_VERIFIED';

export const STORE_CONSOLE_USER = 'STORE_CONSOLE_USER';
export const CONSOLE_LOGIN_PROCESS = 'CONSOLE_LOGIN_PROCESS';
export const LOGIN_CONSOLE_USER = 'LOGIN_CONSOLE_USER';
export const CHECK_AND_LOAD_CONSOLE_USER = 'CHECK_AND_LOAD_CONSOLE_USER';
export const LOGOUT_CONSOLE_USER = 'LOGOUT_CONSOLE_USER';
export const LANGUAGE = 'LANGUAGE';
export const LANGUAGES = 'LANGUAGES';
export const TOP_PROGRESSBAR = 'TOP_PROGRESSBAR';
export const APPBAR_ACTIONS_AREA = 'APPBAR_ACTIONS_AREA';
export const APPBAR_HEADING = 'APPBAR_HEADING';
export const REDIRECT_TO = 'REDIRECT_TO';
export const UPDATE_PAGE_PERMISSIONS = 'UPDATE_PAGE_PERMISSIONS';
export const LAST_SELECTED_BRANDSTORE = 'LAST_SELECTED_BRANDSTORE';
