import React from 'react'

export default function IframeWindow({ src })
{
    return (
        <iframe
            title="location"
            src={src}
            scrolling="no"
            style={{ height: '460px', width: '100%', border: 0}}
        ></iframe>
    )
}
