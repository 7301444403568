// import reportWebVitals from './reportWebVitals';

import "core-js/stable";
import "core-js/es";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LoadableVisibility from "react-loadable-visibility/loadable-components";
import CoalShastra from "./components/CoalShastra";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
require("./polyfills");
require("intersection-observer");
require("./bootstrap");
const CoalShastraConsole = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/components-CoalShastraConsole" */ "./components/CoalShastraConsole"), {fallback: <div>Loading...</div>});

if(process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging')
{
    Sentry.init({
        dsn: "https://9b8740b48b7f48cd8bb360e7a228d3f0@o532776.ingest.sentry.io/5652083",
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("/firebase-messaging-sw.js")
//         .then(function(registration) {
//             console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//         console.log("Service worker registration failed, error:", err);
//     });
// }

if(document.getElementById("root"))
{
    if (window.location.pathname.includes("console")) {
        serviceWorker.unregister();
        ReactDOM.render(
            <Provider store={store}>
                <React.StrictMode>
                    <CoalShastraConsole />
                </React.StrictMode>
            </Provider>,
            document.getElementById("root")
        );
    } else {
        serviceWorker.register();
        ReactDOM.render(
            <Provider store={store}>
                <React.StrictMode>
                    <CoalShastra />
                </React.StrictMode>
            </Provider>,
            document.getElementById("root")
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
