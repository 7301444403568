import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'


export default function AuthRoute({ children, ...rest }) {
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn)
    const location = useLocation()

    return isLoggedIn ? children : <Navigate
            to={{
                pathname: "/login",
                state: { intended: location }
            }}
        />
}
