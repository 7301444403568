import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChatBidAndOfferForm from './ChatBidAndOfferForm'
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import genOfferAndBidMessage from './genOfferAndBidMessage'
// import { useSelector } from 'react-redux'
// import PopUpDialog from './PopUpDialog';

export default function OfferAndBidMessage(props) {
    // const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const [showPopUpDialog, showPopUpDialogSet] = React.useState(false);
    const [paymentModeId, paymentModeIdSet] = React.useState(props?.paymentTermId);
    // const [data, dataSet] = React.useState();

    const prepareAndSubmitMessage = (data) => {
        const message = genOfferAndBidMessage(props, data)
        props.onSubmit({ message: message, data: data })
    }

    return <Box p={2} className="bg-white">
        <Typography component='h5'>
            {props.reOffer ?
                "Send Your Offer"
                : (props.reBid ? "Send Your Bid" : <>Send Your {(props.product_type == 1) ? 'Offer' : 'Bid'}</>)
            }
        </Typography>
        <br />
        <ChatBidAndOfferForm {...props}
            sendingMessageSet={e => props.sendingMessageSet(e)} sendingMessage={props.sendingMessage} userReferenceListSet={e => props.userReferenceListSet(e)} userReferenceList={props.userReferenceList} specialRequest={props.specialRequest} brandStore={props.brandStore}
            productCompanyId={props.productCompanyId} product={props.product} price={props.price} paymentTermId={props.paymentTermId} ccPercentage={props.ccPercentage} paymentDays={props.paymentDays} liftingDays={props.liftingDays} quantity={props.quantity} currency={props.currency} minimumOrderQuantity={props.minimumOrderQuantity} quantityTickSize={props.quantityTickSize} quantityRangeMax={props.quantityRangeMax} quantityRangeMin={props.quantityRangeMin} priceTickSize={props.priceTickSize} creditDays={props.creditDays} showPopUpDialogSet={showPopUpDialogSet} paymentModeIdSet={paymentModeIdSet}
            // onSubmit={data => {
            //     if (globalSetting?.settings_enable_auto_quote == 1 && props.product_type == 1 && !props.reBid && (paymentModeId == 1 || paymentModeId == 4)) {
            //         showPopUpDialogSet(true);
            //         dataSet(data)
            //     }
            //     else if (globalSetting?.settings_enable_auto_award == 1 && props.product_type == 2 && !props.reOffer && paymentModeId == 3) {
            //         showPopUpDialogSet(true);
            //         dataSet(data)
            //     }
            //     else {
            //         ReactGA.event({
            //             category: 'User', action: 'User sent ' + (
            //                 props.reOffer ? "Re-Offer" : (props.reBid ? "Re-Bid" : (props.product_type == 1) ? 'Offer' : 'Bid')
            //             )
            //         });
            //         prepareAndSubmitMessage(data)
            //     }

            // }}
            onSubmit={data => {
                ReactGA.event({
                    category: 'User', action: 'User sent ' + (
                        props.reOffer ? "Re-Offer" : (props.reBid ? "Re-Bid" : (props.product_type == 1) ? 'Offer' : 'Bid')
                    )
                });
                prepareAndSubmitMessage(data)
            }}

        />

        {/* {showPopUpDialog &&
            <PopUpDialog
                show={showPopUpDialog}
                showPopUpDialogSet={showPopUpDialogSet}
                text={(props.product_type == 1) ? 'Offer' : 'Bid'}
                dialogText={`${(props.product_type == 1 && !props.reBid && (paymentModeId == 1 || paymentModeId == 4)) ? "Please Note: If your Offer is accepted, a quote will automatically be sent to the buyer for approval." : (props.product_type == 2 && !props.reOffer && paymentModeId == 3) ? "Please Note: If your bid gets accepted and your company reference is acceptable to the seller, they shall send the quote. The received Quote would be Awarded automatically." : ''}`}
                loading={props.sendingMessage}
                onSubmitAction={e => {
                    ReactGA.event({
                        category: 'User', action: 'User sent ' + (
                            props.reOffer ? "Re-Offer" : (props.reBid ? "Re-Bid" : (props.product_type == 1) ? 'Offer' : 'Bid')
                        )
                    });
                    prepareAndSubmitMessage(data)
                }}
            />
        } */}
    </Box>

}
