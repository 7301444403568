import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser, checkAndLoadUser } from "../../redux/actions/userActions";
import { validatorRegEx } from "../../helpers";
// import CircularProgress from "@mui/material/CircularProgress";
import Loader from "./Loader";
import InfoIcon from '@mui/icons-material/Info';
// import SocialButton from './SocialButton'
import {
    Container,
    // Avatar,
    Grid,
    // Paper,
    Typography,
    Box,
    // Divider,
    InputLabel
} from "@mui/material";
import "../../sass/modal.scss";
import { Link } from "react-router-dom";
import wave from "../../images/waves2.png";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import SocialLoginButtons from "../partials/SocialLoginButtons";
// import LanguageTranslate from '../partials/UnderScoreLanguage'
import asyncComponent from "./asyncComponent";
import ThemeButton from "./ThemeButton";
import ErrorHandler from "./ErrorHandler";
import HelpAndSupport from "./HelpAndSupport";
const Snackbar = asyncComponent(() => { return import(/* webpackChunkName: "js/chunks/partials-Snackbar" */ './Snackbar'); }, <></>);
const LanguageTranslate = asyncComponent(() => { return import(/* webpackChunkName: "js/chunks/partials-LanguageTranslate" */ './UnderScoreLanguage'); }, <></>);

/**
 *
 * @name LoginForm
 * @alias LoginForm
 * @component
 * @exports LoginForm
 *
 * @description
 * Login Form and related Actions
 */

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            modal: false,
            open: false,
            type: 'password'
        };
        this.toggle = this.toggle.bind(this);
        this.showHidePasswordInput = this.showHidePasswordInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    showHidePasswordInput(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot !== null) {
            this.setState({ show: snapshot.show });
        }
    }

    getSnapshotBeforeUpdate(nextProps, prevState) {
        if (nextProps.show !== prevState.show) {
            return { show: nextProps.show };
        } else {
            return null;
        }
    }

    toggle() {
        this.setState(prevState => ({
            show: !prevState.show
        }));
        if (!this.state.show) {
            this.props.closeLoginModal(true);
        }
    }
    // handleInputChange(e) {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     });

    // }

    handleInputChange(e) {
        if (e.target.name === 'email') {
            this.setState({
                [e.target.name]: e.target.value.toString().trim(' ').toLowerCase()
            });
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    // Post login form
    submit = e => {
        e.preventDefault();
        const firebase_web_token = localStorage.getItem("firebase_web_token");
        this.props.loginUser({
            email: this.state.email,
            password: this.state.password,
            firebase_web_token: firebase_web_token
        });
        // this.setState({
        //   email: "",
        //   password: ""
        // });
    };

    render() {
        if(this.props?.brandStore==true && this.props.isLoggedIn){
            return <></>
        }else{
        if (this.props.userNotVerified && this.props.userToBeVerified && this.props.userToBeVerified.user_status === 1) {
            this.props.checkAndLoadUser();
            return <Navigate to={{ pathname: "/verify-otp", search: "?notverified=1", state: { tempUser: this.props.userToBeVerified } }} />
        }
        // Navigate user to add company form.
        if (this.props.isLoggedIn && this.props.noCompany) {
            if (this.props.location && this.props.location.state && this.props.location.state.intended && this.props.location.state.intended.pathname) {
                this.props.checkAndLoadUser();
                return <Navigate to={{ pathname: "/add-company-eKyc", search: this.props.location.state.intended.search, state: {} }} />;
            }
            this.props.checkAndLoadUser();
            return <Navigate to="/add-company-eKyc" />;
        }

        // Navigate user eKyc form.
        if (this.props.isLoggedIn && !this.props.noCompany && this.props.user.ekyc_status === 0) {
            this.props.checkAndLoadUser();
            return <Navigate to="/eKyc" />;
        }


        // Navigate user to select market if no marketplace is selected
        if (this.props.isLoggedIn && !this.props.noCompany && (this.props.user.selected_marketplace == null || this.props.user.selected_marketplace.length === 0)) {
            this.props.checkAndLoadUser();
            return <Navigate to="/markets/select-markets" />;
        }

        // Navigate user to virtual office if review rating.
        if (this.props.isLoggedIn && !this.props.noCompany && this.props.location && this.props.location.state && this.props.location.state.intended) {
            this.props.checkAndLoadUser();
            return <Navigate to={{ pathname: "/dashboard/my-listings", search: this.props.location.state.intended.search, state: {} }} />;
        }

        // Navigate user to virtual office if company is added.
        if (this.props.isLoggedIn && !this.props.noCompany) {
            if (this.props.location && this.props.location.state && this.props.location.state.intended && this.props.location.state.intended.pathname) {
                this.props.checkAndLoadUser();
                return <Navigate to={this.props.location.state.intended} />;
            }
            this.props.checkAndLoadUser();
            return <Navigate to="/" />;
        }
    }


        const { loginMessage } = this.props;

        return (
            <Box sx={{
                    position: 'relative',
                    // fontSize: "3rem",
                    paddingTop:4,
                    paddingBottom: "118px",
                    backgroundColor: '#fff',
                    backgroundImage: `url(${wave})`,
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: {sm: '100% 175px', xs: 'inherit'},

            }} py={3}>
                <Container component="main" maxWidth="xs">
                    {/* <CssBaseline /> */}
                    {/* <Avatar style={{ maxWidth: 60, margin: "auto" }}>
                        <LockOpenOutlinedIcon />
                    </Avatar> */}
                    <Typography
                        component="h1"
                        variant="h5"
                        className="mb-3"
                        align="center"
                        fontWeight='bolder'
                    >
                        <LanguageTranslate s='Login' />
                    </Typography>

                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#F1F3F8', color: '#04368a', p: 1, mt: 2, mx: 'auto', borderRadius: '4px', fontSize: '14px', border: '1px solid #003189'}}>
                        <Box sx={{mr: 2}}><InfoIcon/></Box>
                        <Box component="span">Please enter your registered E-mail and password to login</Box>
                    </Box>

                    {this.props.loginLoader && <Loader />}
                    {loginMessage && (
                        <ErrorHandler>
                            <Snackbar message={loginMessage} open={true} />
                        </ErrorHandler>
                    )}
                    <br />

                    {/* <SocialLoginButtons /> */}

                    <ValidatorForm onSubmit={this.submit} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel>Enter Email ID<sup style={{ color: "red" }}>*</sup></InputLabel>
                                <TextValidator
                                    variant="outlined"
                                    required
                                    size="small"
                                    fullWidth
                                    autoComplete="email"
                                    autoFocus
                                    // label={<LanguageTranslate s='Email Address' />}
                                    name="email"
                                    onChange={this.handleInputChange}
                                    value={this.state.email}
                                    validators={[
                                        "required",
                                        "isEmail",
                                        `matchRegexp:${validatorRegEx("email")}`
                                    ]}
                                    errorMessages={[
                                        "Email is required!",
                                        "",
                                        "Special characters excluding underscore (_), dot (.) are not allowed and only lowercase letters accepted."
                                    ]}
                                />
                                {/* {console.log(this.state.email)} */}
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Enter Your Password<sup style={{ color: "red" }}>*</sup></InputLabel>
                                <TextValidator
                                    variant="outlined"
                                    required
                                    size="small"
                                    fullWidth
                                    id="password"
                                    name="password"
                                    // label={<LanguageTranslate s='Password' />}
                                    // type="password"
                                    type={this.state.type}
                                    autoComplete="current-password"
                                    onChange={this.handleInputChange}
                                    value={this.state.password}
                                    validators={[
                                        "required",
                                        "isString",
                                        "minStringLength:8"
                                    ]}
                                    errorMessages={[
                                        "Please enter password!",
                                        "Field is not valid",
                                        "Minimum password length must be 8 characters long."
                                    ]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" className="fsSmall">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.showHidePasswordInput}
                                                    edge="end"
                                                >
                                                    {this.state.type === 'password' ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{textAlign: 'right', mt: 1.25}}>
                            {this.props?.brandStore==true?(
 <Link to={'/brand-stores/sign-up/MA=='}><LanguageTranslate s='Sign Up' />{" "} </Link>
                            ):(
                                <Link to="/forgot-password" style={{textDecoration: 'underline'}}>
                                <LanguageTranslate s='Forgot password?' />{" "}
                            </Link>
                            )}
                           
                        </Box>
                        <Box mt={2}>
                            <Grid item xs={12}>
                                <ThemeButton
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    type="submit"
                                    id="submit"
                                    size="large"
                                    onClick={(e) => sessionStorage.clear(e)}
                                >
                                    <LanguageTranslate s='LOGIN' />
                                </ThemeButton>
                            </Grid>
                        </Box>
                    </ValidatorForm>

                    <HelpAndSupport brandstore={this.props?.brandStore} />
                    {/* <Box my={2}>
                        <Divider variant="middle" />
                    </Box> */}
                    {/* <Grid container>
                        <Grid item sm={6}>
                            Don't have an account?{" "}
                            <Link to="/sign-up"><LanguageTranslate s='Register' /></Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to="/forgot-password" style={{textDecoration: 'underline'}}>
                                <LanguageTranslate s='Forgot password?' />{" "}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Container>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        loggingIn: state.userReducer.loggingIn,
        isLoggedIn: state.userReducer.isLoggedIn,
        loginStatus: state.userReducer.loginStatus,
        loginMessage: state.userReducer.loginMessage,
        noCompany: state.userReducer.noCompany,
        loginLoader: state.userReducer.loginLoader,
        userNotVerified: state.userReducer.userNotVerified,
        userToBeVerified: state.userReducer.userToBeVerified,
    };
};

export default connect(mapStateToProps, { loginUser, checkAndLoadUser })(LoginForm);