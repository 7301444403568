import firebase from 'firebase/app';
import 'firebase/messaging'
import 'firebase/analytics'
import Emitter from './services/emitter';


const devVapidKey = "BOZzQUDa-D10a96kHjN-hRn28ibMRfFk-6V9odNHPYLnL1-FMUQ9sQkv4ejZGe3sKqxZzY2ZlXowF0uw4E8vkck";
const prodVapidKey = "BCkB5X047wBPS5kQmzrZ_HW0myIsVSCRtcjPurzicua1FZiVFVsVP8WLSonbnPr1NJb3g2hamyrPiBMPfy3MSrA";
const firebaseDevConfig = {
    apiKey: "AIzaSyARE1ytWndXTOdPCFZDMjjKVdhnJWq31Hg",
    authDomain: "stagingcoalshastra.firebaseapp.com",
    projectId: "stagingcoalshastra",
    storageBucket: "stagingcoalshastra.appspot.com",
    messagingSenderId: "542652279052",
    appId: "1:542652279052:web:b145e49234d1b72bd40129",
    measurementId: "G-Z2NBPW3HKH"

    // apiKey: "AIzaSyCS3Ka7XKgMvRhJfOmWnDWaUcYeefyWjM8",
    // authDomain: "coalshastraapp.firebaseapp.com",
    // databaseURL: "https://coalshastraapp.firebaseio.com",
    // projectId: "coalshastraapp",
    // storageBucket: "coalshastraapp.appspot.com",
    // messagingSenderId: "872030353324",
    // appId: "1:872030353324:web:1b5aa331be97e7d35f6c09",
    // measurementId: "G-HBFDNLHQPT"
};
const firebaseProdConfig = {
    apiKey: "AIzaSyCS3Ka7XKgMvRhJfOmWnDWaUcYeefyWjM8",
    authDomain: "coalshastraapp.firebaseapp.com",
    databaseURL: "https://coalshastraapp.firebaseio.com",
    projectId: "coalshastraapp",
    storageBucket: "coalshastraapp.appspot.com",
    messagingSenderId: "872030353324",
    appId: "1:872030353324:web:b7ab252e257671ac",
    measurementId: "G-BG5KKJ0DKB"
};


const isDev = (
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("uat")
);


let initializedFirebaseApp = null;
let messaging = null;
let fcmAnalytics = null;

if(!window.location.pathname.includes("console"))
{
    initializedFirebaseApp = firebase.initializeApp(isDev ? firebaseDevConfig : firebaseProdConfig);
    if(firebase.messaging.isSupported())
    {
        messaging = initializedFirebaseApp.messaging()
    }
    else {
        console.log("Firebase messaging is not supported in this browser");
    }

    fcmAnalytics = initializedFirebaseApp.analytics();
}


const initFirebase = () => {
    // messaging.requestPermissions()
    //     .then(async function() {
    //         const token = await messaging.getToken();
    //         localStorage.setItem('firebase_web_token', token)
    //     })
    //     .catch(function(err) {
    //         console.log("Unable to get permission to notify.", err);
    //     });

    // Project Settings => Cloud Messaging => Web Push certificates

    if(!messaging)
    {
        return;
    }

    messaging.getToken({ vapidKey: isDev ? devVapidKey : prodVapidKey }).then((currentToken) => {
        if (currentToken) {
            // console.log('firebase web token - ', currentToken)
            localStorage.setItem('firebase_web_token', currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });



    navigator.serviceWorker.addEventListener("message", (message) => {
        if(isDev)
        {
            console.log("FCM - From worker - ")
            console.log(message)
        }
        if(fcmAnalytics)
        {
            fcmAnalytics.logEvent('notification_received', {notification_title: message.data.notification.title});
        }
        // console.log('message ', message)
        Emitter.emit('FIREBASE_NOTIFICATION', message);
    });

    // Notification.onclick = function(event) {
    //     console.log('notification onclick', event)
    // };

    // Notification.onclick = function(event) {
    //     console.log('notification click', event)
    //  };



    // messaging.onMessage((payload) => {
    //     console.log('Message received. ', payload);

    //     // ...
    //   });
}

export { initFirebase, messaging, initializedFirebaseApp, fcmAnalytics };