import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    Paper,
    IconButton
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import AnimatedSuccess from "../partials/AnimatedSuccess";
import Slide from "@mui/material/Slide";
import { User } from "../../api/resources/User";
import Loader from '../partials/Loader'
import ThemeButton from "../partials/ThemeButton";
import loadable from "@loadable/component";
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

const RateDeal = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-virtualOffice-partials-RateDealModal" */ "../virtualOffice/partials/RateDealModal"
    );
});

const useStyles = makeStyles(theme => ({
    ratingDialogSize: {
        '& .stepperPadding .MuiStepper-root': {
            minWidth: '450px',
            [theme.breakpoints.down('md')]: {
                minWidth: '312px',
            }
        }
    },
    btnDanger: {
        backgroundColor: '#e32b2b',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#c91f1f',
        }
    },
    paperSpacing: {
        backgroundColor: '#D4DEF1',
        color: '#2959BA',
        padding: theme.spacing(2, 2),
        display: 'grid',
        gridTemplateColumns: '.1fr 1fr',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2.5, 0),
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PendingRating(props) {
    const classes = useStyles();
    const ratingData = props?.pendingRatingData[0]?.award;
    const user_mode = useSelector(state => state.userReducer.user_mode);
    const [open, setOpen] = React.useState(false);
    const [fetchingRatingQuestions, setFetchingRatingQuestions] = React.useState(true);
    const [selectedCompany, setSelectedCompany] = React.useState(false);
    const [ratingErrorMsg, setRatingErrorMsg] = React.useState(false);
    const [awardId, setAwardId] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    const [userName, setUserName] = React.useState("");
    const [product, setProduct] = React.useState({});
    const [ratingQuestions, setRatingQuestions] = React.useState([]);
    const [ratingType, ratingTypeSet] = React.useState('');
    const [ratingSuccess, setRatingSuccess] = React.useState(false);
    const [remarks, setRemarks] = React.useState("");
    const [showBlockDialog, setShowBlockDialog] = React.useState(false);
    const [blockLoader, setBlockLoader] = React.useState(false);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [starRatingValues, setStarRatingValues] = useState([]);
    const setStarValues = (key, value) => {
        let tempStarRatingValues = starRatingValues;
        tempStarRatingValues[key] = value;
        setStarRatingValues(tempStarRatingValues);
    };

    const buyerRating = listing_id => {
        setFetchingRatingQuestions(true);
        User.fetchRatingQue({ type: 1, listing_id: listing_id })
            .then(response => {
                setRatingQuestions(response.data.data.rating_questions);
                setAwardId(response.data.data.award_id);
                setCompanyName(response.data.data.company_name);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setFetchingRatingQuestions(false);
            });
    };

    const sellerRating = listing_id => {
        setFetchingRatingQuestions(true);
        User.fetchRatingQue({ type: 2, listing_id: listing_id })
            .then(response => {
                setRatingQuestions(response.data.data.rating_questions);
                setAwardId(response.data.data.award_id);
                setCompanyName(response.data.data.company_name);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setFetchingRatingQuestions(false);
            });
    };

    //   Post rating
    const submitRating = (e, type, award_id) => {
        setRatingSuccess(false);
        setBlockLoader(true);
        const errorMsg = starRatingValues.filter(e => {
            return e.rating_value != 0;
        }).length > 2;

        if (errorMsg) {
            setRatingErrorMsg(false);
            User.postRating({
                session_company_id: selectedCompany,
                award_id: awardId,
                type: type,
                questions_rating_data: JSON.stringify(starRatingValues),
                remarks: remarks
            })
                .then(response => {
                    if (response.data.status == "success") {
                        setRatingSuccess(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {

                });
        } else {
            setRatingErrorMsg(true);
        }
    };

    useEffect(() => {
        if (ratingData) {
            setProduct(ratingData);
            setSelectedCompany(ratingData.company_id);
            setUserName(props?.pendingRatingData[0]?.company?.user?.fullName);
            if (props.pendingRatingData[0].type == 2) {
                buyerRating(ratingData.product_id);
                ratingTypeSet('buyerRateModal');
                handleClickOpen("ratingModal");
            }
            if (props.pendingRatingData[0].type == 1) {
                sellerRating(ratingData.product_id);
                ratingTypeSet('sellerRateModal');
                handleClickOpen("ratingModal");
            }
        }
    }, [ratingData, props.pendingRatingData])

    return (
        <div>
            <Dialog
                open={open === "ratingModal"}
                keepMounted
                maxWidth="xs"
                disableEscapeKeyDown={true}
                TransitionComponent={Transition}
                className={classes.ratingDialogSize}
            // onClose={e => {
            //     handleClose(e);
            // }}
            >
                {ratingSuccess && (
                    <>
                        <DialogTitle
                            align="center"
                            className={`${"text-white"}`}
                        >
                            <AnimatedSuccess />
                        </DialogTitle>
                        <DialogContent>
                            <Box textAlign="center" mb={1}>
                                <Typography variant="h5">Great!!</Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle1">
                                    {ratingType == "sellerRateModal" ?
                                        "You have successfully rated this Buyer."
                                        :
                                        " You have successfully rated this Seller."
                                    }
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <ThemeButton
                                    style={{
                                        background: "#4CAF50",
                                        color: "#fff"
                                    }}
                                    type="button"
                                    onClick={e => {
                                        handleClose(e);
                                        setRatingSuccess(false);
                                    }}
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                >
                                    Done
                                </ThemeButton>
                            </Box>
                        </DialogContent>
                    </>
                )}
                {!ratingSuccess &&
                    <RateDeal
                        remarks={remarks}
                        pendingRating={true}
                        product={product}
                        companyName={companyName}
                        userName={userName}
                        ratingErrorMsg={ratingErrorMsg}
                        setRatingErrorMsg={setRatingErrorMsg}
                        //setShowBlockDialog={setShowBlockDialog}
                        ratingQuestions={ratingQuestions}
                        rateSuccess={ratingSuccess}
                        ratingType={ratingType}
                        userMode={user_mode}
                        onStarValuesChange={(key, e) => { setStarValues(key, e) }}
                        onChangeRemarks={remarks => { setRemarks(remarks) }}
                        onClose={e => handleClose(e)}
                        onCloseModal={e => handleClickOpen("closeDealModal")}
                        onSubmit={e => submitRating(e, ratingType == "sellerRateModal" ? 1 : 2, selectedCompany, awardId)}
                    />
                }
                {fetchingRatingQuestions &&
                    <Loader />
                }
            </Dialog>
        </div>
    );
}
