import React from 'react'
import { Container } from '@mui/material'
import Box from "@mui/material/Box"
import { Product } from '../api/resources/Product'
import { useNavigate } from 'react-router-dom'
import { User } from '../api/resources/User'

const AdSpace = ({ adSpace, matchesViewport, ...any }) => {
    //console.log(matchesViewport)

    const navigate = useNavigate();

    const getListingData = (listingId) => {
        Product.viewProductDetails({
            listing_id: listingId
        })
            .then(response => {
                if (response.data.status == "success") {
                    navigate(`/markets?market_code=${response.data.data && response.data.data?.market?.market_code}&contract_code=${response.data.data?.contract?.vessel_code}`)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onClick = async (e) => {
        e.preventDefault()
        try {
            const res = await User.saveRouteCount({
                route_name: 'save-route-count',
                model_id: adSpace.id,
                model_type: 4
            })
        }
        catch (error) {
            console.log(error)
        }
        if (adSpace?.adspaceable_id == null || adSpace?.adspaceable_type == null) {
            window.location.href = adSpace.url
        }
        else {
            getListingData(adSpace?.adspaceable_id)
        }
    }

    if (adSpace && adSpace.id)
        return <div style={{ display: 'block' }}>
            <Container>
                <div style={{ textAlign: 'center' }}>
                    <Box
                        // onClick={e => getListingData(adSpace?.adspaceable_id)}
                        onClick={onClick}
                    >
                        <div style={{ display: 'inline-block' }}>
                            <span style={{ position: 'absolute', fontSize: 12, padding: '2px 4px', backgroundColor: '#eef2f585' }}>Sponsored</span>
                            {matchesViewport == true ?
                                <img src={adSpace?.image_src} style={{width: '100%'}} />
                                :
                                <img src={adSpace?.image_src} style={{width: '100%'}}/>
                            }
                        </div>
                    </Box>
                </div>
            </Container>
        </div>

    return <></>
}

export default AdSpace