import React, { useState } from 'react';
// import { Product } from '../../../api/resources/Product'
import useMediaQuery from "@mui/material/useMediaQuery";
import
    {
        Box,
        Dialog,
        DialogTitle,
        DialogContent,
        IconButton,
        Slide,
        CircularProgress
    } from "@mui/material";
import Fade from '@mui/material/Fade';
import MatchedListingItems from '../../firebase/MatchedListingItems'
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Close';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, connect } from "react-redux";
// import { ViewAllBidOffers } from './../../firebase/ListingActionButtons'
import { changeUserMode } from "../../../redux/actions/userActions";
import ChatModal from "../ChatModalV3";


const Transition = React.forwardRef(function Transition(props, ref)
{
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    bottomBorder: {
        '& > div:not(:last-child)': {
            borderBottom: '1px solid #ddd'
        }
    },
    dialogPaper: {
        maxWidth: '745px'
    },
    '& .MuiDialog-paper': {
        // width: 'calc(100% - 190px)'
        backgroundColor: 'red'
    },
    '& .MuiCircularProgress-svg': {
        color: '#cccc'
    }
}))

function MatchingListingsDialog(props)
{
    const classes = useStyles();
    // const user_mode = useSelector(state => state.userReducer.user_mode);
    const [open, setOpen] = React.useState(false);
    const [show, showSet] = React.useState(false);
    const matchesViewport = useMediaQuery("(max-width:600px)");
    const [loadingListing, loadingListingSet] = React.useState(false);
    const [listingData, listingDataSet] = React.useState([]);
    const [listing, listingSet] = React.useState(null);
    const [postingListingData, postingListingDataSet] = React.useState({});
    const [postedProductType, postedProductTypeSet] = React.useState(null);
    const [chatData, setChatData] = useState({});


    const handleClickOpen = modalName =>
    {
        setOpen(modalName);
    };

    const handleClose = () =>
    {
        setOpen(false);
        if (props.onClose) {
            props.onClose()
        }
    };


    // console.log('All data', props)
    React.useEffect(() =>
    {
        showSet(props.show);
        // if (props.listingId) {
        //     fetchMatchingListingData({ product_id: props.listingId });
        // }
        listingDataSet(props.listings)
        postingListingDataSet(props.postingListingData)
        listingSet(props.product)
        postedProductTypeSet(props.product.product_type == 1 ? 2 : 1)

        // console.table(props.listings, props.product )
        // console.log(props.listings)

        if (props.show === true) {
            handleClickOpen("topSimilarContracts")
        }

        // console.log('top bid offer')
        // console.log('props', props)
        // console.log(props.listingId)
        // console.log(props.product.id)


        // return () => {
        //     showSet(false)
        //     listingDataSet(null)
        //     postingListingDataSet(null)
        //     postedProductTypeSet(null)
        // }

    }, [props.show, props.listingId, props.listings, props.product, props.product, props.product.product_type, props.postingListingData])

    return (
        <>
            {show === true &&
                <Dialog
                    open={open === "topSimilarContracts"}
                    TransitionComponent={Transition}
                    keepMounted
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth
                    maxWidth='md'
                    // style={{width: 'calc(100% - 190px)'}}
                    onClose={e =>
                    {
                        handleClose(e);
                    }}
                >
                    <IconButton
                        aria-label="close"
                        className="closeBtn"
                        size="small"
                        onClick={e =>
                        {
                            handleClose(e)
                        }}
                    >
                        <CancelIcon />
                    </IconButton>

                    <Box>
                        <DialogTitle align="center">
                            {/* <Box sx={{ mt: 1.25, mb: 0 }}><CheckCircleIcon style={{ fontSize: '32px', fill: '#85ce1f' }} /></Box> */}
                            {/* <Box sx={{ fontSize: '26px', my: 1.5 }}>Top {listingData && listingData.length} similar listings you may like</Box> */}
                            <Box sx={{ fontSize: '20px', mt: 1.5, fontWeight: 'normal' }}>Send the <strong>SAME</strong> {listing.product_type == 2 ? "bid" : "offer"} to the next Top {listingData && listingData.length} {listing.product_type == 2 ? "sellers" : "buyers"}:</Box>
                            {/* {props.data && <Box sx={{ fontSize: '14px', color: '#6a6a6a', mb: 1 }}>Your listing in <span style={{ color: '#0645ad' }} >{props.data.contract_name}</span> is posted successfully.</Box>} */}
                            {/* <Box sx={{ fontSize: '14px', color: '#2a2a2a', mb: .75 }}>Best {postedProductType == 1 ? 'sellers' : 'buyers'} for you matching to the above contract:</Box> */}
                        </DialogTitle>
                        <DialogContent>
                            {(listingData && listingData.length > 0) &&
                                <div>
                                    <Box component='ul' className={classes.bottomBorder} sx={{ p: 0 }}>
                                        {(listingData && listingData.length > 0) && listingData.map((listing, index) => (
                                            <Fade timeout={(500 + (index * 200))} in={true} key={index}>
                                                <div>
                                                    <MatchedListingItems
                                                        postedProductType={postedProductType}
                                                        listing={listing}
                                                        isTopListings={props.isTopListings}
                                                        matchesViewport={matchesViewport}
                                                        reloadListings={e => props.onReload()}
                                                        key={index}
                                                        setChatData={setChatData}
                                                        chatData={chatData}
                                                        currency={props.currency}
                                                        postingListingData={postingListingData}
                                                        loadingListing={loadingListing}
                                                    />
                                                </div>
                                            </Fade>
                                        ))}
                                    </Box>
                                    {/* postedProductType = 1 BL, 2 SL */}
                                    {/* {<Box><strong>Please note</strong>: To view all the sent {postedProductType != 1 ? "Bid" : "Offer"} on your behalf click the button below. <br /> */}
                                        {/* {postedProductType == 1 && 'If your Offer is accepted, a quote will automatically be sent to the buyer for approval.'} */}
                                    {/* </Box>} */}
                                    {/* {props.data && props.data.matching_case == 2 && <Box><strong>Please note</strong>: To view all the sent bids/offers on your behalf click the button below.</Box> }
                                    {props.data && props.data.matching_case == 1 && <Box><strong>Please note</strong>: Once you click the {postedProductType == 1 ? "Bid" : "Offer"}, we will auto-send the {postedProductType == 1 ? "Bid" : "Offer"} on your behalf.</Box>} */}
                                </div>
                            }

                            {loadingListing &&
                                <Box sx={{ fontSize: '14px', color: '#2a2a2a', mb: .75, textAlign: 'center' }}>
                                    Fetching matching listings.
                                    <br /><br />
                                    <CircularProgress size={25} />
                                </Box>
                            }
                            {(!loadingListing && (!listingData || listingData.length == 0)) &&
                                <Box sx={{ fontSize: '14px', color: '#2a2a2a', mb: .75, textAlign: 'center' }}>No Listings Found.</Box>
                            }
                            <br />

                            {/* {props.data.matching_case == 2 && <ViewAllBidOffers buttonName={postedProductType == 1 ? "View all bids" : "view all offers"} />} */}
                            {/* {<ViewAllBidOffers buttonName={postedProductType != 1 ? "View all bids" : "view all offers"} />} */}

                            {/* {props.data.matching_case == 1 && <MakeAnotherListingBtn />} */}
                        </DialogContent>
                    </Box>
                    {
                        chatData.show && (
                            <ChatModal
                                key={chatData.listing_id}
                                data={chatData}
                                onClose={e => setChatData({ bidSent: true })}
                            />
                        )
                    }
                </Dialog>
            }
        </>
    );
}

const mapStateToProps = state =>
{
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps, { changeUserMode })(MatchingListingsDialog);