import React, { useState } from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
    Button,
    DialogContent
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: '450px'
    },
    '& .MuiDialog-paper': {
        // width: 'calc(100% - 190px)'
        backgroundColor: 'red'
    },
    '& .MuiCircularProgress-svg': {
        color: '#cccc'
    },
    btnPrimary: {
        backgroundColor: "#d4def1",
        color: "#2959BA",
        marginRight: '15px',
        "&:hover": {
            backgroundColor: "#d4def1"
        }
    },
}))

function ReferenceDialog(props) {
    // console.log(props)
    const classes = useStyles();
    const user = useSelector(state => state.userReducer.user);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const existingAddedContacts = useSelector(state => state.userReducer.user.reference_contacts_count);
    const [show, showSet] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose()
        }
    };

    React.useEffect(() => {
        showSet(props.show);
        // console.log(props.show)
        if (props.show == true) {
            handleClickOpen("referenceDialog")
        }

    }, [props.show])

    return (
        <>
            {show === true &&
                <Dialog
                    open={open === "referenceDialog"}
                    TransitionComponent={Transition}
                    keepMounted
                    classes={{ paper: classes.dialogPaper }}
                    maxWidth='xs'
                    onClose={e => {
                        handleClose(e);
                        props.showAutoQuoteDialogSet(false);
                    }}
                >

                    <Box>
                        <DialogTitle>
                            {/* <Box sx={{ fontSize: '14px', color: '#6a6a6a', mb: 1, textTransform: 'capitalize' }}></Box> */}
                            <Box sx={{ fontSize: '14px', color: '#2a2a2a', mb: .75, fontWeight: 'normal' }}>
                                {/* {console.log('existingAddedContacts', existingAddedContacts)}
                            {console.log('globalSetting', globalSetting.settings_mutual_badge_min_count)} */}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>Dear {user.first_name},</span><br /> There are only <strong>{props.length} companies</strong> added as references in your Trusted Circle. We strongly recommend that you add at least <strong>{(25 - props.length)} companies</strong> {existingAddedContacts == 0 ? '' : 'more '} for a secure and better trade experience.
                            </Box>
                            <IconButton
                                aria-label="close"
                                className="closeBtn"
                                size="small"
                                onClick={e => {
                                    handleClose(e)
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 1.5, textAlign: 'center' }}>
                                <Button
                                    component={Link}
                                    to="/my-safety-circle"
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add References
                                </Button>
                                <Button
                                    onClick={e => {
                                        handleClose(e);
                                        props.showAutoQuoteDialogSet(false);
                                    }}
                                    disableElevation
                                    size="small"
                                    variant="text"
                                    color="primary"
                                >
                                    Skip
                                </Button>
                            </Box>
                        </DialogContent>
                    </Box>

                </Dialog>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps)(ReferenceDialog);