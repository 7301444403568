import {
    REDIRECT_TO,
    STORE_CONSOLE_USER,
    CONSOLE_LOGIN_PROCESS,
    LOGIN_CONSOLE_USER,
    CHECK_AND_LOAD_CONSOLE_USER,
    APPBAR_HEADING,
    LOGOUT_CONSOLE_USER,
    APPBAR_ACTIONS_AREA,
    UPDATE_PAGE_PERMISSIONS
}
from '../actions/types';
import {config} from '../../config'

const initalState = {
    consoleUser: {},
    loginLoader: false,
    isUserChecked: false,
	isLoggedIn: false,
	loggingIn: false,
	loginStatus: '',
    loginMessage: '',
    company_id: null,
    companies: [],
    notifications: [],
	messagesCount: 0,
	unreadNotificationCount: 0,
    unreadNotifications: [],
    appbarHeading: '',
    appbarActionsArea: '',
    redirectTo: '',
    pagePermissions: []
}

export default function consoleUserReducer(state = initalState, action) {
    switch(action.type) {
        case REDIRECT_TO:
            return {
                ...state,
                redirectTo: action.payload
            }
        case APPBAR_HEADING:
            document.title = action.payload + ` | ${config.app_name}`
            return {
                ...state,
                appbarHeading: action.payload
            }
        case APPBAR_ACTIONS_AREA:
            return {
                ...state,
                appbarActionsArea: action.payload
            }
        case CONSOLE_LOGIN_PROCESS:
            return {
                ...state,
                loginLoader: action.payload.loginLoader,
                loginMessage: action.payload.loginMessage
            }
        case STORE_CONSOLE_USER:
            return {
                ...state,
                consoleUser: action.payload.consoleUser,
                isLoggedIn: true
            }
        case LOGIN_CONSOLE_USER:
            return {
                ...state,
                consoleUser: action.payload.consoleUser,
                isLoggedIn: action.payload.isLoggedIn,
                loginStatus: action.payload.loginStatus
            }
        case CHECK_AND_LOAD_CONSOLE_USER:
            return {
                ...state,
                consoleUser: action.payload.consoleUser,
                isLoggedIn: action.payload.isLoggedIn
            }
        case LOGOUT_CONSOLE_USER:
            return {
                ...state,
                consoleUser: {},
                isLoggedIn: false,
                loginMessage: 'You have successfully logged out.'
            }
        case UPDATE_PAGE_PERMISSIONS:
            return {
                ...state,
                pagePermissions: action.payload.pagePermissions
            }
        default:
            return state;
    }
}
