import React from 'react'
import Emitter from '../../../services/emitter'
import TopBidOfferListingDialog from './TopBidOfferListingDialog'
import { Product } from '../../../api/resources/Product';
import { User } from '../../../api/resources/User';

export default function TopBidOfferSuggestionContainer() {

    const [showDialog, showDialogSet] = React.useState(false)
    const [loadingVesselPrice, loadingVesselPriceSet] = React.useState(false);
    // const [vesselPriceBuyer, vesselPriceBuyerSet] = React.useState([]);
    // const [vesselPriceSeller, vesselPriceSellerSet] = React.useState([]);
    const [listings, listingsSet] = React.useState([]);
    const [actionType, actionTypeSet] = React.useState(null)
    const [arePricesFetched, arePricesFetchedSet] = React.useState(false);
    const [isExpanded, isExpandedSet] = React.useState(false)
    const [vesselAttrID, vesselAttrIDSet] = React.useState('')
    const [listingId, listingIdSet] = React.useState(null)
    const [listing, listingSet] = React.useState(null)
    const [quantity, quantitySet] = React.useState(null)
    const [postingListingData, postingListingDataSet] = React.useState(null);
    const [vesselType, vesselTypeSet] = React.useState(null)


    // console.log(listingId)
    const getListingData = () => {
        Product.viewProductDetails({
            // listing_id: props.data.listing_id
            listing_id: listingId
        })
            .then(response => {
                listingSet(response.data.data);

                User.chats
                    .fetch({ listing_id: listingId, company_id: response.data.data.company_id })
                    .then(response => {
                        if (response.data.status == "success") {
                            postingListingDataSet(response.data.data[0]?.message_log.data);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            })
            .catch(error => {
                console.log(error);
            });
    }

    /**
     * @function getBestListingOfferRFQ
     * @name getBestListingOfferRFQ
     * @description
     * Function to fetch the best 3 listing. requirements and offers
     *
    */


    const getBestListingOfferRFQ = async () => {
        const params = { vessel_attribute_id: vesselAttrID, type: vesselType, top_5_quantity: quantity, top_5_listings_module: 1, exclude_bid_offer_sent: 1, exclude_self_listings: 1, limit: 5 }
        loadingVesselPriceSet(true)
        try {
            const res = await Product.bestListingOfferRFQ(params);
            if (res.data.status == "success") {
                // vesselPriceBuyerSet(res.data.data.buyer);
                // vesselPriceSellerSet(res.data.data.seller);
                if ((res.data.data.buyer && res.data.data.buyer.length > 0) || (res.data.data.seller && res.data.data.seller.length > 0)) {

                    // console.log('Ok')
                    // listingsSet(res.data.data.seller)
                    if (actionType == 1) {
                        listingsSet(res.data.data.seller)
                    }
                    else {
                        listingsSet(res.data.data.buyer)
                    }
                    showDialogSet(true)
                }

                if (res.data.data.buyer || res.data.data.seller) {
                    isExpandedSet(true)
                }
                arePricesFetchedSet(true)
            }
        } catch (e) {
            console.error(e);
        }
        loadingVesselPriceSet(false)
    };

    const resetData = () => {
        showDialogSet(false)
        loadingVesselPriceSet(false);
        listingsSet([]);
        actionTypeSet(null)
        arePricesFetchedSet(false);
        isExpandedSet(false)
        vesselAttrIDSet('')
        listingIdSet(null)
        listingSet(null)
        vesselTypeSet(null)
        quantitySet(null)
    }


    React.useEffect(() => {
        Emitter.on("BID_OFFER_SENT", (e) => {
            // console.log(e)
            // listingIdSet(27140)
            // actionTypeSet(2)
            // vesselTypeSet(2)
            // vesselAttrIDSet(887)
            actionTypeSet(e.action)
            vesselTypeSet(e.type)
            vesselAttrIDSet(e.vessel_attribute_id)
            listingIdSet(e.product_id)
            quantitySet(e.quantity)
            // getBestListingOfferRFQ({vessel_attribute_id: vesselAttrID, type: vesselType}, {action: 2});
            // listingIdSet(e.product_id)
            // actionTypeSet(e.action)
            // getBestListingOfferRFQ({vessel_attribute_id: e.vessel_attribute_id, type: e.type});
            // console.log('Success')
        });

        // return () => {
        //     resetData()
        // }

    }, [])

    React.useEffect(() => {
        if (actionType, vesselType, vesselAttrID) {
            getBestListingOfferRFQ();
        }

        // return () => {
        //     resetData()
        // }
    }, [actionType, vesselType, vesselAttrID, quantity])


    React.useEffect(() => {
        if (listingId) {
            getListingData()
        }

        // return () => {
        //     listingSet(null)
        //     listingIdSet(null)
        // }

    }, [listingId])

    //   React.useEffect(() => {
    //     console.log(showDialog)
    //     console.log(listing)
    //     console.log(listings)
    //   }, [showDialog, listing, listings])


    return (
        <>
            {(showDialog && listing && listings && listings.length > 0) && <TopBidOfferListingDialog
                listings={listings}
                product={listing}
                postingListingData={postingListingData}
                listingId={listingId}
                show={showDialog}
                isTopListings={true}
                currency={listing?.currency}
                onClose={e => { resetData() }}
                onReload={e => { getBestListingOfferRFQ() }}
            />}
        </>
    )
}
