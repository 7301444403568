import React from "react";
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
// import loaderIcon from "../../images/favicon-loader.svg";

const useStyles = makeStyles(theme => ({
    loaderBG: {}
}));

/**
 * Loader
 *
 * @component
 * @example
 *
 * <Loader />
 */
function Loader() {
    const classes = useStyles();
    return (
        <div className="loader-wrapper">
            <Box className={`${classes.loaderBG} loader`}>
                <img src="/images/loader-new.svg" alt="loader" className="img-fluid" />
            </Box>
        </div>
    );
}

export default Loader;
