import React from "react";
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { Button } from "@mui/material";
import { capitalize } from "lodash";
import { buttonClasses } from "../../theme";

const useStyles = makeStyles(theme => buttonClasses(theme));

export default function ThemeButton(props) {
    const classes = useStyles();
    const userMode = useSelector(state => state.userReducer.user_mode);
    const classPrefix = userMode == 1 ? 'buyer' : 'seller';

    const { children, variant, className, color, ...other } = props;
    const [buttonClass, setButtonClass] = React.useState(classes[classPrefix + 'Button' + capitalize(variant)]);

    React.useEffect(() => {
        setButtonClass(classes[classPrefix + 'Button' + capitalize(variant)]);
    }, [userMode, variant]);

    return (
        <Button
            variant={variant}
            color={color}
            className={`theme-button ${(color == "secondary" ? "" : classPrefix+"-theme-button " + buttonClass)} ${className}`}
            {...other}
        >
            {children}
        </Button>
    );
}