import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import ThemeButton from '../partials/ThemeButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import add_reference_image from '../../images/add_references-img.png';
import CancelIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eef2f5',
    borderTop: 'solid 1px #ccc',
    borderBottom: 'solid 1px #ccc',
    backgroundImage: `url(${add_reference_image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '275px 170px',
    backgroundPosition: 'center -22px',
    // marginTop: '8px'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      display: 'block',
      padding: theme.spacing(1, 0),
      '& > div': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  button: {
    fontSize: '11px',
    backgroundColor: 'transparent',
    color: '#6a6a6a',
    padding: '1px 2px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonPrimary: {
    fontSize: '11px',
    backgroundColor: 'transparent',
    // color: '#0645ad',
    color: theme.palette.primary.main,
    padding: '1px 2px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonPrimaryLight: {
    backgroundColor: '#d4def1',
    color: '#2959BA',
    '&:hover': {
      backgroundColor: '#3f51b557',
    },
  },
  btn: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#1976d2',
  },
}));

/**
 *
 * @name PromptAddReference
 * @alias PromptAddReference
 * @component
 * @version 1.0.0
 * @description
 * The dismissible component that prompts users to download CoalShastra app in web view.
 */

export default function PromptAddReference() {
  const classes = useStyles();
  const [visible, visibleSet] = React.useState(true);
  const globalSetting = useSelector(
    (state) => state.commonReducer.globalSettings
  );
  const user = useSelector((state) => state.userReducer.user);
  const existingAddedContacts = user?.reference_contacts_count;

  return (
    visible && (
      <Box className={`isRelative ${classes.root}`}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              <Box className={classes.wrapper}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ fontWeight: 'bold', mr: 1 }}>
                    <img src="/images/new-badge-32x32.png" />
                  </Box>
                  <Typography variant="body2" component="p">
                    {/* Add{' '}
                    {globalSetting &&
                      existingAddedContacts <
                        globalSetting.settings_mutual_badge_min_count &&
                      globalSetting.settings_mutual_badge_min_count -
                        existingAddedContacts}{' '}
                    {existingAddedContacts == 0 ? '' : 'more '}
                    verified contacts in your trusted contacts list now, and
                    find unique listings created by your trusted contacts. */}
                    Add more References in your list & activate the Trust Badge{' '}
                    <img
                      src={`/images/trusted-company-listing.svg`}
                      style={{ width: '20px', marginRight: '5px' }}
                      className={'vAlignWithIcon'}
                      alt="trusted listing"
                    />
                  </Typography>
                </Box>
                <ThemeButton
                  disableElevation
                  component={Link}
                  variant="contained"
                  size="small"
                  to={`/my-safety-circle`}
                >
                  Add References
                </ThemeButton>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <IconButton
          aria-label="close"
          className="closeBtn"
          size="small"
          onClick={(e) => visibleSet(false, e)}
        >
          <CancelIcon />
        </IconButton>
      </Box>
    )
  );
}
