import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { config } from '../../config';
import { Helmet } from "react-helmet";
import eventsTracking from '../../eventsTracking';
import useMediaQuery from "@mui/material/useMediaQuery";
import { uniqueId } from "lodash";
import Heading from './Heading'
import RatePercent from '../console/admin/benchmark/RatePercent'
import SmCardLoader from '../partials/content-loaders/SmCardLoader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector } from 'react-redux'
import { User } from '../../api/resources/User'
import Hidden from '@mui/material/Hidden';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoadableVisibility from "react-loadable-visibility/loadable-components";
import { useLocation } from 'react-router-dom'
import PendingRating from "./PendingRating";
import ReviewRatingDialog from "./ReviewRatingDialog";
import { cDecrypt } from "../../helpers";
import RegisterCard from './RegisterCard';
import { Alert, Divider } from '@mui/material';
import IframeWindow from './IframeWindow';
import { Product } from '../../api/resources/Product';
import AdSpace from '../AdSpace';
// import JSWFakeMessageAlert from '../JSWFakeMessageAlert';
import FreeDeals202303Offer from '../FreeDeals202303Offer';
// import BottomTabsNav from './../partials/BottomTabsNav'
var uaParser = require('ua-parser-js');


const BottomTabsNav = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/partials-BottomTabsNav" */ "../partials/BottomTabsNav"));
const LatestFiveDeals = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-LatestFiveDeals" */ "./LatestFiveDeals"));
// const GreetUser = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-GreetUser" */ "./GreetUser"));
const SignUpWithMobile = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-SignUpWithMobile" */ "./SignUpWithMobile"));
const MostActiveContracts = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-MostActiveContracts" */ "./MostActiveContracts"));
const BannersCarousel = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-BannersCarousel" */ "./BannersCarousel"));
const TrendingVesselsMines = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-TrendingVesselsMines" */ "./TrendingVesselsMines"));
const CoalOrigins = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-CoalOrigins" */ "./CoalOrigins"));
const FavoriteContracts = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-FavoriteContracts" */ "./FavoriteContracts"));
const Benchmarks = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Benchmarks" */ "./Benchmarks"));
const GlobalCoalNews = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-GlobalCoalNews" */ "./GlobalCoalNews"));
const PromptIOSAppDownloadWeb = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/components-app-download-banners-PromptIOSAppDownloadWeb" */ "../app-download-banners/PromptAppDownload"));

const AboutCoalShastra = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-AboutCoalShastra" */ "./AboutCoalShastra"));
const Partners = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Partners" */ "./Partners"));
const KeyFeaturesSlider = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-KeyFeaturesSlider" */ "./KeyFeaturesSlider"));
const AppDownloadCTA = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-AppDownload" */ "./AppDownload"));
const WhyCoalShastra = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-WhyCoalShastra" */ "./WhyCoalShastra"));
const StartJourney = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-StartJourney" */ "./StartJourney"));
const Testimonials = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Testimonials" */ "./Testimonials"));
const SignUpCTA = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-SignUpCTA" */ "./SignUpCTA"));

const useStyles = makeStyles(theme => ({
    item: {
        // [theme.breakpoints.down('md')]: {
        flexShrink: 0,
        scrollSnapAlign: 'start',
        // padding: theme.spacing(1, 2)
        // }
    }
}));
export default function Index() {
    const classes = useStyles();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
    const user = useSelector(state => state.userReducer.user);
    const userReducer = useSelector(state => state.userReducer)
    // const user_mode = useSelector(state => state.userReducer.user_mode);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const [showDownloadPrompt, showDownloadPromptSet] = React.useState(false);
    const [pendingRatingData, setPendingRatingData] = React.useState("");
    const matchesViewport = useMediaQuery("(max-width:580px)");
    const location = useLocation();
    const guestNumberVerified = sessionStorage.getItem('data') !== null;
    // console.log('guestNumberVerified', guestNumberVerified)
    const [companyId, setCompanyId] = React.useState("");
    const [rating, setRating] = React.useState({});
    const [rewards, setRewards] = React.useState([]);
    const [remarks, setRemarks] = React.useState("");
    const [ratingLoading, ratingLoadingSet] = React.useState(false);
    const [adData, setAdData] = React.useState([]);
    const [adDataMid, setAdDataMid] = React.useState([]);
    const [adDataTop, setAdDataTop] = React.useState([]);
    const [adDataBottom, setAdDataBottom] = React.useState([]);
    const [guestBenchmarkListLoading, setGuestBenchmarkListLoading] = React.useState(false);
    // const [benchmarkDetailsLoader, setBenchmarkDetailsLoader] = useState(false);
    const [benchmarkListGuest, benchmarkListGuestSet] = React.useState({});


    const getPendingRatings = () => {
        User.getPendingRatings()
            .then(response => {
                setPendingRatingData(response.data.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {

            });
    };

    /**
    * @function getRatings
    * @name getRatings
    * @description
    * An async function to get ratings from company
    *
    * @async
    */

    const getRatings = rating_id => {
        ratingLoadingSet(true);

        User.getRating({ rating_id: rating_id })
            .then(response => {
                if (response.data.status == "success") {
                    setCompanyId(response.data.data.rating.company_from);
                    setRating(response.data.data.rating);
                    setRewards(response.data.data.rating.rewards)
                    setRemarks(response.data.data.rating.remarks);
                    ratingLoadingSet(false);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                ratingLoadingSet(false);
            });
    };

    React.useEffect(() => {
        const parsedUA = new uaParser()
        var browser = parsedUA.getBrowser()
        var os = parsedUA.getOS()
        var device = parsedUA.getDevice()
        if (
            // browser.name == 'Chrome' ||
            browser.name == 'Mobile Safari' || browser.name == 'Safari' || os.name == 'Mac OS' || device.type == 'Apple'
        ) {
            showDownloadPromptSet(true)
        }
        if (isLoggedIn) {
            getPendingRatings();
            if (location.search) {
                getRatings(cDecrypt((location.search).split('?rating_id=')[1]));
            }
        }
    }, [isLoggedIn, location])

    const fetchGuestBenchmarkList = async (params) => {
        setGuestBenchmarkListLoading(true)
        var params = Object.assign({
            //   keyword: searchKeyword,
        }, params)

        try {
            const res = await User.guestBenchmarkList(params)
            if (res.data.status === "success") {
                benchmarkListGuestSet(res.data.data);
                // setBenchmarkID(res.data.data.id)
            }
        }
        catch (e) {
            console.error(e)
        }
        setGuestBenchmarkListLoading(false)
    }

    React.useEffect(() => {
        fetchGuestBenchmarkList()
    }, [])

    const fetchAdSpaceData = async (params) => {
        setGuestBenchmarkListLoading(true)
        var params = Object.assign({
            platform_type: 2,
            placement: '1,2,3',
            page: 'homepage'
        }, params)

        try {
            const res = await User.adSpaceData(params)
            if (res.data.status === "success") {
                //console.log(res.data?.data?.adSpaces?.filter((a) => a.placement == 1));
                setAdData(res.data?.data?.adSpaces);
                setAdDataMid(res.data?.data?.adSpaces?.filter((a) => a.placement == 2));
                setAdDataTop(res.data?.data?.adSpaces?.filter((a) => a.placement == 1));
                setAdDataBottom(res.data?.data?.adSpaces?.filter((a) => a.placement == 3));
            }
        }
        catch (e) {
            console.error(e)
        }
        setGuestBenchmarkListLoading(false)
    }

    React.useEffect(() => {
        fetchAdSpaceData()
    }, [])

    const getListingData = (listingId) => {
        Product.viewProductDetails({
            listing_id: listingId
        })
            .then(response => {
                if (response.data.status == "success") {
                    navigate(`/markets?market_code=${response.data.data && response.data.data?.market?.market_code}&contract_code=${response.data.data?.contract?.vessel_code}`)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Box>
            {/* SEO */}

            <Helmet>
                <title>
                    Home | Coal Marketplace | Coal Suppliers And Importers |
                    Coalshastra
                </title>
                <meta
                    name="description"
                    content="We at Coalshastra aim to provide you the best B2B online marketplace to buy and sell coal. TRUST us to level up your margin game with our professional support."
                />
                <link rel="canonical" href={config.base_url} />
                <script type="application/ld+json">
                    {`"@context": "http://www.schema.org",
                        "@type": "Organization",
                        "name": "Coalshastra",
                        "email": "info@coalshastra.com",
                        "url": "https://www.coalshastra.com",
                        "logo": "https://www.coalshastra.com/images/coalshastra-logo-white.png",
                        "description": "",
                        "sameAs" : [
                            "https://www.facebook.com/coalshastra/",
                            "https://twitter.com/CoalShastra",
                            "https://www.linkedin.com/company/coalshastra.com"
                        ],
                        "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Pitam Pura",
                        "addressRegion": "Delhi",
                        "postalCode": "110034",
                        "addressCountry": "India"
                        },
                        "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "011 4552 8444",
                        "contactType": "customer service"
                        }`}
                </script>
                <script type="application/ld+json">
                    {` "@context" : "http://schema.org",
                        "@type" : "WebSite",
                        "name" : "CoalShastra",
                        "url" : "http://coalshastra.com"  `}
                </script>
                <script type="application/ld+json">
                    {`"@context": "https://schema.org",
                        "@graph":
                        [
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Login",
                                "url": "https://www.coalshastra.com/login"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Register",
                                "url": "https://www.coalshastra.com/sign-up"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "About Us",
                                "url": "https://www.coalshastra.com/about-us"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Blog",
                                "url": "https://www.coalshastra.com/blog"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Mediakit",
                                "url": "https://www.coalshastra.com/media-kit"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "News",
                                "url": "https://www.coalshastra.com/news"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Media and Events",
                                "url": "https://www.coalshastra.com/events"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Contact Us",
                                "url": "https://www.coalshastra.com/contact"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Indexes",
                                "url": "https://www.coalshastra.com/global-indexes"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Auction",
                                "url": "https://www.coalshastra.com/auction"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Request for Quotation",
                                "url": "https://www.coalshastra.com/requirement/buyer"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Seller Listings Map",
                                "url": "https://www.coalshastra.com/seller-map"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "New Listings",
                                "url": "https://www.coalshastra.com/requirement/seller"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Buyers RFQ's Map",
                                "url": "https://www.coalshastra.com/listings"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Unit Converter",
                                "url": "https://www.coalshastra.com/tools/unit-converter"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Basis Converter",
                                "url": "https://www.coalshastra.com/tools/basis-converter"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Ratio Converter",
                                "url": "https://www.coalshastra.com/tools/ratio-calculator"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Slagging & Fouling Calculator",
                                "url": "https://www.coalshastra.com/tools/slagging-and-fouling"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Blending Calculator",
                                "url": "https://www.coalshastra.com/tools/blending-calculator"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Rail Freight Calculator",
                                "url": "https://www.coalshastra.com/tools/rail-freight-calculator"
                            }
                        ]`}
                </script>
            </Helmet>
            {showDownloadPrompt && <PromptIOSAppDownloadWeb open={showDownloadPrompt} />}
            {/**
             * TODO add space
             */}
            {/* <JSWFakeMessageAlert /> */}
            {/* <FreeDeals202303Offer /> */}
            {adDataTop.length > 0 && <AdSpace key='top' adSpace={adDataTop[0]} matchesViewport={matchesViewport} />}
            {/* Latest five deals */}
            <Hidden mdUp><Box sx={{ background: "#fff", px: 1, pt: 1, pb: 0 }}><Typography variant="h6" component="h1"><strong>Latest Deals:</strong></Typography></Box></Hidden>
            {/* <Box sx={{background: "#EEF2F5", p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5, overflow: 'auto', scrollSnapType: 'x mandatory'}}> */}

            <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: { xs: 'initial', md: '180px auto' }, background: "#fff", p: 1, mb: 0, }}>
                <Hidden mdDown><Typography variant="h6" component="h1"><strong>Latest Deals:</strong></Typography></Hidden>
                <Box sx={{ position: 'relative', overflow: 'hidden', height: 82 }}>
                    <Box className={`scrollingItem`}>
                        <LatestFiveDeals
                            events={eventsTracking}
                            user={user && user.id}
                        />
                    </Box>
                </Box>
            </Box>

            {/* Guest users */}
            {<SignUpWithMobile
                user={user}
                events={eventsTracking}
            />}
            {!isLoggedIn &&
                <>
                    {!guestNumberVerified && <> <Container>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <Hidden smUp>
                                    <Box textAlign="center" sx={{ my: { xs: 4 } }}>
                                        <Box sx={{ my: 3 }}>
                                            <Typography variant="h4" component="h2" display="block" gutterBottom>
                                                <strong style={{ color: '#2959BA' }}>8500+</strong>
                                            </Typography>
                                            <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px' }}>
                                                Clients
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ my: 3 }}>
                                            <Typography variant="h4" component="h2" display="block" gutterBottom>
                                                <strong style={{ color: '#2959BA' }}>6000+</strong>
                                            </Typography>
                                            <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px' }}>
                                                Deals
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ mt: 3 }}>
                                            <Typography variant="h4" component="h2" display="block" gutterBottom>
                                                <strong style={{ color: '#2959BA' }}>3 Mn MT</strong>
                                            </Typography>
                                            <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px' }}>
                                                Quantity Transacted
                                            </Box>
                                        </Box>
                                    </Box>
                                </Hidden>
                                <AboutCoalShastra />
                                <Partners />
                            </Grid>
                        </Grid>
                    </Container>


                        <Box sx={{ background: '#00838F', pt: { xs: 8, sm: 3 }, pb: { xs: 5, sm: 4 } }}>
                            <KeyFeaturesSlider />
                        </Box>
                        <WhyCoalShastra />
                        <Box sx={{ background: '#EEF2F5' }}>
                            <StartJourney />
                        </Box>

                        <Box sx={{ py: 4 }}>
                            <Testimonials />
                        </Box>
                        <Box sx={{ background: '#EEF2F5' }}>
                            <AppDownloadCTA />
                        </Box>
                    </>
                    }
                </>
            }


            <Container>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>

                        {/* Most active contracts of the day */}
                        {isLoggedIn &&
                            <MostActiveContracts
                                events={eventsTracking}
                                guestNumberVerified={guestNumberVerified}
                                user={user && user.id}
                            />
                        }
                        {(!isLoggedIn && guestNumberVerified) &&
                            <MostActiveContracts
                                events={eventsTracking}
                                guestNumberVerified={guestNumberVerified}
                                user={user && user.id}
                            />
                        }

                        {/* My favorite contracts - for authenticated users */}
                        {(isLoggedIn && !userReducer.noCompany) &&
                            <FavoriteContracts
                                events={eventsTracking}
                                user={user && user.id}
                            />
                        }

                        {/**
             * TODO add space
             */}
                        {adDataMid.length > 0 && <AdSpace key='mid' adSpace={adDataMid[0]} matchesViewport={matchesViewport} />}

                    </Grid>
                </Grid>
                <Hidden smUp>
                    <BottomTabsNav />
                </Hidden>
            </Container>

            {/* Banners */}


            {(isLoggedIn || guestNumberVerified) &&
                <BannersCarousel
                    events={eventsTracking}
                    // guestNumberVerified={guestNumberVerified}
                    user={user && user.id}
                />
            }

            {(guestNumberVerified || isLoggedIn) &&
                <Container>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            {/* Trending Vessel/Mine (last 7 days) */}
                            <TrendingVesselsMines
                                events={eventsTracking}
                                guestNumberVerified={guestNumberVerified}
                                user={user && user.id}
                            />
                        </Grid>
                    </Grid>
                </Container>
            }

            {/* {(guestNumberVerified || isLoggedIn) &&
                <Container>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Heading title={'Coal Origin'} />
                                <CoalOrigins
                                    events={eventsTracking}
                                    guestNumberVerified={guestNumberVerified}
                                    user={user && user.id}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            } */}


            {/* {isLoggedIn &&
                <Container>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}> */}

            {/* Benchmarks */}
            {/* {(userReducer.noCompany) ? */}
            {/* <Benchmarks
                                    user={user}
                                    guestNumberVerified={guestNumberVerified}
                                    user_mode={user_mode}
                                    events={eventsTracking}
                                /> */}
            {/* : */}
            {/* <Box sx={{ mb: 4 }}>
                                    <Heading title={'Benchmarks'} />
                                    <Box sx={{ display: 'flex', overflow: 'auto', scrollSnapType: 'x mandatory', flexBasis: '40%', pb: { sm: 3 } }}>
                                        {guestBenchmarkListLoading && [0, 1, 2, 3, 4].map((i) => (
                                            <Box sx={{ marginRight: '10px' }} key={i} >
                                                <SmCardLoader key={i}
                                                    loaderProps={{ height: "115px", width: '200px' }}
                                                />
                                            </Box>
                                        ))}
                                        {(!guestBenchmarkListLoading && benchmarkListGuest) &&
                                            <Card variant="outlined" sx={{ mr: 1, '&:hover': { boxShadow: '1px 10px 16px #ddd' } }} className={classes.item} key={uniqueId("bm_")}>
                                                <CardContent sx={{ p: 1 }}>
                                                    <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '13px' }}>
                                                        <img src={benchmarkListGuest?.country?.flag_img} alt="flag" width="20" />&nbsp;
                                                        &nbsp;
                                                        {benchmarkListGuest?.country?.iso} | &nbsp;

                                                        {benchmarkListGuest.quality_from} {benchmarkListGuest.quality_type_text} - {benchmarkListGuest.quality_to} {benchmarkListGuest.quality_type_text}
                                                    </Box>
                                                    <Box sx={{ fontSize: '14px', fontWeight: '600', mb: 1 }}>{benchmarkListGuest.name}</Box>
                                                    <Box sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        <img src="../images/rupee-icon.png" alt='INR' width="10" />
                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(benchmarkListGuest.current_week_price)} &nbsp;
                                                        <RatePercent percentage={benchmarkListGuest.price_percentage} />
                                                        <IconButton
                                                            component={Link}
                                                            to={`/guest/benchmark`}
                                                            size="small" color='primary' sx={{ verticalAlign: 'sub' }}>
                                                            <ChevronRightIcon fontSize='small' className="vAlignBottom" />
                                                        </IconButton>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        }
                                        <RegisterCard label={`benchmarks`} />
                                    </Box>
                                </Box> */}
            {/* } */}
            {/* </Grid>
                    </Grid>
                </Container>
            } */}

            {/* Global coal news */}
            {/* Render widget if enabled, ie; value is 1 */}
            {(globalSetting.settings_steelmint_widget_enable == 1) &&
                <Container maxWidth='lg'>
                    <Box sx={{ mt: { sm: 4, xs: 2 }, mb: { sm: 7, xs: 4 } }}>
                        <IframeWindow src="/steelmint-widget.html" />
                    </Box>
                </Container>
            }
            {/* <Box sx={{ mt: { sm: 4, xs: 2 }, mb: { sm: 7, xs: 4 } }}>
                <GlobalCoalNews
                    events={eventsTracking}
                    user={user && user.id}
                />
            </Box> */}

            {!isLoggedIn &&
                <Box sx={{ background: '#2959BA' }}>
                    <SignUpCTA />
                </Box>
            }

            {/**
             * TODO add space
             */}
            {adDataBottom.length > 0 && <AdSpace key='bottom' adSpace={adDataBottom[0]} matchesViewport={matchesViewport} />}

            {isLoggedIn && <Box sx={{ backgroundColor: '#2959BA', py: 1.5, textAlign: 'center', mt: 2 }}>
                <MuiLink
                    // color="primary"
                    underline='none'
                    component={Link}
                    to={`/markets`}
                    size="small"
                    onClick={e => { eventsTracking.exploreMarkets(user?.id ? user?.id : "Unregistered_user") }}
                    sx={{ fontWeight: 'bold', fontSize: '18px', color: '#fff' }}
                >
                    Explore Coal Markets <ChevronRightIcon fontSize='small' className="vAlignMiddle" />
                </MuiLink>
            </Box>}


            {(pendingRatingData && pendingRatingData.length > 0) &&
                <PendingRating pendingRatingData={pendingRatingData} />
            }

            {user && user.active_company == companyId && (rewards && rewards.length > 0) &&
                <ReviewRatingDialog rewards={rewards} rating={rating} remarks={remarks} loading={ratingLoading} />
            }
        </Box>
    )
}
