
const packagesInternaional = {
    staging: [
        {
            "id": 1, planId: "pay_as_you_go", "plan_title": "accessibility", 'isInternational': true,
            "price": "0", "tax_info": "999.00", "amount": "999.00", 'sub_currency': '$', 'currency': '¢',
            "ad_credit": 'as required', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVN9cSsnpZPVR" }
        },

        {
            "id": 2, planId: "silver", "plan_title": "starter", 'isInternational': true,
            "price": "0.15", "tax_info": "4500.00", "amount": "4500.00", 'sub_currency': '$', 'currency': '$',
            "ad_credit": '30,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVBo3CidkMZtz" }
        },
        {
            "id": 3, planId: "gold", "plan_title": "pro", 'isInternational': true,
            "price": "0.10", "tax_info": "10000.00", "amount": "10000.00", 'sub_currency': '$', 'currency': '$',
            "ad_credit": '1,00,000', "ad_credit_validity": '1 year', "best_seller": true,
            "paymentButton": { payment_button_id: "pl_IJVDRGGL15eBDp" }
        },
    ],
    production: [
        {
            "id": 1, planId: "pay_as_you_go", "plan_title": "accessibility", 'isInternational': true,
            "price": "999.00", "tax_info": "0", "amount": "999.00", 'sub_currency': '$', 'currency': '¢',
            "ad_credit": 'as required', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVN9cSsnpZPVR" }
        },

        {
            "id": 2, planId: "silver", "plan_title": "starter", 'isInternational': true,
            "price": "0.15", "tax_info": "4500.00", "amount": "4500.00", 'sub_currency': '$', 'currency': '$',
            "ad_credit": '30,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVBo3CidkMZtz" }
        },
        {
            "id": 3, planId: "gold", "plan_title": "pro", 'isInternational': true,
            "price": "0.10", "tax_info": "10,000.00", "amount": "10,000.00", 'sub_currency': '$', 'currency': '$',
            "ad_credit": '1,00,000', "ad_credit_validity": '1 year', "best_seller": true,
            "paymentButton": { payment_button_id: "pl_IJVDRGGL15eBDp" }
        },
    ]
}


export default (process.env.REACT_APP_ENV == 'staging' || process.env.REACT_APP_ENV == 'development') ? packagesInternaional.staging : packagesInternaional.production