import React, { useState } from 'react';

import { IconButton, Box, Container, Dialog, DialogTitle, DialogContent, Slide, Button, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    blink: {
        animation: 'blinker .75s linear infinite',
        fontSize: '20px'
    },
    padding: {
        '& .MuiDialogTitle-root': {
            padding: 0
        },
    }

}));

export default function FreeDeals202303Offer() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return <>

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth='sm'
            onClose={e => {
                handleClose(e);
            }}
        >
            <Box className={classes.padding}>
                <DialogTitle align="center">
                    <Box mt={1.5} textAlign="center"><strong><img src="../images/announcement.png" alt="caution" width="28" /> <span className={classes.blink}>EXTENDED!! Deal for Free</span> <img src="../images/announcement.png" alt="caution" width="28" />
                        <br />
                        <Typography variant="h6"> NEW YEAR BONUS!</Typography>
                    </strong>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    className="closeBtn"
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>
                <DialogContent>
                    <Box style={{ backgroundColor: "#fff4e5" }} py={2}>
                        <Container>
                            <Box><Typography variant="h6">EXTENDED!! ALL Buying & Selling 🤝 in the 'USA-Gujarat' market is FREE 🤩!! from 1st April 2023 till 30th June 2023</Typography></Box>
                            <br />
                            <Box>Offer valid only for clients having an active package.</Box>
                            <br />
                            <Box>Don't have a package? No Worries, Click Below 👇 to Grab this Offer 🏃‍♀️</Box>
                            <br />
                            <Box textAlign="center"><Button component={Link} variant='contained' to="/pricing-plans">Become Member</Button></Box>
                        </Container>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    </>
}