import React from 'react'
import Box from "@mui/material/Box"
// import Container from "@mui/material/Container"
// import Grid from "@mui/material/Grid"
// import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { Link, Navigate } from "react-router-dom";
import wave from "../../images/waves2.png";
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router"
// import Hidden from '@mui/material/Hidden';
import useMediaQuery from "@mui/material/useMediaQuery";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { validatorRegEx, cEncrypt } from '../../helpers'
import { TextField, InputAdornment, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { Autocomplete } from '@mui/material';
import { Product } from '../../api/resources/Product';
import { Extras } from '../../api/resources/Extras';
import { User } from '../../api/resources/User'
import LoadingButton from '@mui/lab/LoadingButton';
import Emitter from '../../services/emitter'
import OTPTimer from '../partials/OTPTimer'

const useStyles = makeStyles(theme => ({
    guestUser: {
        position: 'relative',
        // fontSize: "3rem",
        paddingTop: theme.spacing(4),
        paddingBottom: "118px",
        backgroundColor: theme.palette.common.white,
        backgroundImage: `url(${wave})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: '100% 175px',
        // backgroundSize: "contain",
        [theme.breakpoints.down("sm")]: {
            backgroundSize: 'inherit',
        }
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "95px auto",
        alignItems: 'flex-start',
        gridGap: '5px',
    },

    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr .5fr",
        alignItems: 'center',
        gridGap: '5px'
    },
    iconBg: {
        position: 'relative',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
    },
    bgGray: {
        backgroundColor: '#F6F6F6'
    }
}));

const BrandStoreStep1 = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const delayResendBy = 90;
    const [delay, setDelay] = React.useState(delayResendBy);
    // const userReducer = useSelector(state => state.userReducer)
    // const matchesViewport = useMediaQuery("(max-width: 580px)");
    // const matchesViewportBanner = useMediaQuery("(max-width: 1160px)");
    const countries = useSelector(state => state.commonReducer.countries);
    const [countriesVal, countriesSet] = React.useState([]);
    const [contactNumber, contactNumberSet] = React.useState("");
    // const [selectedCurrencyObj, selectedCurrencyObjSet] = React.useState();
    const [phoneCode, phoneCodeSet] = React.useState('+91');
    const [otpSent, otpSentSet] = React.useState(false)
    const [otp, otpSet] = React.useState('')
    const [responseKeyData, responseKeyDataSet] = React.useState(null);
    const [loadingCountries, setLoadingCountries] = React.useState(false);
    const [contactNumberVerificationMessageError, contactNumberVerificationMessageErrorSet] = React.useState('');
    const [otpVerificationMessageError, otpVerificationMessageErrorSet] = React.useState('');
    // const [contactVerified, contactVerifiedSet] = React.useState(false);
    const [contactVerified, contactVerifiedSet] = React.useState(!! sessionStorage.getItem('contactVerified'));
    const [otpVerifiedLoader, otpVerifiedLoaderSet] = React.useState(false);
    const [sendingOTP, sendingOTPSet] = React.useState(null);

    // Log contact to LSQ
    const [submitting, submittingSet] = React.useState(false);
    const [submitSuccess, submitSuccessSet] = React.useState(false);

    /**
     * @function getCountries
     * @name getCountries
     * @description
     * An async function to fetch the all the countries
     *
     * @async
     */

    const fetchCountries = async (params) => {
        setLoadingCountries(true)
        var params = Object.assign({
            currency_status: 1
        })
        try {
            const res = await Product.getCountries(params);
            if (res.data.status == 'success') {
                countriesSet(res.data.data)
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoadingCountries(false)
    }

    React.useEffect(() => {
        fetchCountries()
    }, [])

    const sendVerificationCode = async () => {
        sendingOTPSet(true);
        if (phoneCode == '+91') {
            try {
                const res = await User.sendVerificationCode({ type: 2, contact_number: contactNumber })
                //console.log(res)
                if (res.data.status && res.data.status == 'success') {
                    otpSentSet(true);
                    responseKeyDataSet(res.data.data);
                    sendingOTPSet(false);
                    Emitter.emit('SNACKBAR_NOTIFICATION', { message: `OTP send successfully!`, params: { variant: 'success' } });
                    if (phoneCode != '+91') {
                        navigate(`/brand-stores/sign-up/${cEncrypt(
                            contactNumber)}?${cEncrypt(phoneCode)}`);
                    }
                }
                if (res.data.status == 'fail') {
                    contactNumberVerificationMessageErrorSet(res.data.errors.contact_number[0]);
                    responseKeyDataSet(null);
                    sendingOTPSet(false)
                }
            }
            catch (e) {
                console.error(e)
            }
        } else {
            navigate(`/brand-stores/sign-up/${cEncrypt(
                contactNumber)}?${cEncrypt(phoneCode)}`);
            contactVerifiedSet(true)
            sessionStorage.setItem('contactVerified', true)
        }
        sendingOTPSet(false)
    }

    /**
     *
     * @name verifyReferenceCode
     * @alias verifyReferenceCode
     * @component
     * @exports verifyReferenceCode
     *
     * @description
     * Verify user has right to change profile number by validating
     * 4 digit reference code to his mobile number
    */
    const verifyReferenceCode = async () => {
        otpVerifiedLoaderSet(true)
        try {
            const res = await User.verifyOtp(
                {
                    key: responseKeyData,
                    value: otp
                }
            )

            if (res.data.status && res.data.status == 'success') {
                contactVerifiedSet(true);
                sessionStorage.setItem('contactVerified', true)
                // logContactLSQ();
                otpVerificationMessageErrorSet('');
                navigate(`/brand-stores/sign-up/${cEncrypt(
                    contactNumber)}?${cEncrypt(phoneCode)}`);
            }
            if (res.data.status == 'fail') {
                contactVerifiedSet(false);
                sessionStorage.setItem('contactVerified', false)
                otpVerificationMessageErrorSet(res.data.message);
            }
        }
        catch (e) {
            console.error(e)
        }
        otpVerifiedLoaderSet(false)
    }

    /**
     * @function logContactLSQ
     * @name logContactLSQ
     * @description
     * An async function to submit the users' enquiry via
     * contact widget and contact page form
     *
     * @async
     */

    const logContactLSQ = e => {
        submittingSet(true);

        let data = {
            first_name: '',
            last_name: '',
            phone: contactNumber,
            is_guest: 2,
            email: '',
            message: '',
        };
        Extras.submitEnquiryForm(data)
            .then(response => {
                if (response.data.status == "success") {
                    submitSuccessSet(true);
                    // Show success snackbar on contact page
                    // if(props.onSuccess) {
                    //     props.onSuccess();
                    // }

                    // Emitter.emit('SNACKBAR_NOTIFICATION', { message: `${'Your query has been submitted successfully!'}`, params: { variant: 'success' } });;
                    // contactNumberSet("");
                } else {
                    // Emitter.emit('SNACKBAR_NOTIFICATION', { message: `${'Something went wrong! Please try later.'}`, params: { variant: 'default' } });
                    submitSuccessSet(false);
                }
            })
            .finally(() => {
                submittingSet(false);
            });
            sessionStorage.setItem('data', contactNumber)
            sessionStorage.setItem('phonecode', phoneCode)
    };

    // Store verified contact in session storage
    // const [contactFromSession, contactFromSessionSet] = React.useState('');

    // React.useEffect(() => {
    //     const contactInput = contactNumber;

    //     if (contactVerified) {
    //         sessionStorage.setItem('data', contactInput)
    //     }

    //     contactFromSessionSet(sessionStorage.getItem('data'))
    // }, [contactVerified])

    return (
        <>
            {responseKeyData === null && <>
                <ValidatorForm
                    noValidate
                    onSubmit={e => {sendVerificationCode(); logContactLSQ()}}
                >

                    <InputLabel>Enter Phone Number<sup className='nota-bene'>*</sup></InputLabel>
                    <Box sx={{ mb: 2 }}>
                        <Box className={classes.formGrid}>
                            <Box sx={{ backgroundColor: '#F6F6F6' }}>
                                <SelectValidator
                                    value={phoneCode}
                                    name="country"
                                    variant="outlined"
                                    placeholder='Currency'
                                    required
                                    size="small"
                                    fullWidth
                                    onChange={(e) => {
                                        phoneCodeSet(e.target.value);
                                    }}
                                    inputProps={{
                                        id: "open-select"
                                    }}
                                    validators={[
                                        "required",
                                    ]}
                                    errorMessages={[
                                        "Required",
                                    ]}
                                >
                                    {countries.map((option, key) => (
                                        <MenuItem value={option.phone_code} key={key}>
                                            <img
                                                loading="lazy"
                                                width="18"
                                                src={`${option.flag_img}`}
                                                alt={option.iso}
                                            />{option.phone_code}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </Box>

                            <Box className='isRelative'>

                                <TextValidator variant="outlined"
                                    fullWidth
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                    size="small"
                                    value={contactNumber}
                                    onChange={e => { contactNumberSet(e.target.value); contactNumberVerificationMessageErrorSet('') }}
                                    // value={contactNumber}
                                    // onChange={e => { contactNumberSet(e.target.value); otpVerificationMessageErrorSet('') }}
                                    validators={[
                                        "required",
                                        "isNumber",
                                        `minStringLength:${phoneCode == '+91' ? '10' : '7'}`,
                                        `maxStringLength:${phoneCode == '+91' ? '10' : '13'}`,
                                        //`matchRegexp:${validatorRegEx(phoneCode == '+91' &&'mobile')}`
                                    ]}
                                    errorMessages={[
                                        "Contact number is required!",
                                        "Only Numeric values accepted!",
                                        `Minimum required length is ${phoneCode == '+91' ? '10' : '7'} digit`,
                                        `Max length can not exceed ${phoneCode == '+91' ? '10' : '13'} digit`,
                                        //"Contact number can not start with 0",
                                    ]}
                                />
                                {contactNumberVerificationMessageError && <Box textAlign='right' component='span' className={`isAbsolute text-danger`} sx={{ bottom: '-30px', fontSize: 12 }}>{contactNumberVerificationMessageError}</Box>}

                            </Box>
                        </Box>

                        {phoneCode == '+91' && <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>You will receive an OTP on your number</Box>}
                    </Box>

                    <Box>
                        <LoadingButton
                            color='primary'
                            variant='contained'
                            size="large"
                            type="submit"
                            loading={sendingOTP}
                            fullWidth
                            disableElevation
                        >
                            SIGNUP
                        </LoadingButton>
                    </Box>
                </ValidatorForm>

                <Box sx={{ mt: 1.25, textAlign: 'center', fontSize: '14px', }}>
                    <Typography color={`text.secondary`} variant='body1' component='p'>By continuing, you agree to our <Link style={{ textDecoration: 'underline', color: '#003189' }} to='/term-services'>Terms of Service</Link> <br /><Link style={{ textDecoration: 'underline', color: '#003189' }} to='/privacy-policy'>Privacy Policy</Link> and <Link style={{ textDecoration: 'underline', color: '#003189' }} to='/refund-policy'>Refund Policy</Link> </Typography>
                </Box>
            </>
            }


            {(phoneCode == '+91' && responseKeyData !== null && sessionStorage.getItem('phonecode') == '+91') &&
                <ValidatorForm
                    noValidate
                    onSubmit={e => {verifyReferenceCode(); logContactLSQ()}}
                >
                    <Box>
                        <InputLabel>Enter OTP<sup className='note-bene'>*</sup></InputLabel>
                        <TextValidator
                            variant="outlined"
                            fullWidth
                            name="phone"
                            // label="Enter OTP"
                            // placeholder="Enter OTP"
                            size="small"
                            value={otp}
                            onChange={e => { otpSet(e.target.value); otpVerificationMessageErrorSet(''); }}
                            validators={[
                                "required",
                                "isNumber",
                            ]}
                            errorMessages={[
                                "OTP is required!",
                                "Only Numeric values accepted!",
                            ]}
                        />
                        {otpVerificationMessageError && <Box component='p' sx={{ color: 'red', mt: 0, fontSize: 'small' }}>{otpVerificationMessageError}</Box>}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                            <Box>Please confirm OTP sent to <strong>{contactNumber}</strong></Box>
                            <Button type='text' color='primary' onClick={e => responseKeyDataSet(null)} style={{ color: 'blue', textDecoration: 'underline' }}>Change</Button>
                        </Box>

                        <OTPTimer setDelay={setDelay} delay={delay} />

                        <Box textAlign='center'>
                            <Typography variant="subtitle2" component="div" gutterBottom color={'text.secondary'}>Don’t received OTP?  <Button color='primary'
                                onClick={(e) => {sendVerificationCode(e); setDelay(delayResendBy)}}
                                sx={{ color: 'blue', textDecoration: 'underline' }}
                                disabled={delay !== 0 ? true : false }>
                                    Resend OTP
                                </Button>
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <LoadingButton
                            color='primary'
                            variant='contained'
                            size="large"
                            disabled={otp.length !== 4}
                            type="submit"
                            loading={otpVerifiedLoader}
                            fullWidth
                            disableElevation
                        >
                            VERIFY OTP
                        </LoadingButton>
                    </Box>
                    {/* {otpVerificationMessageError && <Box component='p' sx={{ color: 'red', mt: 0, fontSize: 'small' }}></Box>} */}
                </ValidatorForm>
            }
        </>

    )
}

export default BrandStoreStep1