import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import InputAdornment from '@mui/material/InputAdornment';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';
import { getDeliveryTermName, validatorRegEx } from "../../../helpers";
import LightTooltip from "../LightTooltip";
import { useSelector } from 'react-redux'
import { User } from "../../../api/resources/User"

export default function ChatBidAndOfferForm(props) {
    //console.log(props.creditDays)
    const formEl = React.useRef(null);
    const user = useSelector(state => state.userReducer.user);
    const tickSize = useSelector(state => state.commonReducer.globalSettings.tick_size);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const paymentTerms = useSelector(state => state.commonReducer.paymentTerms);
    const [price, priceSet] = React.useState();
    const [quantity, quantitySet] = React.useState();
    const [lifting, liftingSet] = React.useState();
    const [paymentMode, paymentModeSet] = React.useState('');
    const [paymentModeId, paymentModeIdSet] = React.useState('');
    const [deliveryTerm, deliveryTermSet] = React.useState(null);
    const [deliveryTermId, deliveryTermIdSet] = React.useState('2');
    const [comment, commentSet] = React.useState('');
    const [advance, advanceSet] = React.useState();
    const [balance, balanceSet] = React.useState(null);
    const [creditDaysNumbers, creditDaysNumbersSet] = React.useState('30');
    const [paymentDays, paymentDaysSet] = React.useState('');
    const [validityTime, validityTimeSet] = React.useState(30);
    const [showCommentField, showCommentFieldSet] = React.useState(false);
    const [modification, modificationSet] = React.useState(false);
    const [isValidPrice, setIsValidPrice] = React.useState(true);
    const [isValidQuantity, setIsValidQuantity] = React.useState(true);
    const [minValidQuantity, minValidQuantitySet] = React.useState(25)
    const [maxValidQuantity, maxValidQuantitySet] = React.useState(99999)
    // const [multiple25Error, multiple25ErrorSet] = React.useState(false);
    React.useEffect(() => {
        // if (deliveryTermId == 1) {
        //     // Yard Delivered as Delivery term only on Indian BioCoal #5
        //     if(props.product.origin_of_coal == 1 && (props.product.coal_type_id == 5 || props.product.coal_type_id_fk == 5)) {
        //         deliveryTermSet('Yard delivered')
        //     }

        //     // Factory Delivered as Delivery term only on Indian other than BioCoal #5
        //     if(props.product.origin_of_coal == 1 && (props.product.coal_type_id != 5 && props.product.coal_type_id_fk != 5)) {
        //         deliveryTermSet('Factory Delivered')
        //     }

        //     // Factory Delivered as Delivery term only on Indian BioCoal #5
        //     if(props.product.origin_of_coal == 2) {
        //         deliveryTermSet('Factory Delivered')
        //     }
        // }
        // if (deliveryTermId == 2) {
        //     // FOB as Delivery term only on Indian BioCoal #5
        //     if(props.product.origin_of_coal == 1 && (props.product.coal_type_id == 5 || props.product.coal_type_id_fk == 5)) {
        //         deliveryTermSet('FOB (free on board')
        //     }

        //     // Ex-Mine/Depo as Delivery term only on Indian other than BioCoal #5
        //     if(props.product.origin_of_coal == 1 && (props.product.coal_type_id != 5 && props.product.coal_type_id_fk != 5)) {
        //         deliveryTermSet('Ex-mine/depo')
        //     }

        //     // Ex-Port as Delivery term only on Imported Coal
        //     if(props.product.origin_of_coal == 2) {
        //         deliveryTermSet("Ex-port")
        //     }
        // }

        // if (price != "") {
        //     if (price % 25 == 0) {
        //         multiple25ErrorSet(false);
        //     } else {
        //         multiple25ErrorSet(true);
        //     }
        // }

        priceSet(props.price ? props.price : '');
        quantitySet(props.brandStore == true ? '' : props.quantity);
        liftingSet(props.liftingDays ? props.liftingDays : '');
        paymentModeIdSet(props.paymentTermId ? props.paymentTermId : '');
        advanceSet(props.ccPercentage ? props.ccPercentage : '10');
        creditDaysNumbersSet(props.creditDays == 0 ? 0 : props.creditDays ? props.creditDays : '30');
        paymentDaysSet(props.paymentDays != null ? props.paymentDays : props.liftingDays ? props.liftingDays : '')
        commentSet(props.specialRequest ? props.specialRequest : '');
    }, [props.price, props.quantity, props.liftingDays, props.paymentTermId, props.ccPercentage, props.creditDays, props.specialRequest, props.paymentDays, props.brandStore])

    React.useEffect(() => {
        deliveryTermSet(getDeliveryTermName(deliveryTermId, (props.product?.origin_of_coal.toLowerCase() == 'domestic' ? 1 : 2), !isNaN(props.product?.coal_type_id) ? props.product?.coal_type_id : props.product?.coal_type_id_fk, props.currency))
    }, [deliveryTermId])

    React.useEffect(() => {
        //advanceSet(null)
        props.paymentModeIdSet(paymentModeId)
        if (advance) {
            balanceSet(100 - parseInt(advance))
        }
        //creditDaysNumbersSet(null)
        if (paymentModeId == 1) {
            paymentModeSet('Advance')
        }
        if (paymentModeId == 2) {
            paymentModeSet('Letter of Credit')
        }
        if (paymentModeId == 3) {
            paymentModeSet('Credit')
        }
        if (paymentModeId == 4) {
            paymentModeSet('Cash & Carry');
            paymentDaysSet(lifting)
        }

    }, [paymentModeId, advance, lifting])

    React.useEffect(() => {
        if (quantity && props.quantityTickSize) {
            if (quantity % props.quantityTickSize === 0) {
                setIsValidQuantity(true)
            }
            if (quantity % props.quantityTickSize !== 0) {
                setIsValidQuantity(false)
            }
        }
    }, [quantity, props.quantityTickSize])

    // React.useEffect(() => {
    //     if (price && props.priceTickSize) {
    //         if (price % props.priceTickSize === 0) {
    //             setIsValidPrice(true)
    //         }
    //         if (price % props.priceTickSize !== 0) {
    //             setIsValidPrice(false)
    //         }
    //     }
    // }, [price, props.priceTickSize])

    React.useEffect(() => {
        if (props.quantity && props.quantityRangeMin && props.minimumOrderQuantity) {
            let calcMinQty = props.quantity * props.quantityRangeMin
            calcMinQty > props.minimumOrderQuantity ? minValidQuantitySet(calcMinQty) : minValidQuantitySet(props.brandStore == true ? 25 : props.minimumOrderQuantity)
        }
    }, [props.quantity, props.quantityRangeMin, props.minimumOrderQuantity, props.brandStore])

    React.useEffect(() => {
        if (props.quantity && props.quantityRangeMax && props.quantityRangeMax != '') {
            maxValidQuantitySet(props.brandStore == true ? 99999 : props.quantity * props.quantityRangeMax)
        }
    }, [props.quantity, props.quantityRangeMax, props.brandStore])

    const fetchUserReferences = async params => {
        var params = {
            page: 1,
            limit: 20,
            platform_type: 2
        };

        try {
            const res = await User.getReferenceContacts(params);
            if (res.data.status == "success") {
                props.userReferenceListSet(res.data.data.reference_contacts);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return <ValidatorForm
        ref={formEl}
        className="messageForm"
        onSubmit={e => props.onSubmit({
            price: price, quantity: quantity,
            lifting: lifting, paymentMode: paymentMode,
            paymentModeId: paymentModeId,
            advance: advance,
            balance: balance,
            creditDays: creditDaysNumbers,
            paymentDays: paymentDays,
            deliveryTerm: deliveryTerm,
            deliveryTermId: deliveryTermId,
            validityTime: validityTime,
            comment: comment
        })
        }
        noValidate
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="price" autoComplete="price" autoFocus
                    label={`${props.currency == 'INR' ? 'Price (including CESS)' : 'Price'}`} name="price" value={price}
                    validators={[
                        "required",
                        "isFloat",
                        `maxNumber:${parseInt(9999999)}`,
                        "isPositive",
                        //`matchRegexp:${validatorRegEx(tickSize == 25 ? "multiple25" : "endsWith", [0, 5])}`,
                        //`matchRegexp:${validatorRegEx("nonZero")}`
                    ]}
                    errorMessages={[
                        "Price is required.",
                        "Only numeric values accepted.",
                        "Price value can not be greater than 7 digits.",
                        "Price cannot be in negative numbers!",
                        //(props.reOffer || props.product_type == 1) ? `Please enter Offer Price as multiple of ${tickSize == 25 ? "25" : "5"}` : `Please enter Required Price as multiple of ${props.tickSize == 25 ? "25" : "5"}`,
                        //"Price value can not be Zero !!"
                    ]}
                    onChange={e => { priceSet(e.target.value); modificationSet(parseFloat(props.price) == parseFloat(e.target.value) ? false : true); }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            {props.currency}
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">/MT</InputAdornment>
                    }}
                />
                {/* {isValidPrice === false && (
                    <Typography
                        component="p"
                        color="error"
                        className="has__error"
                        style={{ margin: '0 14px' }}
                    >
                        Please enter Price as multiple of {props.priceTickSize}.
                    </Typography>
                )} */}

                {/* {multiple25Error && (
                    <Box mt={1}>
                        <Typography component="p" color="error" className="has__error">
                            {(props.reOffer || props.product_type == 1) ? "Please enter Offer Price as multiple of 25" : "Please enter Required Price as multiple of 25"}
                        </Typography>
                    </Box>
                )} */}
            </Grid>
            <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="quantity" autoComplete="quantity"
                    label="Quantity" name="quantity" value={quantity}
                    validators={[
                        "required",
                        "isNumber",
                        `maxNumber:${parseInt(9999999)}`,
                        `minNumber:${minValidQuantity}`,
                        `maxNumber:${maxValidQuantity}`
                    ]}
                    errorMessages={[
                        "Quantity is required.",
                        "Only numeric values accepted.",
                        "Quantity value can not be greater than 7 digits.",
                        `Quantity value cannot be less than ${minValidQuantity}MT`,
                        `Quantity value cannot be greater than ${maxValidQuantity}MT`
                    ]}
                    onChange={e => { quantitySet(e.target.value); modificationSet(props.quantity == e.target.value ? false : true); }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">MT</InputAdornment>
                    }}
                />
                {isValidQuantity === false && (
                    <Typography
                        component="p"
                        color="error"
                        className="has__error"
                        style={{ margin: '0 14px' }}
                    >
                        Please enter Quantity as multiple of {props.quantityTickSize}.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="lifting" autoComplete="lifting"
                    label="Lifting (No of Days)" name="lifting" value={lifting}
                    validators={["required", "isNumber", `matchRegexp:${validatorRegEx("nonZero")}`]}
                    errorMessages={["Lifting days is required.", "Only numeric values accepted.", "Lifting Days can not be Zero !!"]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={e => { liftingSet(e.target.value); modificationSet(props.liftingDays == e.target.value ? false : true); }}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectValidator native="true" required variant="outlined" size="small" fullWidth
                    value={paymentModeId} name="paymentModeId" label="Payment Mode"
                    validators={["required"]}
                    errorMessages={["Payment mode is required"]}
                    onChange={e => { paymentModeIdSet(e.target.value); modificationSet(props.paymentTermId == e.target.value ? false : true); }}
                >
                    {(paymentTerms && paymentTerms.length > 0) && paymentTerms.map((p, key) => (
                        <MenuItem
                            value={p.id}
                            key={key}
                        >
                            {p.payment_title}
                        </MenuItem>
                    ))}
                    {/* <MenuItem value='1'>Advance</MenuItem>
                    <MenuItem value='3'>Credit</MenuItem>
                    <MenuItem value='4'>Cash &amp; Carry</MenuItem> */}
                </SelectValidator>
            </Grid>

            {(paymentModeId == 4) && <Grid item xs={6}>
                <TextValidator variant="outlined" required fullWidth size="small" id="advance" autoComplete="advance"
                    label="Advance" name="Advance" value={advance}
                    validators={["required", "isNumber", `maxNumber:${parseInt(100)}`,]}
                    errorMessages={["Advance is required.", "Only numeric values accepted.", "Advance value can not be greater than 100",]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    onChange={e => {
                        advanceSet(e.target.value);
                        modificationSet(props.ccPercentage == e.target.value ? false : true);
                        if (e.target.value >= 0) {
                            balanceSet(100 - e.target.value)
                        }
                    }
                    }
                />
            </Grid>}
            {(paymentModeId == 4) && <Grid item xs={6}>
                <TextValidator variant="outlined" required fullWidth size="small" id="balance" autoComplete="balance"
                    label="Balance" name="Balance" value={balance}
                    validators={["required", "isNumber"]}
                    errorMessages={["Balance is required.", "Only numeric values accepted."]}
                    disabled
                    readOnly
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>}
            {(paymentModeId == 2 || paymentModeId == 3) && <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="creditDaysNumbers" autoComplete="creditDaysNumbers"
                    label="No. of Credit Days" name="No. of Credit Days" value={creditDaysNumbers}
                    validators={["required", "isNumber", `minNumber:${0}`]}
                    errorMessages={["No. of Credit Days is required.", "Only numeric values accepted.", "Credit Days can not be Negative !!"]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={e => { creditDaysNumbersSet(e.target.value); modificationSet(props.creditDays == e.target.value ? false : true); }}
                />
            </Grid>}
            {paymentModeId == 4 &&
                <Grid item xs={12}>
                    <TextValidator variant="outlined" required fullWidth size="small" id="paymentDaysNumbers" autoComplete="paymentDaysNumbers"
                        label="No. of Payment Days" name="No. of Payment Days" value={paymentDays}
                        validators={["required", "isNumber", `matchRegexp:${validatorRegEx("nonZero")}`]}
                        errorMessages={["No. of Payment Days is required.", "Only numeric values accepted.", "Payment Days can not be Zero !!"]}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Days</InputAdornment>
                        }}
                        onChange={e => { paymentDaysSet(e.target.value); modificationSet(props.paymentDays == e.target.value ? false : true); }}
                    />
                </Grid>}
            <Grid item xs={12}>
                <FormLabel component="legend">
                    {(props.reOffer || props.product_type == 1) ? "Offer Validity" : "Bid Validity"} <sup style={{ color: "red" }}>*</sup>
                    <LightTooltip
                        arrow
                        title={`The time after which the ${(props.reOffer || props.product_type == 1) ? "offer" : "bid"} will automatically be withdrawn from the buyer`}
                    >
                        <InfoIcon fontSize='24' />
                    </LightTooltip>
                </FormLabel>
                <SelectValidator native="true" required variant="outlined" size="small" fullWidth
                    value={validityTime} name="validityTime"
                    validators={["required"]}
                    errorMessages={[((props.reOffer || props.product_type == 1) ? "Offer Validity" : "Bid Validity") + " is required"]}
                    onChange={e => { validityTimeSet(e.target.value); }}
                >
                    <MenuItem value={60}>60 Minutes</MenuItem>
                    <MenuItem value={30}>30 Minutes</MenuItem>
                    <MenuItem value={15}>15 Minutes</MenuItem>
                    <MenuItem value={5}>5 Minutes</MenuItem>
                    <MenuItem value={3}>3 Minutes</MenuItem>
                    <MenuItem value={1}>1 Minute</MenuItem>
                </SelectValidator>
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset" color="primary">
                    <FormLabel component="legend">Delivery Term <sup style={{ color: "red" }}>*</sup></FormLabel>
                    <RadioGroup aria-label="deliveryTermId" value={deliveryTermId} name="deliveryTermId" onChange={e => {
                        deliveryTermIdSet(e.target.value);
                    }}
                        style={{ display: "inline" }}
                        defaultValue={deliveryTermId}
                    >
                        <FormControlLabel
                            value="1"
                            control={<Radio size="small" color="primary" />}
                            label={getDeliveryTermName(1, (!isNaN(props.product?.origin_of_coal) ? props.product?.origin_of_coal : (props.product?.origin_of_coal.toLowerCase() == 'domestic' ? 1 : 2)), !isNaN(props.product?.coal_type_id) ? props.product?.coal_type_id : props.product?.coal_type_id_fk, props.currency)}
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio size="small" color="primary" />}
                            label={getDeliveryTermName(2, (!isNaN(props.product?.origin_of_coal) ? props.product?.origin_of_coal : (props.product?.origin_of_coal.toLowerCase() == 'domestic' ? 1 : 2)), !isNaN(props.product?.coal_type_id) ? props.product?.coal_type_id : props.product?.coal_type_id_fk, props.currency)} />
                        {/* {(props.product.origin_of_coal == 1 && (props.product.coal_type_id == 5 || props.product.coal_type_id_fk == 5)) && <>
                            <FormControlLabel value="1" control={<Radio size="small" color="primary" />} label="Yard delivered " />
                            <FormControlLabel value="2" control={<Radio size="small" color="primary" />} label="FOB (free on board)"/>
                        </>}
                        {(props.product.origin_of_coal == 1 && (props.product.coal_type_id != 5 && props.product.coal_type_id_fk != 5)) && <>
                            <FormControlLabel value="1" control={<Radio size="small" color="primary" />} label="Factory Delivered" />
                            <FormControlLabel value="2" control={<Radio size="small" color="primary" />} label="Ex-mine/depo"/>
                        </>}
                        {(props.product.origin_of_coal == 2) && <>
                            <FormControlLabel value="1" control={<Radio size="small" color="primary" />} label="Factory Delivered" />
                            <FormControlLabel value="2" control={<Radio size="small" color="primary" />} label="Ex-port" />
                        </>} */}
                    </RadioGroup>
                </FormControl>
            </Grid>
            {/* {!showCommentField && <Button type='button' color="primary" onClick={e => showCommentFieldSet(true)}>+ Add Special Comments</Button>} */}
            {/* {showCommentField && */}
            <Grid item xs={12}>
                <TextValidator variant="outlined" fullWidth size="small" rows="2" multiline={true}
                    label="Additional Comments:"
                    id="comment"
                    name="comment"
                    value={comment}
                    onChange={e => { commentSet(e.target.value); modificationSet((props.specialRequest == null && e.target.value.length == 0) ? false : (props.specialRequest != null && props.specialRequest == e.target.value) ? false : true); }}
                    inputProps={{
                        maxlength: globalSetting?.settings_special_comment_length
                    }}
                    helperText={<Box align="right">Words: {comment.length}<span style={{ color: "#2264e9" }}>/{globalSetting?.settings_special_comment_length}</span></Box>}
                />
            </Grid>
            {/* } */}

            {/* {(props.product_type == 1 && !props.reBid && (paymentModeId == 1 || paymentModeId == 4)) &&
                <Box sx={{ fontSize: 10, color: '#2a2a2a', borderBottom: '1px solid #ddd', px: 1, py: .6, backgroundColor: '#499c2233', borderRadius: '4px', ml: 2 }}><strong>Please Note</strong>: If your Offer is accepted, a quote will automatically be sent to the buyer for approval.</Box>
            }

            {(props.product_type == 2 && !props.reOffer && paymentModeId == 3) &&
                <Box sx={{ fontSize: 10, color: '#2a2a2a', borderBottom: '1px solid #ddd', px: 1, py: .6, backgroundColor: '#499c2233', borderRadius: '4px', ml: 2 }}><strong>Please Note</strong>: If your bid gets accepted and your company reference is acceptable to the seller, they shall send the quote. The received Quote would be Awarded automatically.</Box>
            } */}

            {(props.product.user_is_paid == true && props.product.created_at_paid == true && (user.ad_credits > quantity) && modification == true) &&
                <Box sx={{ fontSize: 12, color: '#2a2a2a', borderBottom: '1px solid #ddd', px: 1, py: .6, backgroundColor: '#d32f2f80', borderRadius: '4px', ml: 2 }}><strong>This deal is no longer compulsory. Any modifications need counterparty's approval.</strong></Box>
            }

            <Grid item xs={12}>
                <Button
                    aria-label="Submit"
                    fullWidth
                    variant='contained'
                    type="submit"
                    color="primary"
                    disabled={isValidPrice === false || isValidQuantity === false || props.sendingMessage}
                    //disabled={props.sendingMessage}
                    onClick={e => {
                        fetchUserReferences()
                        e.stopPropagation()
                        e.preventDefault()
                        formEl.current.submit()
                    }}
                >
                    {props.sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}{(props.reBid || props.reOffer) ? "SUBMIT" : (props.product_type == 1) ? 'SEND YOUR OFFER' : 'SEND YOUR BID'}
                </Button>
            </Grid>
        </Grid>
    </ValidatorForm>
}
