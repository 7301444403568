import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    Card,
    Grid,
    IconButton,
    Button,
    TextField
} from "@mui/material";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import AnimatedSuccess from "../partials/AnimatedSuccess";
import Slide from "@mui/material/Slide";
import { User } from "../../api/resources/User";
import dateformat from "dateformat";
import ThemeButton from "../partials/ThemeButton";
import loadable from "@loadable/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import RatingOnDeals from "../partials/ReviewRatingOnDeals";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    resetContainer: {
        padding: theme.spacing(1)
    },
    title: {
        alignText: "center"
    },
    buttonLoaderWrapper: {
        //margin: theme.spacing(1),
        position: "relative"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    ratingData: {
        display: "grid",
        gridTemplateColumns: "1fr",
        minWidth: '450px',
        gridGap: ".5rem",
        [theme.breakpoints.down('md')]: {
            // gridTemplateColumns: "1fr",
            minWidth: 'auto',
        }
    },
    indigo: {
        backgroundColor: "#3F51B5"
    },
    cyan: {
        backgroundColor: "#00838F",
        "&:hover": {
            backgroundColor: "#00838F"
        }
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
    },
    fsXS: {
        fontSize: 12
    },
    grayColor: {
        color: '#0009'
    },
    gridCol2ProductQTY: {
        display: "grid",
        gridTemplateColumns: "1fr 100px",
        alignItems: 'center',
        gridGap: '5px'
    },
    btnBadge: {
        backgroundColor: '#f2f3f5',
        borderRadius: '18px',
        fontSize: 12,
        display: 'inline-block',
        textAlign: 'center',
        padding: '3px 10px'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewRatingDialog(props) {
    const classes = useStyles();
    const ratingData = props?.rating;
    const user_mode = useSelector(state => state.userReducer.user_mode);
    const [open, setOpen] = React.useState(false);
    const [ratingErrorMsg, setRatingErrorMsg] = React.useState(false);
    const [awardId, setAwardId] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    //const [userName, setUserName] = React.useState("");
    const [product, setProduct] = React.useState({});
    const [quotation, setQuotation] = React.useState({});
    const [ratingType, ratingTypeSet] = React.useState('');
    const [ratingSuccess, setRatingSuccess] = React.useState(false);
    //const [remarks, setRemarks] = React.useState("");

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [starRatingValues, setStarRatingValues] = useState([]);
    const setStarValues = (key, value) => {
        let tempStarRatingValues = starRatingValues;
        tempStarRatingValues[key] = value;
        setStarRatingValues(tempStarRatingValues);
    };

    //   Post rating
    const submitRating = (e) => {
        setRatingSuccess(false);
        const errorMsg = starRatingValues.filter(e => {
            return e.rating_value != 0;
        }).length > 2;

        if (errorMsg) {
            setRatingErrorMsg(false);
            User.postReviewRating({
                award_id: awardId,
                type: ratingType == "sellerRateModal" ? 1 : 2,
                questions_rating_data: JSON.stringify(starRatingValues),
            })
                .then(response => {
                    if (response.data.status == "success") {
                        setRatingSuccess(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {

                });
        } else {
            setRatingErrorMsg(true);
        }
    };

    useEffect(() => {
        if (ratingData) {
            handleClickOpen("reviewRatingModal");
            setProduct(ratingData?.award?.quotation?.product);
            setQuotation(ratingData?.award?.quotation);
            setAwardId(ratingData?.award_id);
            setCompanyName(ratingData.rating_from?.name);
            //setRemarks(props.remarks);
            //setUserName(props?.pendingRatingData[0]?.company?.user?.fullName);
            if (ratingData?.award?.quotation?.product?.product_type == 2) {
                ratingTypeSet('buyerRateModal');
            }
            if (ratingData?.award?.quotation?.product?.product_type == 1) {
                ratingTypeSet('sellerRateModal');
            }
        }
    }, [ratingData])

    return (
        <div>
            <Dialog
                open={open === "reviewRatingModal"}
                keepMounted
                maxWidth="xs"
                disableEscapeKeyDown={true}
                TransitionComponent={Transition}
                className={classes.ratingDialogSize}
            // onClose={e => {
            //     handleClose(e);
            // }}
            >
                {ratingSuccess && (
                    <>
                        <DialogTitle
                            align="center"
                            className={`${"text-white"}`}
                        >
                            <AnimatedSuccess />
                        </DialogTitle>
                        <DialogContent>
                            <Box textAlign="center" mb={1}>
                                <Typography variant="h5">Great!!</Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle1">
                                    {ratingType == "sellerRateModal" ?
                                        "You have successfully rated this Buyer."
                                        :
                                        " You have successfully rated this Seller."
                                    }
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <ThemeButton
                                    style={{
                                        background: "#4CAF50",
                                        color: "#fff"
                                    }}
                                    type="button"
                                    onClick={e => {
                                        handleClose(e);
                                        setRatingSuccess(false);
                                    }}
                                    component={Link}
                                    to="/dashboard/my-listings"
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                >
                                    Done
                                </ThemeButton>
                            </Box>
                        </DialogContent>
                    </>
                )}
                {!ratingSuccess &&

                    <div className={`${classes.root} ${ratingType == "sellerRateModal" ? classes.indigo : classes.cyan} text-white`}>
                        <Grid container px={2} mb={2}>
                            <Grid item sm={12}>
                                <Box
                                    sx={{ textAlign: 'center' }}
                                    py={1}
                                >
                                    <Typography variant="h6" className={classes.title}>
                                        Review Ratings
                                        {/* {ratingType == "sellerRateModal" ? "Rate this Buyer!!" : "Rate this Seller!!"} */}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} mb={1}>
                                <Card>
                                    <Box mb={1} px={1} pt={1}>
                                        <Box>
                                            <Box className={classes.gridCol2}>
                                                <Typography variant="body1" style={{ marginRight: "5px" }}>
                                                    {quotation?.coal_type?.type}  <Typography variant="body2" className={classes.btnBadge}>
                                                        {<small>
                                                            {(quotation?.coal_type?.type === 'Steam Coal' && quotation?.coal_type?.type !== null) && product?.additional_parameter + ' CV'}

                                                            {(quotation?.coal_type?.type === 'Coking CoaL' && quotation?.coal_type?.type !== null) && product?.additional_parameter + ' Ash'}

                                                            {(quotation?.coal_type?.type === 'PET Coke' && quotation?.coal_type?.type !== null) && product?.additional_parameter + '% Sulphur'}

                                                            {(quotation?.coal_type?.type === 'Metallurgical Coke' && quotation?.coal_type?.type !== null) && product?.additional_parameter + ' Ash'}

                                                            {(quotation?.coal_type?.type === 'Bio Coal/Waste' && quotation?.coal_type?.type !== null) && product?.additional_parameter + ' CV'}
                                                        </small>
                                                        }
                                                    </Typography>
                                                </Typography>
                                                <Typography variant="body2" className={`${classes.fsXS} ${classes.grayColor} text-right`}>
                                                    Quote ID: {quotation?.id}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.gridCol2ProductQTY}>
                                                <small><strong>
                                                    <img style={{ marginRight: "5px" }} src="/images/seller-page-user-xs-icon-ship.svg" width="10" />
                                                    {
                                                        quotation?.vessel_attribute?.vessel?.vessel_name !== null ?
                                                            quotation?.vessel_attribute?.vessel?.vessel_name : product?.mining_company != null ? product.mining_company?.name : (
                                                                product?.mine !== null ? product?.mine : product?.manufacturer_name
                                                            )

                                                    } {' '}
                                                    {(product?.coal_type_id_fk !== null && product?.coal_type_id_fk === 3) ? product?.made_from : (product?.made_from === null && product?.coal_type_id_fk === 3) ? 'N/A' : product?.made_from}

                                                    {(product?.coal_type_id_fk !== null && (product?.coal_type_id_fk === 4 || product?.coal_type_id_fk === 5)) && product?.manufacturer_name} - {' '}

                                                    {quotation?.product_country?.name}
                                                </strong></small>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Typography variant="body2" className={classes.grayColor}>
                                                        <small>{dateformat(quotation?.created_at, 'dd-mm-yyyy')}</small>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <img style={{ marginRight: "5px" }} src="/images/coal-cart-sm.svg" alt="" width="10" />
                                                <Typography component='strong' variant='subtitle2'>
                                                    <small>{quotation?.quantity} MT</small>
                                                </Typography>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item sm={12}>
                                <Card>
                                    <Box px={1} pt={1}>
                                        {/* User/Company  */}
                                        <Box display='inline' textAlign='center'>
                                            <img src="/images/user-icon.svg" width="50" alt="user icon" style={{ display: 'block', margin: 'auto' }} />{' '}
                                            <Box mb={.5}>
                                                {/* <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                                    {props.userName}
                                                </Box> */}
                                                <Box sx={{ color: '#6a6a6a', fontSize: '12px', textTransform: 'capitalize' }}>
                                                    {companyName}
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Questions and ratings */}
                                        <ValidatorForm onSubmit={e => { submitRating() }}>
                                            <Grid container>
                                                {props.rewards.length > 0 && props.rewards && props.rewards.map(
                                                    (que, key) => (
                                                        <React.Fragment key={key}>
                                                            {/* Questions and star fields */}
                                                            {key === 0 && (
                                                                <Grid item sm={12}>
                                                                    <Box sx={{ fontWeight: 'bold' }}>Q1. {que.question.question}</Box>

                                                                    <Box mt={.5} mb={.5}>
                                                                        <RatingOnDeals
                                                                            name={`ratingSeller${key}`}
                                                                            onChange={e => {
                                                                                setStarValues(key, e);
                                                                                setRatingErrorMsg(false);
                                                                            }}
                                                                            rewardId={que.id}
                                                                            value={que.point}
                                                                            question={que.question}
                                                                            ratingErrorMsg={ratingErrorMsg}
                                                                            size="medium"
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}

                                                            {key === 1 && (
                                                                <Grid item sm={12}>
                                                                    <Box sx={{ fontWeight: 'bold' }}> Q2. {que.question.question}</Box>

                                                                    <Box mt={.5}>
                                                                        <RatingOnDeals
                                                                            name={`ratingSeller${key}`}
                                                                            onChange={e => {
                                                                                setStarValues(key, e);
                                                                                setRatingErrorMsg(false);
                                                                            }}
                                                                            rewardId={que.id}
                                                                            question={que.question}
                                                                            value={que.point}
                                                                            ratingErrorMsg={ratingErrorMsg}
                                                                            size="medium"
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}

                                                            {key === 2 && (
                                                                <Grid item sm={12}>
                                                                    <Box sx={{ fontWeight: 'bold' }}>Q3. {que.question.question}</Box>

                                                                    <Box mt={.5}>
                                                                        <RatingOnDeals
                                                                            name={`ratingSeller${key}`}
                                                                            onChange={e => {
                                                                                setStarValues(key, e);
                                                                                setRatingErrorMsg(false);
                                                                            }}
                                                                            rewardId={que.id}
                                                                            value={que.point}
                                                                            question={que.question}
                                                                            ratingErrorMsg={ratingErrorMsg}
                                                                            size="medium"
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                )}

                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        multiline
                                                        variant="outlined"
                                                        className="w-100"
                                                        placeholder="Feedback (Optional)"
                                                        rows={3}
                                                        name="remarks"
                                                        value={remarks}
                                                        onChange={e => setRemarks(e.target.value)}
                                                        margin="normal"
                                                    />
                                                </Grid> */}

                                                <Grid item sm={12} mb={2}>
                                                    <span className={classes.buttonLoaderWrapper}>
                                                        <Button
                                                            fullWidth
                                                            color="primary"
                                                            align="center"
                                                            size="small"
                                                            variant="contained"
                                                            type="submit"
                                                            className={ratingType == "sellerRateModal" ? classes.indigo : classes.cyan}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </div >
                }

            </Dialog>
        </div>
    );
}
