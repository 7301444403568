import { Box } from "@mui/material"
import React from "react"
import SignUpForm from './SignUpForm'
import { useParams } from "react-router";
import { cDecrypt } from '../../helpers'
import wave from "../../images/waves2.png";
import { useSelector } from 'react-redux'
import BrandStoreSignup from "./BrandStoreSignup";
// import OtpVerification from './OtpVerification'
// import MultiStep from './multistep/MultiStep'

/**
 *
 * @name BrandStoreSignupV2
 * @alias BrandStoreSignupV2
 * @component
 * @exports BrandStoreSignupV2
 *
 * @description
 * BrandStoreSignupV2 Form and related Actions
 */

export default function BrandStoreSignupV2(props) {
    let routeParams = useParams();
    const countries = useSelector(state => state.commonReducer.countries);
    
    // let phoneCode = cDecrypt((props.location?.search).slice(1));
    // let contact_number = cDecrypt(routeParams?.contact_number);
    let phoneCode = "+91"
    let contact_number = cDecrypt(routeParams?.contact_number);
    if(window.location?.search != '')
    {
        phoneCode = cDecrypt((window.location?.search).slice(1));
    }
    
    const [countryId, setCountryId] = React.useState(null)

    React.useEffect(() => {
        if (phoneCode) {
            setCountryId(countries.find(c => c.phone_code == phoneCode).id)
        }
    }, [phoneCode]);

    return (
        <Box sx={{
            position: 'relative',
            // fontSize: "3rem",
            paddingTop: 4,
            paddingBottom: "118px",
            backgroundColor: '#fff',
            backgroundImage: `url(${wave})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: { sm: '100% 175px', xs: 'inherit' },

        }} py={3}>
            <BrandStoreSignup contact_number={contact_number} phoneCode={phoneCode} countryId={countryId} />
            {/* {!tempUser &&
            <SignUpForm setTempUser={setTempUser} />
        }
        {tempUser &&
            <OtpVerification tempUser={tempUser} />
        } */}
        </Box>
        // <MultiStep />
    )
}