import React from 'react';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';

export default function OnLoadTransition(props) {

    if (props.transition === "slide") {
        return (
            <Slide direction="up" in={true} mountOnEnter unmountOnExit {...props}>
                <div className="onLoadTransition">{props.children}</div>
            </Slide>
        );
    }

    if(props.transition === "fade") {
        return (
            <Fade in={true} mountOnEnter unmountOnExit {...props}>
                <div className="onLoadTransition">{props.children}</div>
            </Fade>
        );
    }

    if(props.transition === "grow") {
        return (
            <Grow in={true} mountOnEnter unmountOnExit {...props}>
                <div className="onLoadTransition">{props.children}</div>
            </Grow>
        );
    }

    if(props.transition === "zoom") {
        return (
            <Zoom in={true} mountOnEnter unmountOnExit {...props}>
                <div className="onLoadTransition">{props.children}</div>
            </Zoom>
        );
    }

    return <>{props.children}</>;
}