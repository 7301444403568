import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from "react-router-dom";
import { cEncrypt } from '../../helpers';

const useStyles = makeStyles(theme => ({
    item: {
        flexShrink: 0,
        scrollSnapAlign: 'start',
    },
}))
export default function RegisterCard(props) {
  const classes = useStyles();
  return (
    <Card
        variant="outlined"
        className={classes.item}
        sx={{ textAlign: 'center', mr: 1, background: '#2959ba14', '&:hover': { boxShadow: '1px 10px 16px #ddd' } }}>
        <CardContent sx={{ fontSize: '14px',}}>
            <Box sx={{ mb: 2 }}>To see more {props.label} <br /> register/login yourself.</Box>

            <Button
                color="primary"
                sx={{borderRadius: '20px', background: '#2959BA33',}}
                component={Link}
                to={`/sign-up/${cEncrypt(
                    0)}`}
                endIcon={
                    <ChevronRightIcon />
                }
            >
                Register for free !
            </Button>
        </CardContent>
    </Card>
  )
}
