import { Box } from '@mui/system';
import React from 'react'

export default function OTPTimer(props)
{
    const minutes = Math.floor(props.delay / 60);
    const seconds = Math.floor(props.delay % 60);

    React.useEffect(() =>
    {
        const timer = setInterval(() =>
        {
            props.setDelay(props.delay - 1);
        }, 1000);

        if (props.delay === 0) {
            clearInterval(timer);
        }

        return () =>
        {
            clearInterval(timer);
        };
    }, [props.delay])


    return (
        <>
            {props.delay !== 0 &&
                <Box sx={{textAlign: 'center', my: .75}}>
                    {minutes}{minutes !== 0 ? ' Min': 0}:{seconds}{seconds !== 0 ? ' Sec': 0}
                </Box>
            }
        </>
    )
}
