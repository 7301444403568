import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import userReducer from './userReducer';
import consoleUserReducer from './consoleUserReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
	commonReducer,
	userReducer,
	consoleUserReducer,
	notificationReducer
})