import axios from 'axios';
import { config } from '../config';
import { getUserToken } from '../helpers'
import Emitter from '../services/emitter';
var uaParser = require('ua-parser-js');

const HOST_URL = config.api_url;

let headers = {
    'Content-Type': 'application/json',
    // 'Accept-Encoding': 'gzip'
}

// var userToken = getUserToken()
// if(userToken) {
//     headers.token = userToken
// }

// configure an axios instance
const instance = axios.create({
    baseURL: HOST_URL,
    headers: headers
});


instance.interceptors.request.use(function (config) {
    const userToken = getUserToken();
    // const firebase_web_token = localStorage.getItem('firebase_web_token')
    var params = {}
    // params['firebase_web_token'] = firebase_web_token
    if(config.params) {
        params = config.params
    }
    if(userToken) {
        params['token'] = userToken
        config.headers.token = `${userToken}`;
    }

    params['platform_type'] = 2

    var parsedUA = new uaParser()

    params['cs_ua_source'] = {
        'browser': parsedUA.getBrowser(),
        'device': parsedUA.getDevice(),
        'os': parsedUA.getOS(),
        // 'engine': uaparsed.getEngine(),
        // 'cpu': uaparsed.getCPU(),
    }

    config.params = params

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    if(!window.location.pathname.includes("console") && response.data.status == 'fail' && response.data.over_shoot_quantity !== undefined) {
        Emitter.emit('OVERSHOOT_QUANTITY', response.data)
    }

    if(response.data.success == false && (response.data.hasOwnProperty('auth') && response.data.auth == false))
    {
        localStorage.clear();
        window.location = '/login';
    }

    return response;
}, function (error) {
    if(error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.reload(true);
    }
    return Promise.reject(error);
});

export default instance;
