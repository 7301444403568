import resource from 'resource-axios';
import axios from '../base';
import { config } from '../../config';
import { makeQueryString } from '../../helpers'


export const Product = resource(config.api_url, {

    get: (params, mode) => {
        return axios.get(`${config.api_url}/rfqmaplisting/${mode}${params}`)
	},

	getCountries: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/getallcountrylist?${queryString}`)
	},

    getCoalTypes: () => {
        return axios.get(`${config.api_url}/allcoaltype`)
    },

	getMineCompanies: () => {
		return axios.get(`${config.api_url}/fetchminecompanies`)
	},

	getGlobalSettings: () => {
		return axios.get(`${config.api_url}/global-settings`)
	},

	getPaymentTerms: () => {
		return axios.get(`${config.api_url}/available-payment-terms`)
	},

	// Coal Requests & Deals
	homepageCoalRequestList: () => {
		return axios.get(`${config.api_url}/homepagecoalrequestlist`)
	},

	// RFQ Form home data
	rfqForm: (params) => {
		if(!params)
		{
			params = {}
		}
		return axios.get(`${config.api_url}/getrfqdetails`, params)
    },

    fetchMineCompanies: () => {
        return axios.get(`${config.api_url}/fetchminecompanies`)
    },

	fetchEkycIndustries: () => {
        return axios.get(`${config.api_url}/industry-list`)
    },

	// Get city from state data
	getPostCity: (params) => {
		return axios.post(`${config.api_url}/checkcityexist`, params)
	},


	// Get recommended data
	recommendedProducts: (params) => {
		return axios.post(`${config.api_url}/recommended-products`, params)
	},

	// Get recommended data
	hotProducts: (params) => {
		return axios.post(`${config.api_url}/hot-products`, params)
	},

	getCoalPorts: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/getallcoalportlist?${queryString}`)
	},

	getAllCities: () => {
		return axios.get(`${config.api_url}/getallcitystate`)
	},

	getAllVessels: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/allvessellist?${queryString}`)
	},

	getStates: (params) => {
		var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/getallstatelist?${queryString}`)
	},

	// Get vessel address from port data
	getVesselByCountry: (params) => {
		return axios.post(`${config.api_url}/getvesselbycountryid`, params)
	},

	// Get seller landing page card
		sellerLP: (params) => {
		var queryString = makeQueryString(params)
		return axios.get(`${config.api_url}/share-content${queryString}`)
	},

	// Get vessel address from port data
	getPortAddress: (params) => {
		return axios.post(`${config.api_url}/getportdetailsbyid`, params)
	},

	// Subscribe newsletter from seller landing page
	subscribeNewsLetter: (params) => {
		return axios.post(`${config.api_url}/subscribe-for-newsletter`, params)
	},

	// Get payment terms  data
	getPaymentChildren: (params) => {
		return axios.get(`${config.api_url}/getchildpaymentterm`)
	},

	// Post RFQ form data
	postRFQform: (params) => {
		return axios.post(`${config.api_url}/buyerrfqsaving`, params)
	},

	// Post new RFQ form data
	postProductSavingForm: (params) => {
		return axios.post(`${config.api_url}/product-saving-v2`, params)
	},

	// Get best requirements/listing and offers
	bestListingOfferRFQ: (params) => {
		return axios.post(`${config.api_url}/best-buyerseller`, params)
	},

	getListingMatchingSuggestion: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/product-listing-matching-suggestions?${queryString}`)
	},

    myDealsv2: (params) => {
        return axios.post(`${config.api_url}/mydeals-v2`, params)
    },
    tradeHistory: (params) => {
        return axios.post(`${config.api_url}/my-trade-history`, params)
    },


	virtualOffice: {
		inbox: (params) => {
			return axios.post(`${config.api_url}/inboxchatlist`, params)
		},
		newInbox: (params) => {
			return axios.post(`${config.api_url}/inbox-chat-list-v2`, params)
		},
		myListings: (params) => {
			return axios.post(`${config.api_url}/mylistings`, params)
		},
		myListingsV2: (params) => {
			return axios.post(`${config.api_url}/my-listings-v2`, params)
		},
		myDeals: (params) => {
			return axios.post(`${config.api_url}/mydeals`, params)
		},
		closedDeals: (params) => {
			return axios.post(`${config.api_url}/closeddeals`, params)
		},
		rfqChatList: (params) => {
			return axios.post(`${config.api_url}/rfqchatlist`, params)
		},
		ratedDealsList: (params) => {
			return axios.post(`${config.api_url}/rated-deals`, params)
		},
		inboxChatList: (params) => {
			return axios.post(`${config.api_url}/inboxchatlist`, params)
		},
		getCompanyRatings: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.api_url}/get-user-rated-list?${queryString}`)
		},
		getCompanyRatingsV2: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.api_url}/get-user-rated-list-v2?${queryString}`)
		},
		benchmarkList: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.api_url}/benchmark/benchmark-list?${queryString}`)
		},
		benchmarkDetail: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.api_url}/benchmark/benchmark-details?${queryString}`)
		},
	},

	getQuotationDetails: (params) => {
		return axios.post(`${config.api_url}/fetchrfqforquotation`, params)
	},

	sendQuotation: (params) => {
		return axios.post(`${config.api_url}/sendrfqquotation`, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
	},

	sendQuotationV2: (params) => {
		return axios.post(`${config.api_url}/send-quotation-v2`, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
	},

	viewQuotation: (params) => {
		return axios.post(`${config.api_url}/viewquotation`, params)
	},

	viewProductDetails: (params) => {
		return axios.post(`${config.api_url}/view-requirement-info`, params)
	},

	awardQuotation: (params) => {
		return axios.post(`${config.api_url}/awardquotation`, params)
	},

	rejectQuotation: (params) => {
		return axios.post(`${config.api_url}/rejectquotation`, params)
	},

	rejectQuotationReasons: (params) => {
		return axios.get(`${config.api_url}/quotation-reject-reasons`, params)
	},

	bidOfferRejectReasons: (params) => {
		return axios.get(`${config.api_url}/bid-offer-reject-reasons`, params)
	},

	rejectQuoteAddNegativeList: (params) => {
		return axios.post(`${config.api_url}/negative-list/reject-quote-add-negative-list`, params)
	},

	closeDealQuotation: (params) => {
		return axios.post(`${config.api_url}/skiprating`, params)
	},

	getsellerListingMap: (params) => {
        // return axios.post(`${config.api_url}/sellerlisting`, params)
		return axios.get(`${config.api_url}/rfqmaplisting/2`, params)
	},

	buyerListingMap: (params) => {
		return axios.get(`${config.api_url}/rfqmaplisting/1`, params)
	},

	expireListing: (params) => {
		return axios.post(`${config.api_url}/expirelisting`, params)
	},

	rePostListing: (params) => {
		return axios.post(`${config.api_url}/reposting-product-saving`, params)
	},

	changeQuantityAction: (params) => {
		return axios.post(`${config.api_url}/updateproductlistingquantity`, params)
	},

	storeAddressRfq: (params) => {
		return axios.post(`${config.api_url}/storeaddress`, params)
	},

	getGroups: (params) => {
        var queryString = makeQueryString(params)
		return axios.post(`${config.api_url}/getgroups${queryString}`)
	},

	allTempAddressForRfq: (params) => {
        var queryString = makeQueryString(params)
		return axios.post(`${config.api_url}/getalltempaddress${queryString}`)
	},

	getMultiplePort: (params) => {
		return axios.post(`${config.api_url}/getvesseldetailsforrfq`, params)
	},

	uploadMediaForRfq: (params) => {
		return axios.post(`${config.api_url}/uploadmediaforrfq`, params)
	},

	storeNewVesselAction: (params) => {
		return axios.post(`${config.api_url}/savevessel`, params)
	},

	updateListingPrice: (params) => {
		return axios.post(`${config.api_url}/updatelistingprice`, params)
	},

	// Get last product info
	getLastProductInfo: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.post(`${config.api_url}/get-last-product-info?${queryString}`)
	},

	getMutualReferenceList: (params) => {
		return axios.post(`${config.api_url}/get-mutual-reference-list`, params)
	},

	getCommMutualReferenceList: (params) => {
		return axios.post(`${config.api_url}/communication-mutual-reference-list`, params)
	},

	//
	updateKycAddress: (params) => {
		return axios.post(`${config.api_url}/update-kyc-address`, params)
	},

    coalOriginHavingPrices: (params) => {
        var queryString = ''
        if(params)
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
		return axios.get(`${config.api_url}/coal-origin-having-prices?${queryString}`)
	},

	fetchForAutoWithdrawRejectListing: (params) => {
		return axios.post(`${config.api_url}/fetch-for-auto-withdraw-and-reject-listing`, params)
	},

	updateForAutoWithdrawRejectListing: (params) => {
		return axios.post(`${config.api_url}/update-for-auto-withdraw-and-reject-listing`, params)
	},

	getAllOpenMarketListings: (params) => {
		var queryString = ''
		if (params) {
			queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		}
		return axios.get(`${config.api_url}/get-all-open-market-listing?${queryString}`)
	},

	updateListingPrice: (params) => {
        return axios.post(`${config.api_url}/update-listing-price`, params)
    },

	updateListingStatus: (params) => {
        return axios.post(`${config.api_url}/update-listing-status`, params)
    },

}, axios);
