import React from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Slide from "@mui/material/Slide";
import makeStyles from '@mui/styles/makeStyles'
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery';
// import CardActions from "@mui/material/CardActions";
// import Typography from "@mui/material/Typography";
import { useLocation } from 'react-router-dom'
import ContactForm from './ContactForm';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    openEnquiryBtn: {
        position: "fixed",
        right: -43,
        top: '40%',
        transform: "rotate(-90deg)",
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: 35,
        padding: "0 16px"
    },
    rePostBtn: {
        position: "fixed",
        right: -5,
        top: '80%',
        //transform: "rotate(-90deg)",
        background: "#2959BA",
        border: 0,
        borderRadius: 3,
        //boxShadow: "0 3px 5px 2px #69beff4d",
        color: "white",
        height: 35,
        padding: "0 16px",
        '&:hover': {
            background: "#2959BA",
        },
    },
    container: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        width: 320,
        zIndex: 1
    },
    cardBgPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    // btnWhite: {
    //     backgroundColor: theme.palette.common.white,
    //     color: theme.palette.common.black,
    //     "&:hover": {
    //       backgroundColor: theme.palette.common.white
    //     }
    // }
}));


export default function FloatingContactForm() {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const user = useSelector((state) => state.userReducer.user);
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    //console.log(matches)
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
    // const [visible, visibleSet] = React.useState(isLoggedIn ? false : true);
    const [visible, visibleSet] = React.useState(false);
    // const [success, successSet] = React.useState(false);
    const pathSplit = location?.pathname.split("/")
    const handleChange = () => {
        visibleSet((prev) => !prev);
    };

    // const onSuccess = () => {
    //     successSet(true)
    // }

    // const SuccessCard = () =>  (
    //     <CardContent>
    //         <Typography variant="h2" component="p" gutterBottom>
    //             Thank <br /> You!
    //         </Typography>
    //         <Typography variant="h6" component="p" sx={{fontStyle: 'oblique'}}>
    //             Your query has been submitted successfully. <br /> We will be in touch shortly!
    //         </Typography>
    //     </CardContent>
    // );

    const Content = () => (
        <CardContent>
            <ContactForm
                isContactFormWidget={true}
                onSubmitHandleChange={handleChange}
            />
        </CardContent>
    )

    React.useEffect(() => {
        if (matches == true && isLoggedIn == false) {
            setTimeout(() => {
                visibleSet(true)
            }, [10000])
        }
        if (location.pathname == "/add-your-products" && isLoggedIn == true) {
            //console.log("hj")
            visibleSet(false);
        }
        if (pathSplit && (pathSplit.length == 3 && pathSplit[1] == "brand-stores")) {
            visibleSet(false);
        }
    }, [isLoggedIn, matches, location.pathname])

    return (
        <>
            {(location.pathname == "/markets") ?
                <Button
                    className={classes.rePostBtn}
                    to="/add-your-products"
                    component={Link}
                    target="_blank"
                >
                    Re Post Your Products
                </Button>
                : (user?.brand_store_slug && location.pathname == `/brand-stores/${user?.brand_store_slug}`) ?
                    <Button
                        className={classes.rePostBtn}
                        to="/markets"
                        component={Link}
                        target="_blank"
                    >
                        Add Your Products
                    </Button>
                    : ((location.pathname == "/add-your-products" || (pathSplit && (pathSplit.length == 3 && pathSplit[1] == "brand-stores"))) && isLoggedIn == true) ? '' :
                        <Button
                            className={classes.openEnquiryBtn}
                            // visible={visible}
                            onClick={handleChange}
                        >
                            CONTACT US
                        </Button>
            }
            <Box sx={{ position: 'relative' }}>
                <Slide
                    className={classes.container}
                    direction="left"
                    in={visible}
                    mountOnEnter
                    unmountOnExit
                >
                    <Box>
                        <Card>
                            <CardHeader
                                className={classes.cardBgPrimary}
                                sx={{ padding: 1 }}
                                action={
                                    <IconButton
                                        className={`text-white`}
                                        onClick={(e) => {
                                            visibleSet(false, e);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                                title="Contact Us"
                            />
                            <Content />
                        </Card>
                    </Box>
                </Slide>
            </Box>
        </>
    )
}
