import { NOTIFY, CLEAR_NOTIFICATION } from './types';

export const notify = (params) => dispatch => {
	dispatch({
		type: NOTIFY,
		payload: {
			message: params.message,
			type: params.type ? params.type : ''
		}
	})
}

export const clearNotification = () => dispatch => {
	dispatch({
		type: CLEAR_NOTIFICATION,
		payload: {
			message: '',
			type: ''
		}
	})
}